import React, { useState, useEffect } from 'react';
import './style.css';
import $ from 'jquery';
import { HomeContainer } from './HomeStyle';
// import { PreContainer } from './pre';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './custom-carousel.css';
const scrollToTop = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
};

const googleMapsEmbedUrl =
'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20206.3390221396!2d54.70463058534053!3d24.728052106162842!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5effd1737a9a77%3A0x4bdf4e512ac74a2a!2sSafe%20Care%20Medical%20Industry!5e0!3m2!1sen!2sae!4v1670939984800!5m2!1sen!2sae';

const chunkArray = (array, size) => {
  const chunkedArray = [];
  for (let i = 0; i < array.length; i += size) {
    chunkedArray.push(array.slice(i, i + size));
  }
  return chunkedArray;
};
const Index = ( {backendUrl}) => {
  const [services, setServices] = useState([]);
  const [categories, setCategories] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(`${backendUrl}/getIndexDetails`)
      .then(response => response.json())
      .then(data => {
        setServices(data.service);
        setCategories(data.categories);
      })
      .catch(error => {
        console.error(error);
      });
  }, [backendUrl]);
  // useEffect(() => {
  //   const handleLoad = () => {
  //     setTimeout(() => {
  //       setIsLoading(false);
  //     }, 5000);
  //   };

  //   window.addEventListener('load', handleLoad);

  //   return () => {
  //     window.removeEventListener('load', handleLoad);
  //   };
  // }, []);

  const headingStyle = {
    color: 'white',
    textAlign: 'center',
    marginBottom: '20px',
    backgroundColor: '#000',
    padding: '10px',
    borderRadius: '50px 50px 50px 0',
  };

  const [isFormOpen, setIsFormOpen] = useState(false);

  const openForm = () => {
    setIsFormOpen(true);
  };

  const closeForm = () => {
    setIsFormOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const mybutton = document.getElementById('myBtn');

      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        mybutton.style.display = 'block';
      } else {
        mybutton.style.display = 'none';
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const [slideIndex, setSlideIndex] = useState(1);

  useEffect(() => {
    showSlides(slideIndex);
  }, [slideIndex]);

  function plusSlides(n) {
    let newIndex = slideIndex + n;
    const totalSlides = document.getElementsByClassName("mySlides").length;
  
    if (newIndex > totalSlides) {
      newIndex = 1;
    }
  
    if (newIndex < 1) {
      newIndex = totalSlides;
    }
  
    setSlideIndex(newIndex);
  }

  function currentSlide(n) {
    setSlideIndex(n);
  }

  function showSlides(n) {
    let i;
    let slides = document.getElementsByClassName("mySlides");
    let dots = document.getElementsByClassName("demo");
    let captionText = document.getElementById("caption");
    if (n > slides.length) {
      setSlideIndex(1);
    }
    if (n < 1) {
      setSlideIndex(slides.length);
    }
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active", "");
    }
    slides[slideIndex - 1].style.display = "block";
    dots[slideIndex - 1].className += " active";
    captionText.innerHTML = dots[slideIndex - 1].alt;
  }
 
  
  const chunkedServices = chunkArray(services, 3);
  return (
  <HomeContainer>
    <div>
       {/* <Header /> */}
        <head>
	
  <title>Best and quality medical disposable manufacturer in UAE</title>
  <meta name="description"
    content="We are the quality manufacturer of medical disposables in UAE for the past 7 years.  We provide Respiratory, PPE and nursing consumables, Surgical Kits, etc." />
  <meta property="og:title"
    content="Best and quality medical disposable manufacturer in UAE " />
  <meta property="og:description"
    content="We are the quality manufacturer of medical disposables in UAE for the past 7 years.  We provide Respiratory, PPE and nursing consumables, Surgical Kits, etc." />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <meta charset="utf-8" />
  <meta property="twitter:card" content="summary_large_image" />
  
  <link rel="shortcut icon" href="./playground_assets/32x32.png"
    type="icon/png" sizes="32x32" />
  <link rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.min.css"/>
  <link rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.theme.default.css"/>
  <script
    src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
  <script
    src="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js"></script>
  
  
  <link rel="stylesheet"
    href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap"
    data-tag="font" />
  <link rel="stylesheet"
    href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap"
    data-tag="font" />
  <link rel="stylesheet"
    href="https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap"
    data-tag="font" />
  <link rel="stylesheet"
    href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&amp;display=swap"
    data-tag="font" />
  
  </head>
      <body>
      {/* <PreContainer>
      {isLoading ? (
        <div className="loader">
          <img src="playground_assets/icon.png" />
        </div>
      ) : (
        null
      )}
    </PreContainer> */}

        <button onClick={scrollToTop} id="myBtn" title="Go to top">
          &#8593;
        </button>
        <div class="whatsapp">
		<a
			href="https://api.whatsapp.com/send/?phone=%2B971562428556&text&type=phone_number&app_absent=0_blank"
			target="_blank"><img src="playground_assets/whatsapp.png"/></a>
	</div>
  <div className='slideshow-container' style={{ position: 'relative' }}>
		<script type="text/javascript"
			src="https://unpkg.com/dangerous-html@0.1.11/dist/default/lib.umd.js"></script>
		<link href="./styles/home.css" rel="stylesheet" />

		<div class="mySlides">
			<div class="numbertext">1 / 6</div>
			<video width="100%" height="100%" loop="" autoplay="" muted>
				<source
					src="playground_assets/Safecare Medical Industries2.m4v"
					type="video/mp4"/>
			</video>
		</div>

		<div class="mySlides">
			<div class="numbertext">2 / 6</div>
			<img src="playground_assets/hero-img-1.jpg"
				style={{width: '100%'}}/>
		</div>

		<div class="mySlides">
			<div class="numbertext">3 / 6</div>
			<img src="playground_assets/hero-img-2.jpg"
				style={{width: '100%'}}/>
		</div>

		<div class="mySlides">
			<div class="numbertext">4 / 6</div>
			<img src="playground_assets/hero-img-3.jpg"
				style={{width: '100%'}}/>
		</div>

		<div class="mySlides">
			<div class="numbertext">5 / 6</div>
			<img src="playground_assets/hero-img-4.jpg"
				style={{width: '100%'}}/>
		</div>

		<div class="mySlides">
			<div class="numbertext">6 / 6</div>
			<img src="playground_assets/hero-img.jpg" style={{width: '100%'}}/>
		</div>


		<a class="prev" onClick={() => plusSlides(-1)}>❮</a> <a class="next"
			 onClick={() => plusSlides(1)}>❯</a>

		<div class="caption-container">
			<p id="caption"></p>
		</div>

		<div class="row">
			<div class="column">
				<img class="demo cursor"
					src="playground_assets/hero-img-thumb-5.jpg"
					style={{width: '100%'}} onClick={() => currentSlide(1)}
					alt="SAFECARE INDUSTRY"/>
			</div>
			<div class="column">
				<img class="demo cursor"
					src="playground_assets/hero-img-thumb-1.jpg"
					style={{width: '100%'}} onClick={() => currentSlide(2)}
					alt="INJUCTION MOLDING"/>
			</div>
			<div class="column">
				<img class="demo cursor"
					src="playground_assets/hero-img-thumb-2.jpg"
					style={{width: '100%'}} onClick={() => currentSlide(3)} alt="MULTIVAC"/>
			</div>
			<div class="column">
				<img class="demo cursor"
					src="playground_assets/hero-img-thumb-3.jpg"
					style={{width: '100%'}} onClick={() => currentSlide(4)}
					alt="AUTOMATED STORAGE & RETRIEVAL SYSTEM"/>
			</div>
			<div class="column">
				<img class="demo cursor"
					src="playground_assets/hero-img-thumb-4.jpg"
					style={{width: '100%'}} onClick={() => currentSlide(5)}
					alt="QLA AUTOMATED CUTTING AND STACKING SYSTEM"/>
			</div>
			<div class="column">
				<img class="demo cursor"
					src="playground_assets/hero-img-thumb.jpg"
					style={{width: '100%'}}  onClick={() => currentSlide(6)}
					alt="SAFECARE INDUSTRY"/>
			</div>
		</div>
	</div>
  <div class="home-container03">
		<div class="home-container04">
			<div class="home-container05">
				<img alt="home-image"
					src="playground_assets/image-2-%5B2%5D-1500h.png"
					class="home-image" />
			</div>
			<div class="home-container06">
				<div class="home-container07">
					<h1 class="home-text05">WHO WE ARE</h1>
				</div>
				<h1 class="home-text06">
					<span>About Safecare&nbsp;</span> <br /> <span>Medical
						Industries</span>
				</h1>
				<h1 class="home-text10">
					<span>Safecare Medical Industries was conceived and&nbsp;</span> <span>
						established in the year of 2016 by Bin Ali group that&nbsp; </span> <span>
						plans to give a different dimension to the medical&nbsp; </span> <span>industries
						all across the Middle East</span>
				</h1>
				<div class="home-container08">
					<a href="about" class="home-navlink1 button"> LEARN
						MORE </a>
				</div>
			</div>
		</div>
	</div>
  <div class="home-container09">
		<h1 class="home-text15">Our Clients</h1>
   
		<div className="home-div">
  <Carousel
    className="brand-carousel section-padding"
    showThumbs={false}
    infiniteLoop
    autoPlay
    interval={2000}
    swipeable
    emulateTouch
    dynamicHeight
    responsive={{
      0: { items: 1 },
      200: { items: 3 },
      500: { items: 5 },
    }}
  >
    <div className="single-logo">
      <img src="./playground_assets/a4 company profilr for pr-200h.png" alt="" />
    </div>
    <div className="single-logo">
      <img src="./playground_assets/11-200h.jpg" alt="" />
    </div>
    <div className="single-logo">
      <img src="./playground_assets/21-200h.jpg" alt="" />
    </div>
    <div className="single-logo">
      <img src="./playground_assets/31-200h.jpg" alt="" />
    </div>
    <div className="single-logo">
      <img src="./playground_assets/41.jpg" alt="" />
    </div>
    <div className="single-logo">
      <img src="./playground_assets/nmc.jpg" alt="" />
    </div>
  </Carousel>
</div>


	</div>
  <div className="home-container10">
      <div className="home-container11">
        <h1 className="home-text16">Our Services</h1>
        <span className="home-text17">
          The medical field demands the highest quality medical supplies and
          equipment. Safecare Line of Medical Products Includes:
        </span>
      </div>
      <div className="home-container12">
  <section className="container">
    {services.map((service, index) => (
      <div className="card" key={index}>
        <div className="image">
          <img
            alt="image"
            src={`/uploads/${service.serviceimage}`}
            className="home-image01"
          />
        </div>
        <h2>{service.servicename}</h2>
        <p>{service.servicedescription.substr(0, 100)}</p>
      </div>
    ))}
  </section>
</div>

<div className="home-blog">
  {chunkedServices.map((chunk, i) =>
    chunk.map((item, j) => (
      <div className="home-container14" key={`${i}-${j}`} style={{ width: '33.33%', padding: '10px' }}>
        <div className="home-blog-post-card" style={{ border: '1px solid #ccc', borderRadius: '5px', padding: '10px' }}>
          <div className="home-container14">
            <img
              alt="image"
              src={`/uploads/${item.serviceimage}`}
              className="home-image01"
              style={{ width: '100%', height: 'auto' }}
            />
            <span className="home-text18" style={{ fontWeight: 'bold' }}>{item.servicename}</span>
            <span className="home-text19">
              {item.servicedescription.substr(0, 150)}
            </span>
          </div>
        </div>
      </div>
    ))
  )}
  {chunkedServices.flat().length % 3 !== 0 && (
    <div className="home-container14" />
  )}
</div>
      



      <div className="home-container25">
        <h1 className="home-text32">Product Categories</h1>
      </div>
      <div className="home-container">
  <div className="home-container26">
    <section className="container1">
      {categories.map((category) => (
        <div className="card1" key={category.id}>
          <div className="image1">
            <img src={`/uploads/${category.image}`} />
          </div>
          <span className="home-text41">{category.catename}</span>
          <button className="home-button4 button" style={{ background: 'linear-gradient(50deg, rgb(31, 149, 141), rgb(88, 181, 107))' }}>
            <a href="product">View Products</a>
          </button>
        </div>
      ))}
    </section>
  </div>

  <div className="home-blog3">
  <div className="home-blog-row">
    {categories.map((category, index) => (
      <React.Fragment key={index}>
        <div className="home-blog-post-card10">
          <img
            alt="image"
            src={`/uploads/${category.image}`}
            className="home-image10"
          />
          <div className="home-container36">
            <span className="home-text41">{category.catename}</span>
            <button className="home-button4 button" style={{ background: 'linear-gradient(50deg, rgb(31, 149, 141), rgb(88, 181, 107))' }}>
              <a href={`product?categoryid=${category._id}`}>View Products</a>
            </button>
          </div>
        </div>
        {(index + 1) % 4 === 0} {}
      </React.Fragment>
    ))}
  </div>
</div>
<div class="home-container43">
			<button class="home-button8 button" style={{ background: 'linear-gradient(50deg, rgb(31, 149, 141), rgb(88, 181, 107))' }}>
				<a href="product">View All Products</a>
			</button>
		</div>

</div>




    


    </div>
   
    <div className="home-container44">
      <div className="home-div1">
        <div
          style={{ textAlign: 'center' }}
          dangerouslySetInnerHTML={{ __html: `
            <iframe
              src="${googleMapsEmbedUrl}"
              width="100%"
              height="300"
              style="border:0;"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          ` }}
        />
      </div>
    </div>

        <script data-section-id="header"
		src="https://unpkg.com/@teleporthq/teleport-custom-scripts"></script>
	<script
		src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
      </body>
      {/* <Footer /> */}
    </div>
    </HomeContainer>
  );
};

export default Index;
