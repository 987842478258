import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import "./style.css";
import "./request_quote.css";
import "./POPUP.css";
import axios from "axios";
import { fetchWishlist } from "../layout/Header";
import Alert from "react-bootstrap/Alert";
import ReactImageMagnify from 'react-image-magnify';


function Productdetails({ productDetails, backendUrl, sessionID }) {
  const [inputValues, setInputValues] = useState({});
  const [wishlist, setWishlist] = useState([]);
  const [medicineList, setMedicineList] = useState([]);
  const [count, setCount] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedImage, setSelectedImage] = useState('');

 
 
  useEffect(() => {
    if (productDetails && productDetails.product) {
      document.title = `Product - ${productDetails.product.productname}`;
    }
  }, [productDetails]);

  useEffect(() => {
    if (sessionID && backendUrl) {
      fetchWishlist(sessionID, backendUrl, setCount, setWishlist,setMedicineList).then(
        (wishlist) => {
          setWishlist(wishlist);
        }
      );
    }
  }, [sessionID, backendUrl,setMedicineList]);

  const handleSuccess = () => {
    setSuccessMessage("Your message has been sent successfully.");
  };

  const ValidateCart = (id,productId) => {
    const inputValue = inputValues[id];

    if (
      inputValue === undefined ||
      inputValue === "" ||
      inputValue === "undefined"
    ) {
      alert("Please Enter Qty");
      return;
    } else {
      const data = {
        productrefrence: id,
        Qty: inputValue,
        sessionID: sessionID,
        productId: productId,
        status:1,
        
      };

      axios
        .post(`${backendUrl}/saveWishlist`, data, { withCredentials: true })
        .then((response) => {
          handleSuccess();
          const newCount = response.headers["wishlist-count"];
          setCount(newCount);
          setInputValues((prevState) => ({
            ...prevState,
            [id]: "",
          }));
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleChange = (event, referenceId) => {
    const newQuantity = event.target.value;

    if (newQuantity <= 0) {
 
      alert('Quantity must be greater than zero')
      return;
    } else {
   
    }
  
    setInputValues((prevState) => ({
      ...prevState,
      [referenceId]: newQuantity,
    }));
  
    const updatedWishlist = wishlist.map((item) => {
      if (item.productrefrence === referenceId) {
        return { ...item, Qty: newQuantity };
      }
      return item;
    });
  
    setWishlist(updatedWishlist);
  };
  

  if (!productDetails) {
    return null;
  }
  const productFeatures = productDetails.productFeatures;
  const productReference = productDetails.productReference;
  const categories = productDetails.categories;
  const product = productDetails.product;
  const productPicture = productDetails.productPicturess;
  const productColor = productDetails.productColor;
  const hasProductPictures = productPicture.length > 0;
  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };
  function redirectToProduct(categoryId) {
    const url = `/product?categoryid=${categoryId}`;
    window.location.href = url;
  }
  
  return (
    <div>
      <head>
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
          rel="stylesheet"
        />
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"></script>
        <title>
          Request-a-quote - Best and quality medical disposable manufacturer in
          UAE
        </title>
        <meta
          name="description"
          content="We are the quality manufacturer of medical disposables in UAE for the past 7 years.  We provide Respiratory, PPE and nursing consumables, Surgical Kits, etc."
        />
        <meta
          property="og:title"
          content="Request-a-quote - Best and quality medical disposable manufacturer in UAE "
        />
        <meta
          property="og:description"
          content="We are the quality manufacturer of medical disposables in UAE for the past 7 years.  We provide Respiratory, PPE and nursing consumables, Surgical Kits, etc."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta charset="utf-8" />
        <meta property="twitter:card" content="summary_large_image" />

        <link
          rel="shortcut icon"
          href="public/playground_assets/32x32.png"
          type="icon/png"
          sizes="32x32"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap"
          data-tag="font"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap"
          data-tag="font"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap"
          data-tag="font"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&amp;display=swap"
          data-tag="font"
        />

        
      </head>
      <body>
        <div className="request--quote-container">
          <div className="request--quote-container01">
            <a href="index.html" className="request--quote-navlink">
              <svg viewBox="0 0 1024 1024" className="request--quote-icon">
                <path d="M426 854h-212v-342h-128l426-384 426 384h-128v342h-212v-256h-172v256z"></path>
              </svg>
            </a>
           
            <h1 className="request--quote-text"  onClick={() => redirectToProduct(product.categoryId._id)}>
              {product.categoryId.catename}
            </h1>
            <h1 className="request--quote-text">/ {product.productname}</h1>
          </div>
          <div className="request--quote-container02">
          <div className="request--quote-container03">
        {productPicture.slice(0, 3).map((picture, index) => (
          <div key={index} className="request--quote-container04">
            <img
              src={index === 0 ? `/uploads/${product.image}` : `/uploads/${picture.image}`}
              alt="image"
              className="request--quote-image"
              onClick={() => handleImageClick(index === 0 ? `/uploads/${product.image}` : `/uploads/${picture.image}`)}
            />
          </div>
        ))}
      </div>

              <div className="request--quote-container07">
          <ReactImageMagnify
            {...{
              smallImage: {
                alt: product.productname,
                isFluidWidth: true,
                src: selectedImage || `/uploads/${product.image}`,
              },
              largeImage: {
                src: selectedImage || `/uploads/${product.image}`,
                width: 1200,
                height: 1800,
              },
              lensStyle: { backgroundColor: 'rgba(0,0,0,0.3)' },
            }}
          />
        </div>
            <div className="request--quote-container08">
              <h1 className="request--quote-text01">{product.brand}</h1>
              <h1 className="request--quote-text02">{product.productname}</h1>
              {productReference.length > 0 && (
                <h1 className="request--quote-text03">REFERENCE</h1>
              )}
              <ul>
                {productReference.map((ProductReference) => (
                  <li key={ProductReference.reference}>
                    {ProductReference.reference}
                  </li>
                ))}
              </ul>
              {productFeatures.length > 0 && (
                 <h1 className="request--quote-text03">KEY FEATURES</h1>    
              )}
              <ul>
                {productFeatures.map((ProductFeatures) => (
                  <li key={ProductFeatures.feature}>
                    {ProductFeatures.feature}
                  </li>
                ))}
              </ul>
              {productColor.length > 0 && (
                 <h1 className="request--quote-text03">COLORS</h1>    
              )}
              <ul className="color-grid" style={{ display: "flex", flexDirection: "row", listStyleType: "none" }}>
                    {productColor.map((colorObj) => (
                      <li key={colorObj._id} className="custom-color">
                        <input
                          type="color"
                          value={colorObj.Color}
                          readOnly
                          style={{ width: "30px", height: "30px" }}
                          disabled
                        />
                      </li>
                    ))}
                  </ul>


            </div>
          </div>
          <div>
            <div class="request--quote-container10">
              <div class="request--quote-container11">
                <img
                  src={selectedImage || `/uploads/${product.image}`}
                  alt={product.productname}
                  class="request--quote-image4"
                />
              </div>
              <div class="request--quote-container12">
              {productPicture.slice(0, 3).map((picture, index) => (
                <div class="request--quote-container13" key={index}>
                  <img
                   src={index === 0 ? `/uploads/${product.image}` : `/uploads/${picture.image}`}
                    alt="image" 
                    class="request--quote-image5" onClick={() => handleImageClick(index === 0 ? `/uploads/${product.image}` : `/uploads/${picture.image}`)}
                  />
                   </div>
                  ))}
              </div>
              <div class="request--quote-container16">
                <h1 class="request--quote-text06">{product.brand}</h1>
                <h1 class="request--quote-text07">{product.productname}</h1>
                <h1 class="request--quote-text08">Key Features</h1>
                <ul>
                {productFeatures.map((ProductFeatures) => (
                  <li key={ProductFeatures.feature}>
                    {ProductFeatures.feature}
                  </li>
                ))}
              </ul>
                <h1 class="request--quote-text08">REFERENCE</h1>
                <ul>
                  {productReference.map((ProductReference) => (
                    <li key={ProductReference.reference}>
                      {ProductReference.reference}
                    </li>
                  ))}
                </ul>
                {/* <div class="request--quote-container17">
                  <input
                    type="number"
                    placeholder="Quantity"
                    class="request--quote-textinput1 input"
                  />
                  <button class="request--quote-button1 button">
                    Add to Wishlist
                  </button>
                </div> */}
              </div>
            </div>

            <table>
              <thead>
                <tr>
                  <th scope="col" style={{ width: "50%" }}>
                    <b>REFERENCE</b>
                  </th>
                  <th scope="col">
                    <b>DESCRIPTION</b>
                  </th>
                  <th scope="col" colSpan={2}></th>
                </tr>
              </thead>
              <tbody>
  {productReference.map((reference) => {
    const wishlistItem = wishlist.find(
      (item) => item.productrefrence._id === reference._id
    );
    const qty = wishlistItem ? wishlistItem.Qty : "";

    return (
      <tr key={reference._id}>
        <td data-label="REFERENCE">{reference.reference}</td>
        <td data-label="DESCRIPTION">{reference.description}</td>
        <td colSpan={2}>
          <input
            type="number"
            className="request--quote-textinput input"
            id={`inputdata${reference._id}`}
            value={inputValues[reference._id] || qty}
            onChange={(event) => handleChange(event, reference._id)}
          />

          <button
            className="request--quote-button button"
            style={{ borderRadius: "7%" }}
            onClick={() => ValidateCart(reference._id, reference.productId)}
          >
            Add to list
          </button>
        </td>
      </tr>
    );
  })}
</tbody>

            </table>
          </div>
        </div>
      </body>
    </div>
  );
}

export default Productdetails;
