import React,{useEffect,useState} from 'react';
import { WishlistContainer } from './WishlistStyle';

function ViewWishlist({ customerDetails, customer }) {
    
    console.log(customerDetails)
    console.log(customer)
  return (
    <div>
      <WishlistContainer>
      <head>
        <meta charset="UTF-8"/>
         <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
         <title>Safecare Industry</title>
        <link rel="stylesheet" href="cdn.jsdelivr.net_npm_bootstrap@5.2.3_dist_css_bootstrap.min.css" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous"/>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/js/all.min.js"></script>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css"></link>
        </head>
        <body>
        <section class="bg-light my-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-8">
              <div class="card border shadow-0">
              
                <div class="m-4">
                  <h4 class="card-title mb-4">Your RFQ Details </h4>
                  {customerDetails && Array.isArray(customerDetails.custdetails) ? (
                        customerDetails.custdetails.map((item) => (
                          <div className="row gy-3 mb-4" key={item._id}>
                            <div className="col-lg-5">
                              <div className="me-lg-5">
                                <div className="d-flex">
                                  <img src={"/uploads/" + item.productrefrence?.productId?.image} 
                                    className="border rounded me-3"
                                    style={{ width: '96px', height: '96px' }}
                                    alt="Product Image"
                                  />
                                  <div className="">
                                    <a href="#" className="nav-link">
                                      {item.productrefrence?.productId?.productname}
                                    </a>
                                    <p className="text-muted">
                                      {item.productrefrence?.description}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-2 col-sm-6 col-6 d-flex flex-row flex-lg-column flex-xl-row text-nowrap">
                              <div className="">
                              <select
                                style={{ width: '100px' }}
                                className="form-select me-4"
                                value={item.qty} // Set the initial value based on the qty property
                            >
                                <option>{item.qty}</option>
                            </select>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p>No items in the wishlist.</p>
                      )}
                </div>
      
              </div>
            </div>
            <div class="col-lg-4">
              
              <div class="card shadow-0 border">
               
              <div className="card-body">
                <div className="form-body">
                    <div className="row">
                    <div className="form-holder">
                        <div className="form-content">
                        <div className="form-items">
                            <h4>Customr Details</h4>
                          
                                <form className="requires-validation">
                                    <div className="col-md-12" style={{paddingBottom:'10px'}}>
                                        <label htmlFor="orderno" style={{color:'black'}}>Order No: {customer?.custlist[0]?.orderno}</label>
                                    </div>
                                    <div className="col-md-12" style={{paddingBottom:'10px'}}>
                                        <label htmlFor="fullname" style={{color:'black'}}>Customer Name: {customer?.custlist[0]?.fullname}</label>
                                    </div>

                                    <div className="col-md-12" style={{paddingBottom:'10px'}}>
                                        <label htmlFor="email" style={{color:'black'}}>Email: {customer?.custlist[0]?.email}</label>
                                    </div>

                                    <div className="col-md-12" style={{paddingBottom:'10px'}}>
                                        <label htmlFor="phone" style={{color:'black'}}>Phone: {customer?.custlist[0]?.phone}</label>
                                    </div>
                                    </form>


                            
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
              </div>
            </div>           
            
          </div>
        </div>
      </section>  
        </body>
      </WishlistContainer>
    </div>
  )
}

export default ViewWishlist
