import React,{useEffect,useState} from 'react';
import { WishlistContainer } from './WishlistStyle';
import axios from 'axios';
import { fetchWishlist } from "../layout/Header";
function Wishlist({backendUrl,sessionID}) {
  const [wishlist, setWishlist] = useState([]);
  const [medicinelist, setMedicineList] = useState([]); 
  const [count, setCount] = useState(0);
 
  useEffect(() => {
    if (sessionID && backendUrl) {
      fetchWishlist(sessionID, backendUrl, setCount, setWishlist,setMedicineList).then(
        (wishlist) => {
          setWishlist(wishlist);
        }
      );
    }
  }, [sessionID, backendUrl, setCount, setWishlist, setMedicineList]);
  const handleQuantityChange = (e, itemId, productid) => {
    const newQuantity = e.target.value;
  
    if (newQuantity === "" || parseFloat(newQuantity) <= 0) {
      alert("Please enter a valid quantity greater than zero");
      return;
    }
  
    const updatedWishlist = wishlist.map((item) => {
      if (item.productrefrence._id === itemId) {
        return { ...item, Qty: newQuantity };
      }
      return item;
    });
    setWishlist(updatedWishlist);
  
    const data = {
      productrefrence: itemId,
      Qty: newQuantity,
      sessionID: sessionID,
      productId: productid,
      status: 1,
    };
  
    axios
      .post(`${backendUrl}/saveWishlist`, data, { withCredentials: true })
      .then((response) => {
        const updatedSpan = document.getElementById(`d_updated${itemId}`);
        if (updatedSpan) {
          updatedSpan.style.display = "inline";
          setTimeout(() => {
            updatedSpan.style.display = "none";
          }, 1000);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  
  function handleRemoveClick(wishlistID) {
    const data = {
      value: wishlistID
    };
    axios
      .post(`${backendUrl}/updateWishlist`, data, { withCredentials: true })
      .then((response) => {
        const removedDiv = document.getElementById(`wishlistItem_${wishlistID}`);
        if (removedDiv) {
          removedDiv.remove();
  
      
          const wishlistContainer = document.getElementById('wishlistContainer');
          if (wishlistContainer && wishlistContainer.children.length === 0) {
            wishlistContainer.innerHTML = '<p class="blink text-danger">No items in the wishlist</p>';
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  
// Form Validation
const [fullname, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setNum] = useState('');
  const [message, setMessage] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  // Validation states
  const [nameValid, setNameValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const [numValid, setNumValid] = useState(true);
  const [isCheckedValid, setIsCheckedValid] = useState(true);
  const [orderResponse, setOrderResponse] = useState(null);
    // Handle input changes
    const handleNameChange = (e) => {
      setName(e.target.value);
      setNameValid(true);
    };
  
    const handleEmailChange = (e) => {
      setEmail(e.target.value);
      setEmailValid(true);
    };
  
    const handleNumChange = (e) => {
      const inputValue = e.target.value;
      const cleanedValue = inputValue.replace(/\D/g, ''); // Remove non-digit characters
      
      setNum(cleanedValue);
      setNumValid(validatePhoneNumber(cleanedValue));
    };
  
    const handleMessageChange = (e) => {
      setMessage(e.target.value);
    };
  
    const handleCheckboxChange = () => {
      setIsChecked(!isChecked);
      setIsCheckedValid(true);
    };
    const handleSubmit = (e) => {
      e.preventDefault();
  
      // Perform validation
      let formValid = true;
  
      if (fullname === '') {
        setNameValid(false);
        formValid = false;
      }
  
      if (email === '') {
        setEmailValid(false);
        formValid = false;
      } else if (!validateEmail(email)) {
        setEmailValid(false);
        formValid = false;
      }
  
      if (phone === '') {
        setNumValid(false);
        formValid = false;
      } else if (!validatePhoneNumber(phone)) {
        setNumValid(false);
        formValid = false;
      }
  
      if (!isChecked) {
        setIsCheckedValid(false);
        formValid = false;
      }
  
      if (!isChecked) {
        setIsCheckedValid(false);
        formValid = false;
      }
      if (wishlist.length === 0) {
        formValid = false;
      }
  
      if (formValid) {
        const data = {
          fullname: fullname,
          email: email,
          phone: phone,
          message: message,
          sessionID:sessionID
        };
        fetch(`${backendUrl}/saveCustomerOrder`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((response) => {
            setOrderResponse(response);
            setIsFormSubmitted(true);
            setName('');
            setEmail('');
            setNum('');
            setMessage('');
            setIsChecked(false);
            setTimeout(() => {
              setIsFormSubmitted(false);
              window.location.reload(); // Refresh the page
            }, 4000);
          });
        
      }else
      {
        setIsFormSubmitted(true);
      }
    };
    const validateEmail = (email) => {
      // Regular expression to validate email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
    const validatePhoneNumber = (phoneNumber) => {
      // Regular expression to validate phone number format
      const phoneRegex = /^\d{10,13}$/; 
      return phoneRegex.test(phoneNumber);
    };
    
    

  
  return (
    <div>
      <WishlistContainer>
        <head>
        <meta charset="UTF-8"/>
         <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
         <title>Whishlist</title>
        <link rel="stylesheet" href="cdn.jsdelivr.net_npm_bootstrap@5.2.3_dist_css_bootstrap.min.css" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous"/>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/js/all.min.js"></script>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css"></link>
        </head>
        <body>
       
        <section class="bg-light my-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-8">
              <div class="card border shadow-0">
              <div class="m-5">
              <h4 class="card-title mb-4">Your wishlist items</h4>
  <p>You have  {wishlist.length} items in your cart</p>
  {wishlist.length === 0 ? (
    <p className=''><span class="badge bg-danger pt-1 mt-3 ms-2">Wishlist is empty</span></p>
  ) : (
    wishlist.map((item) => (
      <div class="row gy-3 mb-4" id={`wishlistItem_${item._id}`}>
        <div class="col-lg-5">
          <div class="me-lg-5">
            <div class="d-flex">
              <img
                src={`/uploads/${item.productId.image}`}
                class="border rounded me-3"
                style={{ width: '96px', height: '96px' }}
                alt={item.productId.productname}
              />
              <div class="">
                <a href="#" class="nav-link">{item.productId.productname}</a>
                <p class="text-muted">{item.productrefrence.reference}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-sm-6 col-6 d-flex flex-row flex-lg-column flex-xl-row text-nowrap">
          <div className="">
            <input
              type="number"
              style={{ width: '100px' }}
              className="form-control me-4"
              value={item.Qty}
              onChange={(e) => handleQuantityChange(e, item.productrefrence._id, item.productId._id)}
            />
          </div>
        </div>
        <div class="col-lg-2 col-sm-6 col-6 d-flex flex-row flex-lg-column flex-xl-row text-nowrap">
        <span class="badge bg-success pt-1 mt-3 ms-2" style={{display:'none',width:'56%',height:'20px'}} id={`d_updated${item.productrefrence._id}`}>updated</span>                                  
        </div>
        <div class="col-lg col-sm-6 d-flex justify-content-sm-center justify-content-md-start justify-content-lg-center justify-content-xl-end mb-2">
          <div class="float-md-end">
            <a href="#" class="btn btn-light border text-danger icon-hover-danger" style={{color:'#f50a03;'}} onClick={() => handleRemoveClick(item._id)}>Delete</a>
          </div>
        </div>
      </div>
    ))
  )}
</div>
<div class="border-top pt-10 mx-4 mb-4">
                  <p><i class="fas fa-truck text-muted fa-lg"></i> Free Delivery within 1-2 weeks</p>
                  <p class="text-muted">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip
                  </p>
                </div>
                

              </div>
            </div>

            <div class="col-lg-4">
              
              <div class="card shadow-0 border">
               
              <div className="card-body">
      <div className="form-body">
        <div className="row">
          <div className="form-holder">
            <div className="form-content">
              <div className="form-items">
                <h4>Request for Quotation</h4>
                <p>Fill in the form below.</p>
                <form className="requires-validation" onSubmit={handleSubmit} noValidate>

                  <div className="col-md-12">
                    <input
                      className={`form-control ${nameValid ? '' : 'is-invalid'}`}
                      style={{ marginBottom: '1rem', border: '1px solid black' }}
                      type="text"
                      name="fullname" id='fullname'
                      placeholder="Full Name"
                      value={fullname}
                      onChange={handleNameChange}
                      required
                    />
                    <div className="invalid-feedback">Username field cannot be blank!</div>
                  </div>

                  <div className="col-md-12">
                    <input
                      className={`form-control ${emailValid ? '' : 'is-invalid'}`}
                      style={{ marginBottom: '1rem', border: '1px solid black' }}
                      type="email"
                      name="email" id='email'
                      placeholder="E-mail Address"
                      value={email}
                      onChange={handleEmailChange}
                      required
                    />
                    <div className="invalid-feedback">
                      {email === '' ? 'Email field cannot be blank!' : 'Invalid email format!'}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <input
                      className={`form-control ${numValid ? '' : 'is-invalid'}`}
                      style={{ marginBottom: '1rem', border: '1px solid black' }}
                      type="tel"
                      name="phone" id='phone'
                      placeholder="Phone Number"
                      value={phone}
                      onChange={handleNumChange}
                      required
                    />
                    <div className="invalid-feedback">
                      {phone === '' ? 'Phone number field cannot be blank!' : 'Invalid phone number format!'}
                    </div>
                  </div>

                  <div className="form-outline">
                    <textarea
                      className="form-control"
                      style={{ marginBottom: '1rem', border: '1px solid black' }}
                      id="message" name='message'
                      rows="4"
                      placeholder="Your Message"
                      value={message}
                      onChange={handleMessageChange}
                    ></textarea>
                  </div>

                  <div className="form-check">
                    <input
                      className={`form-check-input ${isCheckedValid ? '' : 'is-invalid'}`}
                      type="checkbox"
                      value=""
                      id="invalidCheck"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      required
                    />
                      <label className={`form-check-label ${!isChecked ? 'text-black' : 'text-black'}`}>
                        {isChecked ? 'I confirm that all data are correct' : 'Please confirm that all data are correct'}
                      </label>
                      {isFormSubmitted && !isChecked && (
                        <div className="invalid-feedback">
                          Please confirm that the entered data are all correct!
                        </div>
                      )}
                   
                  </div>


                  <div className="form-button mt-3">
                    <button id="submit" type="submit" className="btn btn-success">Request for Quote</button>
                  </div>

                  {isFormSubmitted && wishlist.length === 0 && (
    <p className=''><span className="badge bg-danger pt-1 mt-3 ms-2">Wishlist is empty</span></p>
  )}
  {isFormSubmitted && orderResponse && orderResponse.orderno && wishlist.length > 0 && (
    <div className="alert">
      <span className="closebtn" onClick={() => setIsFormSubmitted(false)}>&times;</span>
      Your RFQ {orderResponse.orderno ? `(${orderResponse.orderno})` : ''} Submitted <strong>Successfully</strong>
    </div>
  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
              </div>
            </div>

          </div>
        </div>
        </section>
        <section>
        <div className="container my-5">
      <header className="mb-4">
        <h3>Recommended items</h3>
      </header>

      <div className="row">
  {medicinelist.map((item) => (
    <div className="col-lg-3 col-md-6 col-sm-6" key={item._id} style={{paddingBottom:'2%'}}>
      <div className="card px-4 border shadow-0 mb-4 mb-lg-0">
        <div className="mask px-2" style={{ height: '50px' }}>
          {item.prodstatus === 1 && (
            <div className="d-flex justify-content-between">
              {/* <h6>
                <span className="badge bg-danger pt-1 mt-3 ms-2">New</span>
              </h6> */}
            </div>
          )}
        </div>
        <a href="#">
          <img src={`/uploads/${item.image}`} className="card-img-top rounded-2" alt="" />
        </a>
        <div className="card-body d-flex flex-column pt-3 border-top">
          <a href="#" className="nav-link">
            {item.productname}
          </a>
          <div className="price-wrap mb-2">
            <strong className=""></strong>
            <del className=""></del>
          </div>
          <div className="card-footer d-flex align-items-end pt-3 px-0 pb-0 mt-auto">
            <a href={`/product/${item._id}`} className="btn btn-outline-primary w-100">
              More Details
            </a>
          </div>
        </div>
      </div>
    </div>
  ))}
</div>

    </div>
      </section>
        </body>
      </WishlistContainer>
    </div>
  )
}

export default Wishlist
