import styled from 'styled-components';
export const NoPageContainer = styled.div`
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,400i,700");
*,
*:after,
*:before {
  box-sizing: border-box;
}

body {
  background-color: #ffffff;
  font-family: "Montserrat", sans-serif;
}

main {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  text-align: center;
}
.fa-industry{
    color: #1F958D;
}

h1 {
  color: #1F958D;
  font-size: 12.5rem;
  letter-spacing: 0.1em;
  margin: 0.025em 0;
  text-shadow: 0.05em 0.05em 0 rgba(0, 0, 0, 0.25);
  white-space: nowrap;
}
@media (max-width: 30rem) {
  h1 {
    font-size: 8.5rem;
  }
}
h1 > span {
  animation: spooky 2s alternate infinite linear;
  color: #528cce;
  display: inline-block;
}

h2 {
  color: #1F958D;
  margin-bottom: 0.4em;
}

p {
  color: #1F958D;
  margin-top: 0;
}
button{
    background-color:#1F958D;
    padding:10px 20px 10px 20px;
    color: #ffffff;
    outline: unset;
    border-radius: 5px;
    border: none;
    cursor: pointer;
}
button:hover{
    background-color:#3AACB1 ;
}


@keyframes spooky {
  from {
    transform: translatey(0.15em) scaley(0.95);
  }
  to {
    transform: translatey(-0.15em);
  }
}


`;