

import styled from 'styled-components';
export const HomeContainer = styled.div`
.home-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
  }
  .home-container01 {
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-size: cover;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
  }
  .home-container02 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-text {
    color: #ffffff;
    font-size: 4em;
    margin-left: var(--dl-space-space-fiveunits);
  }
  .home-navlink {
    box-shadow: 2px 2px 4px 0px #000000;
    font-style: normal;
    margin-top: var(--dl-space-space-twounits);
    transition: 0.3s;
    font-weight: 600;
    margin-left: var(--dl-space-space-fiveunits);
    padding-top: var(--dl-space-space-unit);
    border-width: 0px;
    padding-left: var(--dl-space-space-threeunits);
    border-radius: 10px;
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-unit);
    text-decoration: none;
  }
  .home-navlink:hover {
    color: var(--dl-color-gray-white);
    background-color: var(--dl-color-primary-100);
  }
  .home-container03 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-image: linear-gradient(90deg, rgb(210, 235, 215) 0.00%,rgb(195, 227, 224) 100.00%);
  }
  .home-container04 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    max-width: 1600px;
    align-items: flex-start;
  }
  .home-container05 {
    flex: 0 0 auto;
    width: 50%;
    height: auto;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    padding-left: 0px;
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-image {
    width: var(--dl-size-size-maxwidth);
    height: auto;
    object-fit: cover;
  }
  .home-container06 {
    flex: 0 0 auto;
    width: 50%;
    height: auto;
    display: block;
    padding: var(--dl-space-space-twounits);
    align-items: flex-start;
    flex-direction: column;
  }
  .home-container07 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
  }
  .home-text05 {
    color: var(--dl-color-gray-white);
    width: 30%;
    padding: var(--dl-space-space-halfunit);
    font-size: 25px;
    font-style: normal;
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
    border-radius: 5px;
    background-image: linear-gradient(90deg, rgb(88, 181, 107) 0.00%,rgb(31, 149, 141) 100.00%);
  }
  .home-text06 {
    font-size: 25px;
    margin-top: var(--dl-space-space-twounits);
    font-family: Poppins;
  }
  .home-text10 {
    width: 100%;
    font-size: 20px;
    font-style: normal;
    margin-top: var(--dl-space-space-threeunits);
    font-family: Poppins;
    font-weight: 400;
    line-height: 1.5;
  }
  .home-container08 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
  }
  .home-navlink1 {
    color: var(--dl-color-primary-100);
    cursor: pointer;
    height: auto;
    font-size: 18px;
    font-style: normal;
    margin-top: var(--dl-space-space-threeunits);
    transition: 0.3s;
    font-family: Poppins;
    font-weight: 500;
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-100);
    padding-left: var(--dl-space-space-threeunits);
    border-radius: 50px;
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-unit);
    text-decoration: none;
    background-color: transparent;
  }
  .home-navlink1:hover {
    color: var(--dl-color-gray-white);
    background-image: linear-gradient(0deg, rgb(88, 181, 107) 0.00%,rgb(31, 149, 141) 100.00%);
  }
  .home-container09 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-self: center;
    align-items: center;
    flex-direction: column;
  }
  .home-text15 {
    font-size: 25px;
    margin-top: var(--dl-space-space-unit);
    font-family: Poppins;
  }
  .home-div {
    width: 100%;
    height: 100%;
    margin-top: var(--dl-space-space-unit);
  }
  .home-container10 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-top: 0px;
    align-items: center;
    flex-direction: column;
  }
  .home-container11 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-self: center;
    margin-top: 0px;
    align-items: center;
    flex-direction: column;
  }
  .home-text16 {
    font-size: 25px;
    margin-top: 30px;
    font-family: Poppins;
  }
  .home-text17 {
    font-size: 20px;
    font-style: normal;
    margin-top: var(--dl-space-space-unit);
    font-family: Poppins;
    font-weight: 400;
  }
  .home-container12 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: none;
    align-items: flex-start;
  }
  .home-blog {
    width: 100%;
    display: flex;
    max-width: 1600px;
    align-items: center;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-threeunits);
    justify-content: space-between;
  }
  .home-container13 {
    width: 33.3%;
    display: flex;
    align-items: center;
    margin-right: var(--dl-space-space-twounits);
    justify-content: space-between;
  }
  .home-blog-post-card {
    width: 100%;
    height: 430px;
    display: flex;
    padding: var(--dl-space-space-unit);
    overflow: hidden;
    max-width: var(--dl-size-size-maxwidth);
    box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
    transition: 0.3s;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-image: linear-gradient(90deg, rgb(88, 181, 107) 0.00%,rgb(31, 149, 141) 100.00%);
  }
  .home-blog-post-card:hover {
    transform: scale(1.02);
  }
  .home-image01 {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  .home-container14 {
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: flex-start;
  }
  .home-text18 {
    color: var(--dl-color-gray-white);
    font-size: 20px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text19 {
    color: var(--dl-color-gray-white);
    font-size: 12px;
    font-style: normal;
    text-align: center;
    font-family: Poppins;
    font-weight: 300;
    margin-bottom: 0px;
  }
  .home-container15 {
    width: 33.3%;
    display: flex;
    align-items: center;
    margin-right: var(--dl-space-space-twounits);
    justify-content: space-between;
  }
  .home-blog-post-card01 {
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-unit);
    overflow: hidden;
    max-width: var(--dl-size-size-maxwidth);
    box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
    transition: 0.3s;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-image: linear-gradient(90deg, rgb(88, 181, 107) 0.00%,rgb(31, 149, 141) 100.00%);
  }
  .home-blog-post-card01:hover {
    transform: scale(1.02);
  }
  .home-image02 {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  .home-container16 {
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: flex-start;
  }
  .home-text20 {
    color: var(--dl-color-gray-white);
    font-size: 20px;
    font-style: normal;
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text21 {
    color: var(--dl-color-gray-white);
    font-size: 12px;
    font-style: normal;
    text-align: center;
    font-family: Poppins;
    font-weight: 300;
    margin-bottom: 0px;
  }
  .home-container17 {
    width: 33.3%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .home-blog-post-card02 {
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-unit);
    overflow: hidden;
    max-width: var(--dl-size-size-maxwidth);
    box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
    transition: 0.3s;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-image: linear-gradient(90deg, rgb(88, 181, 107) 0.00%,rgb(31, 149, 141) 100.00%);
  }
  .home-blog-post-card02:hover {
    transform: scale(1.02);
  }
  .home-image03 {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  .home-container18 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: flex-start;
  }
  .home-text22 {
    color: var(--dl-color-gray-white);
    font-size: 20px;
    align-self: center;
    font-style: normal;
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text23 {
    color: var(--dl-color-gray-white);
    width: 100%;
    font-size: 12px;
    font-style: normal;
    text-align: center;
    font-family: Poppins;
    font-weight: 300;
    margin-bottom: 0px;
  }
  .home-blog1 {
    width: 100%;
    display: flex;
    max-width: 1600px;
    align-items: center;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-threeunits);
    justify-content: space-between;
  }
  .home-container19 {
    width: 33.3%;
    display: flex;
    align-items: center;
    margin-right: var(--dl-space-space-twounits);
    justify-content: space-between;
  }
  .home-blog-post-card03 {
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-unit);
    overflow: hidden;
    max-width: var(--dl-size-size-maxwidth);
    box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
    transition: 0.3s;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-image: linear-gradient(90deg, rgb(88, 181, 107) 0.00%,rgb(31, 149, 141) 100.00%);
  }
  .home-blog-post-card03:hover {
    transform: scale(1.02);
  }
  .home-image04 {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  .home-container20 {
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-unit);
    justify-content: flex-start;
  }
  .home-text24 {
    color: var(--dl-color-gray-white);
    font-size: 20px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text25 {
    color: var(--dl-color-gray-white);
    font-size: 12px;
    font-style: normal;
    text-align: center;
    font-family: Poppins;
    font-weight: 300;
    margin-bottom: 0px;
  }
  .home-container21 {
    width: 33.3%;
    display: flex;
    align-items: center;
    margin-right: var(--dl-space-space-twounits);
    justify-content: space-between;
  }
  .home-blog-post-card04 {
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-unit);
    overflow: hidden;
    max-width: var(--dl-size-size-maxwidth);
    box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
    transition: 0.3s;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-image: linear-gradient(90deg, rgb(88, 181, 107) 0.00%,rgb(31, 149, 141) 100.00%);
  }
  .home-blog-post-card04:hover {
    transform: scale(1.02);
  }
  .home-image05 {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  .home-container22 {
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: flex-start;
  }
  .home-text26 {
    color: var(--dl-color-gray-white);
    font-size: 20px;
    font-style: normal;
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text27 {
    color: var(--dl-color-gray-white);
    font-size: 12px;
    font-style: normal;
    text-align: center;
    font-family: Poppins;
    font-weight: 300;
    margin-bottom: 0px;
  }
  .home-container23 {
    width: 33.3%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .home-blog-post-card05 {
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-unit);
    overflow: hidden;
    max-width: var(--dl-size-size-maxwidth);
    box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
    transition: 0.3s;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-image: linear-gradient(90deg, rgb(88, 181, 107) 0.00%,rgb(31, 149, 141) 100.00%);
  }
  .home-blog-post-card05:hover {
    transform: scale(1.02);
  }
  .home-image06 {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  .home-container24 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: flex-start;
  }
  .home-text28 {
    color: var(--dl-color-gray-white);
    font-size: 20px;
    align-self: center;
    font-style: normal;
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text29 {
    color: var(--dl-color-gray-white);
    width: 100%;
    font-size: 12px;
    font-style: normal;
    text-align: center;
    font-family: Poppins;
    font-weight: 300;
    margin-bottom: 0px;
  }
  .home-container25 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-self: center;
    margin-top: var(--dl-space-space-twounits);
    align-items: center;
    margin-bottom: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: center;
  }
  .home-text32 {
    font-size: 25px;
    font-family: Poppins;
  }
  .home-container26 {
    flex: 0 0 auto;
    width: 100%;
    height: aut;
    display: none;
    align-items: flex-start;
  }
  .home-blog2 {
    width: 100%;
    display: flex;
    max-width: 1600px;
    align-items: center;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-threeunits);
    justify-content: space-between;
  }
  .home-container27 {
    width: 33.3%;
    display: flex;
    align-items: center;
    margin-right: var(--dl-space-space-twounits);
    justify-content: space-between;
  }
  .home-blog-post-card06 {
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-unit);
    overflow: hidden;
    max-width: var(--dl-size-size-maxwidth);
    box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
    transition: 0.3s;
    align-items: center;
    border-color: var(--dl-color-primary-100);
    border-width: 1px;
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-gray-white);
  }
  .home-blog-post-card06:hover {
    transform: scale(1.02);
  }
  .home-image07 {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  .home-container28 {
    display: flex;
    padding: var(--dl-space-space-unit);
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: flex-start;
  }
  .home-text33 {
    color: var(--dl-color-gray-black);
    font-size: 20px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-button {
    cursor: pointer;
    font-style: normal;
    transition: 0.3s;
    font-family: Poppins;
    font-weight: 500;
    border-color: var(--dl-color-primary-100);
    border-radius: 50px;
    text-decoration: none;
  }
  .home-button:hover {
    color: var(--dl-color-gray-white);
    background-color: transparent;
    background-image: linear-gradient(90deg, rgb(88, 181, 107) 3.00%,rgb(31, 149, 141) 100.00%);
  }
  .home-container29 {
    width: 33.3%;
    display: flex;
    align-items: center;
    margin-right: var(--dl-space-space-twounits);
    justify-content: space-between;
  }
  .home-blog-post-card07 {
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-unit);
    overflow: hidden;
    max-width: var(--dl-size-size-maxwidth);
    box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
    transition: 0.3s;
    align-items: center;
    border-color: var(--dl-color-primary-100);
    border-width: 1px;
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-gray-white);
  }
  .home-blog-post-card07:hover {
    transform: scale(1.02);
  }
  .home-image08 {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  .home-container30 {
    display: flex;
    padding: var(--dl-space-space-unit);
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: flex-start;
  }
  .home-text34 {
    color: var(--dl-color-gray-black);
    font-size: 20px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-button1 {
    cursor: pointer;
    font-style: normal;
    transition: 0.3s;
    font-family: Poppins;
    font-weight: 500;
    border-color: var(--dl-color-primary-100);
    border-radius: 50px;
    text-decoration: none;
  }
  .home-button1:hover {
    color: var(--dl-color-gray-white);
    background-color: transparent;
    background-image: linear-gradient(90deg, rgb(88, 181, 107) 3.00%,rgb(31, 149, 141) 100.00%);
  }
  .home-container31 {
    width: 33.3%;
    display: flex;
    align-items: center;
    margin-right: var(--dl-space-space-twounits);
    justify-content: space-between;
  }
  .home-blog-post-card08 {
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-unit);
    overflow: hidden;
    max-width: var(--dl-size-size-maxwidth);
    box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
    transition: 0.3s;
    align-items: center;
    border-color: var(--dl-color-primary-100);
    border-width: 1px;
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-gray-white);
  }
  .home-blog-post-card08:hover {
    transform: scale(1.02);
  }
  .home-image09 {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  .home-container32 {
    display: flex;
    padding: var(--dl-space-space-unit);
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: flex-start;
  }
  .home-text37 {
    color: var(--dl-color-gray-black);
    font-size: 20px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-button2 {
    cursor: pointer;
    font-style: normal;
    transition: 0.3s;
    font-family: Poppins;
    font-weight: 500;
    border-color: var(--dl-color-primary-100);
    border-radius: 50px;
    text-decoration: none;
  }
  .home-button2:hover {
    color: var(--dl-color-gray-white);
    background-color: transparent;
    background-image: linear-gradient(90deg, rgb(88, 181, 107) 3.00%,rgb(31, 149, 141) 100.00%);
  }
  .home-container33 {
    width: 33.3%;
    display: flex;
    align-items: center;
    margin-right: 0px;
    justify-content: space-between;
  }
  .home-blog-post-card09 {
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-unit);
    overflow: hidden;
    max-width: var(--dl-size-size-maxwidth);
    box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
    transition: 0.3s;
    align-items: center;
    border-color: var(--dl-color-primary-100);
    border-width: 1px;
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-gray-white);
  }
  .home-blog-post-card09:hover {
    transform: scale(1.02);
  }
  .home-image10 {
    width: 90%;
    height: 250px;
    object-fit: cover;
  }
  .home-container34 {
    display: flex;
    padding: var(--dl-space-space-unit);
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: flex-start;
  }
  .home-text40 {
    color: var(--dl-color-gray-black);
    font-size: 20px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-button3 {
    cursor: pointer;
    font-style: normal;
    transition: 0.3s;
    font-family: Poppins;
    font-weight: 500;
    border-color: var(--dl-color-primary-100);
    border-radius: 50px;
    text-decoration: none;
  }
  .home-button3:hover {
    color: var(--dl-color-gray-white);
    background-color: transparent;
    background-image: linear-gradient(90deg, rgb(88, 181, 107) 3.00%,rgb(31, 149, 141) 100.00%);
  }
  .home-blog3 {
    width: 100%;
    display: flex;
    max-width: 1600px;
    align-self: center;
    align-items: center;
    justify-content: space-between;
  }
  .home-container35 {
    width: 33.3%;
    display: flex;
    align-items: center;
    margin-right: var(--dl-space-space-twounits);
    justify-content: space-between;
    padding-bottom:30px
  }
  .home-blog-post-card10 {
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-unit);
    overflow: hidden;
    max-width: var(--dl-size-size-maxwidth);
    box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
    transition: 0.3s;
    align-items: center;
    border-color: var(--dl-color-primary-100);
    border-width: 1px;
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-gray-white);
  }
  .home-blog-post-card10:hover {
    transform: scale(1.02);
  }
  .home-image11 {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  .home-container36 {
    display: flex;
    padding: var(--dl-space-space-unit);
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: flex-start;
  }
  .home-text41 {
    color: var(--dl-color-gray-black);
    font-size: 20px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-button4 {
    cursor: pointer;
    font-style: normal;
    transition: 0.3s;
    font-family: Poppins;
    font-weight: 500;
    border-color: var(--dl-color-primary-100);
    border-radius: 50px;
    text-decoration: none;
  }
  .home-button4:hover {
    color: var(--dl-color-gray-white);
    background-color: transparent;
    background-image: linear-gradient(90deg, rgb(88, 181, 107) 3.00%,rgb(31, 149, 141) 100.00%);
  }
  .home-container37 {
    width: 33.3%;
    display: flex;
    align-items: center;
    margin-right: var(--dl-space-space-twounits);
    justify-content: space-between;
  }
  .home-blog-post-card11 {
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-unit);
    overflow: hidden;
    max-width: var(--dl-size-size-maxwidth);
    box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
    transition: 0.3s;
    align-items: center;
    border-color: var(--dl-color-primary-100);
    border-width: 1px;
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-gray-white);
  }
  .home-blog-post-card11:hover {
    transform: scale(1.02);
  }
  .home-image12 {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  .home-container38 {
    display: flex;
    padding: var(--dl-space-space-unit);
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: flex-start;
  }
  .home-text42 {
    color: var(--dl-color-gray-black);
    font-size: 20px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-button5 {
    cursor: pointer;
    font-style: normal;
    transition: 0.3s;
    font-family: Poppins;
    font-weight: 500;
    border-color: var(--dl-color-primary-100);
    border-radius: 50px;
    text-decoration: none;
  }
  .home-button5:hover {
    color: var(--dl-color-gray-white);
    background-color: transparent;
    background-image: linear-gradient(90deg, rgb(88, 181, 107) 3.00%,rgb(31, 149, 141) 100.00%);
  }
  .home-container39 {
    width: 33.3%;
    display: flex;
    align-items: center;
    margin-right: var(--dl-space-space-twounits);
    justify-content: space-between;
  }
  .home-blog-post-card12 {
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-unit);
    overflow: hidden;
    max-width: var(--dl-size-size-maxwidth);
    box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
    transition: 0.3s;
    align-items: center;
    border-color: var(--dl-color-primary-100);
    border-width: 1px;
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-gray-white);
  }
  .home-blog-post-card12:hover {
    transform: scale(1.02);
  }
  .home-image13 {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  .home-container40 {
    display: flex;
    padding: var(--dl-space-space-unit);
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: flex-start;
  }
  .home-text45 {
    color: var(--dl-color-gray-black);
    font-size: 20px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-button6 {
    cursor: pointer;
    font-style: normal;
    transition: 0.3s;
    font-family: Poppins;
    font-weight: 500;
    border-color: var(--dl-color-primary-100);
    border-radius: 50px;
    text-decoration: none;
  }
  .home-button6:hover {
    color: var(--dl-color-gray-white);
    background-color: transparent;
    background-image: linear-gradient(90deg, rgb(88, 181, 107) 3.00%,rgb(31, 149, 141) 100.00%);
  }
  .home-container41 {
    width: 33.3%;
    display: flex;
    align-items: center;
    margin-right: 0px;
    flex-direction: row;
    justify-content: space-between;
  }
  .home-blog-post-card13 {
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-unit);
    overflow: hidden;
    max-width: var(--dl-size-size-maxwidth);
    box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
    transition: 0.3s;
    align-items: center;
    border-color: var(--dl-color-primary-100);
    border-width: 1px;
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-gray-white);
  }
  .home-blog-post-card13:hover {
    transform: scale(1.02);
  }
  .home-image14 {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  .home-container42 {
    display: flex;
    padding: var(--dl-space-space-unit);
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: flex-start;
  }
  .home-text48 {
    color: var(--dl-color-gray-black);
    font-size: 20px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-button7 {
    cursor: pointer;
    font-style: normal;
    transition: 0.3s;
    font-family: Poppins;
    font-weight: 500;
    border-color: var(--dl-color-primary-100);
    border-radius: 50px;
    text-decoration: none;
  }
  .home-button7:hover {
    color: var(--dl-color-gray-white);
    background-color: transparent;
    background-image: linear-gradient(90deg, rgb(88, 181, 107) 3.00%,rgb(31, 149, 141) 100.00%);
  }
  .home-container43 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    margin-top: var(--dl-space-space-unit);
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-twounits);
    justify-content: center;
  }
  .home-button8 {
    cursor: pointer;
    font-style: normal;
    transition: 0.3s;
    font-family: Poppins;
    font-weight: 500;
    border-color: var(--dl-color-primary-100);
    border-radius: 50PX;
    margin-top: 20px;
  }
  .home-button8:hover {
    color: var(--dl-color-gray-white);
    box-shadow: 5px 5px 5px 0px #d4d4d4;
    background-color: var(--dl-color-primary-100);
  }
  .home-container44 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 0px;
    justify-content: center;
  }
  .home-div1 {
    width: 100%;
  }
  @media(max-width: 1600px) {
    .home-container01 {
      height: 600px;
    }
    .home-container05 {
      padding-left: var(--dl-space-space-threeunits);
    }
    .home-image {
      width: 100%;
    }
    .home-text05 {
      font-size: 1.3em;
    }
    .home-text10 {
      font-size: 25px;
      margin-top: var(--dl-space-space-unit);
      line-height: 1.5;
    }
    .home-navlink1 {
      color: var(--dl-color-primary-100);
      cursor: pointer;
      font-size: 20px;
      font-style: normal;
      margin-top: var(--dl-space-space-unit);
      font-family: Poppins;
      font-weight: 400;
      border-color: var(--dl-color-primary-100);
      padding-left: var(--dl-space-space-twounits);
      border-radius: 50px;
      padding-right: var(--dl-space-space-twounits);
    }
    .home-blog {
      margin-top: var(--dl-space-space-twounits);
      max-height: auto;
      padding-top: 0px;
      padding-left: var(--dl-space-space-threeunits);
      margin-bottom: var(--dl-space-space-twounits);
      padding-right: var(--dl-space-space-threeunits);
  
    }
    .home-blog-post-card {
      max-height: var(--dl-size-size-maxwidth);
    }
    .home-text18 {
      margin-bottom: var(--dl-space-space-unit);
    }
    .home-text19 {
      margin-bottom: var(--dl-space-space-unit);
    }
    .home-blog-post-card01 {
      max-height: 100%;
    }
    .home-text21 {
      margin-bottom: var(--dl-space-space-unit);
    }
    .home-container17 {
      height: 100%;
    }
    .home-blog1 {
      padding-top: 0px;
      padding-left: var(--dl-space-space-threeunits);
      padding-right: var(--dl-space-space-threeunits);
    }
    .home-text26 {
      margin-bottom: var(--dl-space-space-unit);
    }
    .home-text27 {
      margin-bottom: var(--dl-space-space-unit);
    }
    .home-container25 {
      margin-top: 0px;
    }
    .home-blog2 {
      padding-top: 0px;
      padding-left: var(--dl-space-space-threeunits);
      padding-right: var(--dl-space-space-threeunits);
      padding-bottom: var(--dl-space-space-threeunits);
    }
    .home-blog-post-card06 {
      height: 100%;
      padding-left: var(--dl-space-space-halfunit);
    }
    .home-container28 {
      width: 100%;
      padding-left: 0px;
      padding-right: 0px;
    }
    .home-text33 {
      font-size: 16px;
      text-align: center;
      margin-bottom: var(--dl-space-space-twounits);
    }
    .home-blog-post-card07 {
      height: 450%;
    }
    .home-container30 {
      width: 100%;
      padding-top: var(--dl-space-space-twounits);
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: var(--dl-space-space-twounits);
    }
    .home-text34 {
      font-size: 16px;
      text-align: center;
    }
    .home-blog-post-card08 {
      height: 450%;
    }
    .home-container32 {
      width: 100%;
      padding-left: 0px;
      padding-right: 0px;
    }
    .home-text37 {
      font-size: 16px;
      text-align: center;
      margin-bottom: var(--dl-space-space-twounits);
    }
    .home-blog-post-card09 {
      height: 450%;
    }
    .home-container34 {
      width: 100%;
      padding-left: 0px;
      padding-right: 0px;
    }
    .home-text40 {
      font-size: 16px;
      text-align: center;
      margin-bottom: var(--dl-space-space-twounits);
    }
    .home-blog3 {
      padding-top: 0px;
      padding-left: var(--dl-space-space-threeunits);
      padding-right: var(--dl-space-space-threeunits);
    
    }
    .home-blog-post-card10 {
      height: 450px;
    }
    .home-text41 {
      font-size: 16px;
    }
    .home-blog-post-card11 {
      height: 450px;
    }
    .home-text42 {
      font-size: 16px;
    }
    .home-blog-post-card12 {
      height: 450px;
    }
    .home-text45 {
      font-size: 16px;
    }
    .home-blog-post-card13 {
      height: 450px;
    }
    .home-text48 {
      font-size: 16px;
    }
  }
  @media(max-width: 1200px) {
    .home-container01 {
      height: 500px;
    }
    .home-image {
      width: 100%;
      height: 385px;
      border-radius: 25px;
    }
    .home-text05 {
      width: 40%;
    }
    .home-text06 {
      font-size: 20px;
    }
    .home-text10 {
      font-size: 16px;
      margin-top: var(--dl-space-space-unit);
      line-height: 1.5;
    }
    .home-navlink1 {
      font-size: 16px;
      border-radius: 50px;
    }
    .home-text15 {
      font-size: 20px;
    }
    .home-text16 {
      font-size: 20px;
    }
    .home-text17 {
      font-size: 16px;
      text-align: center;
    }
    .home-blog {
      margin-top: var(--dl-space-space-twounits);
    }
    .home-container14 {
      padding-top: 40px;
      padding-left: var(--dl-space-space-twounits);
      padding-right: var(--dl-space-space-twounits);
      padding-bottom: 40px;
    }
    .home-text18 {
      text-align: center;
      margin-bottom: var(--dl-space-space-halfunit);
    }
    .home-container16 {
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
    }
    .home-text20 {
      margin-bottom: var(--dl-space-space-unit);
    }
    .home-blog-post-card02 {
      height: 100%;
    }
    .home-container18 {
      padding-left: var(--dl-space-space-halfunit);
      padding-right: var(--dl-space-space-halfunit);
    }
    .home-blog1 {
      padding-top: 0px;
    }
    .home-container20 {
      padding-top: 28px;
      padding-left: var(--dl-space-space-halfunit);
      padding-right: var(--dl-space-space-halfunit);
      padding-bottom: 28px;
    }
    .home-text24 {
      text-align: center;
    }
    .home-container22 {
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
    }
    .home-container24 {
      padding-left: var(--dl-space-space-halfunit);
      padding-right: var(--dl-space-space-halfunit);
    }
    .home-container25 {
      height: 100%;
      margin-top: 0px;
      margin-bottom: var(--dl-space-space-threeunits);
    }
    .home-text32 {
      font-size: 20px;
      margin-top: 0px;
      font-family: Poppins;
    }
    .home-blog2 {
      padding-top: 0px;
      padding-right: var(--dl-space-space-threeunits);
    }
    .home-container27 {
      width: 25%;
      margin-right: var(--dl-space-space-unit);
    }
    .home-blog-post-card06 {
      height: 450%;
      padding-left: var(--dl-space-space-unit);
    }
    .home-image07 {
      margin-bottom: 0px;
    }
    .home-container28 {
      padding-top: var(--dl-space-space-twounits);
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: var(--dl-space-space-twounits);
    }
    .home-text33 {
      text-align: center;
      margin-bottom: var(--dl-space-space-twounits);
    }
    .home-container29 {
      width: 25%;
      margin-right: var(--dl-space-space-unit);
    }
    .home-container30 {
      padding-top: var(--dl-space-space-twounits);
      padding-left: var(--dl-space-space-halfunit);
      padding-right: var(--dl-space-space-halfunit);
      padding-bottom: var(--dl-space-space-twounits);
    }
    .home-text34 {
      color: var(--dl-color-gray-black);
      font-size: 16px;
      font-style: normal;
      text-align: center;
      font-family: Poppins;
      font-weight: 600;
      margin-bottom: var(--dl-space-space-twounits);
    }
    .home-text35 {
      text-align: center;
    }
    .home-container31 {
      width: 25%;
      margin-right: var(--dl-space-space-unit);
    }
    .home-container32 {
      padding-top: var(--dl-space-space-twounits);
      padding-left: var(--dl-space-space-halfunit);
      padding-right: var(--dl-space-space-halfunit);
      padding-bottom: var(--dl-space-space-twounits);
    }
    .home-text37 {
      color: var(--dl-color-gray-black);
      font-size: 16px;
      font-style: normal;
      text-align: center;
      font-family: Poppins;
      font-weight: 600;
    }
    .home-text38 {
      text-align: center;
    }
    .home-container33 {
      width: 25%;
      margin-right: 0px;
    }
    .home-image10 {
      margin-bottom: 0px;
    }
    .home-container34 {
      padding-top: var(--dl-space-space-twounits);
      padding-left: var(--dl-space-space-halfunit);
      padding-right: var(--dl-space-space-halfunit);
      padding-bottom: var(--dl-space-space-twounits);
    }
    .home-text40 {
      text-align: center;
      margin-bottom: var(--dl-space-space-twounits);
    }
    .home-blog3 {
      padding-top: 0px;
      padding-right: var(--dl-space-space-threeunits);
    }
    .home-container35 {
      width: 25%;
      margin-right: var(--dl-space-space-unit);
    }
    .home-container36 {
      padding-top: 27px;
      padding-left: var(--dl-space-space-halfunit);
      padding-right: var(--dl-space-space-halfunit);
      padding-bottom: 27px;
    }
    .home-text41 {
      text-align: center;
      margin-bottom: var(--dl-space-space-twounits);
    }
    .home-container37 {
      width: 25%;
      margin-right: var(--dl-space-space-unit);
    }
    .home-container38 {
      padding-top: 30px;
      padding-left: var(--dl-space-space-halfunit);
      padding-right: var(--dl-space-space-halfunit);
      padding-bottom: 30px;
    }
    .home-text42 {
      color: var(--dl-color-gray-black);
      font-size: 16px;
      font-style: normal;
      text-align: center;
      font-family: Poppins;
      font-weight: 600;
      margin-bottom: var(--dl-space-space-twounits);
    }
    .home-text43 {
      text-align: center;
    }
    .home-container39 {
      width: 25%;
      margin-right: var(--dl-space-space-unit);
    }
    .home-container40 {
      padding-top: 30px;
      padding-left: var(--dl-space-space-halfunit);
      padding-right: var(--dl-space-space-halfunit);
      padding-bottom: 30px;
    }
    .home-text45 {
      color: var(--dl-color-gray-black);
      font-size: 16px;
      font-style: normal;
      text-align: center;
      font-family: Poppins;
      font-weight: 600;
    }
    .home-text46 {
      text-align: center;
    }
    .home-container41 {
      width: 25%;
      margin-right: 0px;
    }
    .home-container42 {
      padding-top: 27px;
      padding-left: var(--dl-space-space-halfunit);
      padding-right: var(--dl-space-space-halfunit);
      padding-bottom: 27px;
    }
    .home-text48 {
      text-align: center;
      margin-bottom: var(--dl-space-space-twounits);
    }
    .home-button8 {
      padding-top: var(--dl-space-space-unit);
      padding-left: var(--dl-space-space-twounits);
      padding-right: var(--dl-space-space-twounits);
      padding-bottom: var(--dl-space-space-unit);
      margin-top: 10px;
    }
  }
  @media(max-width: 991px) {
    .home-container01 {
      height: 400px;
      z-index: 100;
    }
    
    .home-container02 {
      justify-content: center;
    }
    .home-text {
      font-size: 3em;
      text-align: left;
      margin-left: var(--dl-space-space-fiveunits);
    }
    .home-image {
      width: 100%;
    }
    .home-text05 {
      padding: var(--dl-space-space-halfunit);
      font-size: 1em;
      border-radius: 5px;
    }
    .home-text06 {
      font-size: 20px;
      font-family: Poppins;
    }
    .home-text10 {
      font-size: 18px;
      font-style: normal;
      margin-top: var(--dl-space-space-unit);
      font-family: Poppins;
      font-weight: 400;
      line-height: 1.5;
    }
    .home-navlink1 {
      font-size: 14px;
    }
    .home-text15 {
      font-size: 25px;
    }
    .home-text16 {
      font-size: 25px;
    }
    .home-text17 {
      font-size: 18px;
      text-align: center;
    }
    .home-container12 {
      display: flex;
      margin-top: var(--dl-space-space-unit);
      margin-bottom: var(--dl-space-space-unit);
    }
    .home-blog {
      height: auto;
      display: none;
      padding-left: 120px;
      padding-right: 120px;
      flex-direction: column;
    }
    .home-container13 {
      width: 100%;
      margin-right: 0px;
      margin-bottom: var(--dl-space-space-threeunits);
    }
    .home-image01 {
      height: 350px;
    }
    .home-container15 {
      width: 100%;
      height: auto;
      margin-right: 0px;
      margin-bottom: var(--dl-space-space-threeunits);
    }
    .home-image02 {
      height: 350px;
    }
    .home-container17 {
      width: 100%;
      height: auto;
    }
    .home-image03 {
      height: 350px;
    }
    .home-blog1 {
      display: none;
      padding-top: 0px;
      padding-left: 120px;
      padding-right: 120px;
      flex-direction: column;
    }
    .home-container19 {
      width: 100%;
      margin-top: var(--dl-space-space-unit);
      margin-right: 0px;
      margin-bottom: var(--dl-space-space-threeunits);
    }
    .home-image04 {
      height: 350px;
    }
    .home-container21 {
      width: 100%;
      margin-right: 0px;
      margin-bottom: var(--dl-space-space-threeunits);
    }
    .home-image05 {
      height: 350px;
    }
    .home-container23 {
      width: 100%;
    }
    .home-image06 {
      height: 350px;
    }
    .home-text32 {
      font-size: 25px;
      margin-top: 40px;
    }
    .home-container26 {
      display: flex;
    }
    .home-blog2 {
      width: 100%;
      display: none;
      padding-top: 0px;
      padding-left: 120px;
      padding-right: 120px;
      flex-direction: column;
    }
    .home-container27 {
      width: 100%;
      margin-right: 0px;
      margin-bottom: var(--dl-space-space-threeunits);
    }
    .home-image07 {
      height: 350px;
    }
    .home-text33 {
      margin-bottom: var(--dl-space-space-twounits);
    }
    .home-container29 {
      width: 100%;
      margin-right: 0px;
      margin-bottom: var(--dl-space-space-threeunits);
    }
    .home-image08 {
      height: 350px;
    }
    .home-container31 {
      width: 100%;
      margin-right: 0px;
      margin-bottom: var(--dl-space-space-threeunits);
    }
    .home-image09 {
      height: 350px;
    }
    .home-container33 {
      width: 100%;
      margin-right: 0px;
      margin-bottom: 0px;
    }
    .home-image10 {
      height: 350px;
    }
    .home-text40 {
      margin-bottom: var(--dl-space-space-twounits);
    }
    .home-blog3 {
      display: none;
      padding-top: 0px;
      padding-left: 120px;
      padding-right: 120px;
      flex-direction: column;
    }
    .home-container35 {
      width: 100%;
      margin-right: 0px;
      margin-bottom: var(--dl-space-space-threeunits);
    }
    .home-image11 {
      height: 350px;
    }
    .home-container37 {
      width: 100%;
      margin-right: 0px;
      margin-bottom: var(--dl-space-space-threeunits);
    }
    .home-image12 {
      height: 350px;
    }
    .home-container39 {
      width: 100%;
      margin-right: 0px;
      margin-bottom: var(--dl-space-space-threeunits);
    }
    .home-image13 {
      height: 350px;
    }
    .home-container41 {
      width: 100%;
      margin-right: 0px;
      margin-bottom: 0px;
    }
    .home-image14 {
      height: 350px;
    }
  
  /*scroll-card*/
  ::-webkit-scrollbar {
    height: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #000000;
    border-radius: 25px;
  }
  ::-webkit-scrollbar-thumb {
    background: linear-gradient(50deg, #1F958D, #58B56B);
    border-radius: 25px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background:linear-gradient(50deg, #1F958D, #58B56B);
  }
  .container {
    display: flex;
    gap: 12px;
    width: 100%;
    background: linear-gradient(50deg, #58B56B, #1F958D);
    padding: 30px;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    scroll-padding: 30px;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
  }
  .container .card {
    display: flex;
    flex: 0 0 50%;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 12px;
    background: #fff;
    scroll-snap-align: start;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
  }
  .card .image {
    height: 150px;
    width: 150px;
    padding: 4px;
    background: #ffffff;
    border-radius: 50%;
  }
  .image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
    border: 5px solid #fff;
  }
  .card h2 {
    margin-top: 25px;
    margin-bottom: 10px;
    color: #333;
    font-size: 14px;
    font-weight: 900;
    color:#1F958D;
    background: #ffffff;
  
  }
  .card p {
    margin-top: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    text-align: center;
  }
  ::-webkit-scrollbar{
    width: 20px;
  }
  ::-webkit-scrollbar-track{
    background:#ffffff;
  }
  ::-webkit-scrollbar-thumb{
    background: linear-gradient(50deg, #1F958D, #58B56B);
    border-radius: 20px;
  }
  
  .container1 {
    display: flex;
    gap: 12px;
    width: 100%;
    background: #fff;
    padding: 30px;
    /*scroll-snap-type: x mandatory;*/
    overflow-x: scroll;
    scroll-padding: 30px;
   
  }
  .container1 .card1 {
    display: flex;
    flex: 0 0 50%;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
    padding-top: 30px;
    border-radius: 12px;
    background: #fff;
    scroll-snap-align: start;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
    border: 1px solid #1F958D;
  }
  .card1 .image1 {
    height: 175px;
    width: 175px;
    padding: 4px;
    background: #ffffff;
    border-radius: 50%;
  }
  .image1 img {
    height: 100%;
    width:100%;
    object-fit: cover;
    border-radius: 10px;
    border: 5px solid #fff;
    
  }
  .card1 h2 {
    margin-top: 25px;
    margin-bottom: 10px;
    color: #333;
    font-size: 14px;
    font-weight: 900;
    color:#1F958D;
    background: #ffffff;
  
  }
  .card1 p {
    margin-top: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    text-align: center;
  }
  .new a {
    padding: 10px 20px;
    border: solid 1px #1F958D;
    border-radius: 30px;
    cursor: pointer;
    font-size: 10px;
    margin-top: 20px;
    
  }
  .new a:hover {
    background-color: #58B56B;
    color: #fff;
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  }
  @media(max-width: 767px) {
    .home-container01 {
      height: 300px;
      align-items: center;
    }
    .home-container02 {
      height: auto;
      align-self: center;
      align-items: center;
      justify-content: center;
    }
    .home-text {
      font-size: 2.5em;
      text-align: center;
      margin-left: 0px;
    }
    .home-navlink {
      margin-left: 0px;
    }
    .home-container04 {
      align-items: center;
      flex-direction: column;
    }
    .home-container05 {
      width: 100%;
      flex-direction: row;
      justify-content: center;
    }
    .home-container06 {
      width: 100%;
      align-self: center;
    }
    .home-container07 {
      justify-content: center;
    }
    .home-text05 {
      width: 40%;
      font-size: 16px;
      text-align: center;
      border-radius: 20px;
    }
    .home-text06 {
      font-size: 14px;
      text-align: center;
    }
    .home-text10 {
      font-size: 12px;
      text-align: center;
      line-height: 1.5;
    }
    .home-container08 {
      height: auto;
      justify-content: center;
    }
    .home-navlink1 {
      text-align: center;
    }
    .home-text15 {
      font-size: 18px;
    }
    .home-text16 {
      font-size: 18px;
    }
    .home-text17 {
      font-size: 16px;
      text-align: center;
    }
    .home-blog {
      padding-left: var(--dl-space-space-twounits);
      padding-right: var(--dl-space-space-twounits);
    }
    .home-blog1 {
      padding-top: 0px;
      padding-left: var(--dl-space-space-twounits);
      padding-right: var(--dl-space-space-twounits);
    }
    .home-text32 {
      font-size: 18px;
    }
    .home-blog2 {
      padding-top: 0px;
      padding-left: var(--dl-space-space-twounits);
      padding-right: var(--dl-space-space-twounits);
    }
    .home-blog3 {
      padding-top: 0px;
      padding-left: var(--dl-space-space-twounits);
      padding-right: var(--dl-space-space-twounits);
    }
  
  /*scroll-card*/
  ::-webkit-scrollbar {
    height: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #000000;
    border-radius: 25px;
  }
  ::-webkit-scrollbar-thumb {
    background: linear-gradient(50deg, #1F958D, #58B56B);
    border-radius: 25px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background:linear-gradient(50deg, #1F958D, #58B56B);
  }
  .container {
    display: flex;
    gap: 12px;
    width: 100%;
    background: linear-gradient(50deg, #58B56B, #1F958D);
    padding: 30px;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    scroll-padding: 30px;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
  }
  .container .card {
    display: flex;
    flex: 0 0 50%;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 12px;
    background: #fff;
    scroll-snap-align: start;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
  }
  .card .image {
    height: 150px;
    width: 150px;
    padding: 4px;
    background: #ffffff;
    border-radius: 50%;
  }
  .image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
    border: 5px solid #fff;
  }
  .card h2 {
    margin-top: 25px;
    margin-bottom: 10px;
    color: #333;
    font-size: 14px;
    font-weight: 900;
    color:#1F958D;
    background: #ffffff;
  
  }
  .card p {
    margin-top: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    text-align: center;
  }
  ::-webkit-scrollbar{
    width: 20px;
  }
  ::-webkit-scrollbar-track{
    background:#ffffff;
  }
  ::-webkit-scrollbar-thumb{
    background: linear-gradient(50deg, #1F958D, #58B56B);
    border-radius: 20px;
  }
  
  .container1 {
    display: flex;
    gap: 12px;
    width: 100%;
    background: #fff;
    padding: 30px;
    /*scroll-snap-type: x mandatory;*/
    overflow-x: scroll;
    scroll-padding: 30px;
   
  }
  .container1 .card1 {
    display: flex;
    flex: 0 0 50%;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
    padding-top: 30px;
    border-radius: 12px;
    background: #fff;
    scroll-snap-align: start;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
    border: 1px solid #1F958D;
  }
  .card1 .image1 {
    height: 175px;
    width: 175px;
    padding: 4px;
    background: #ffffff;
    border-radius: 50%;
  }
  .image1 img {
    height: 100%;
    width:100%;
    object-fit: cover;
    border-radius: 10px;
    border: 5px solid #fff;
    
  }
  .card1 h2 {
    margin-top: 25px;
    margin-bottom: 10px;
    color: #333;
    font-size: 14px;
    font-weight: 900;
    color:#1F958D;
    background: #ffffff;
  
  }
  .card1 p {
    margin-top: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    text-align: center;
  }
  .new a {
    padding: 10px 20px;
    border: solid 1px #1F958D;
    border-radius: 30px;
    cursor: pointer;
    font-size: 10px;
    margin-top: 20px;
    
  }
  .new a:hover {
    background-color: #58B56B;
    color: #fff;
  }
  
  
  }
  
  
  
  
  @media(max-width: 479px) {
    .home-container01 {
      height: 300px;
      margin: 0px;
      align-self: center;
      align-items: center;
    }
    .home-text {
      height: auto;
      padding: var(--dl-space-space-unit);
      font-size: 25px;
    }
    .home-navlink {
      font-size: 12px;
     
    }
    .home-container03 {
      justify-content: center;
    }
    .home-container04 {
      align-items: center;
      flex-direction: column;
    }
    .home-container05 {
      width: 100%;
      padding: var(--dl-space-space-unit);
    }
    .home-image {
      width: 100%;
      height: 300PX;
      border-radius: 25px;
    }
    .home-container06 {
      width: 100%;
      padding: var(--dl-space-space-unit);
      align-self: center;
    }
    .home-text05 {
      width: 50%;
      font-size: 14px;
      text-align: center;
    }
    .home-text06 {
      font-size: 14px;
      margin-top: var(--dl-space-space-halfunit);
      text-align: center;
    }
    .home-text10 {
      font-size: 12px;
      font-style: normal;
      margin-top: var(--dl-space-space-unit);
      text-align: center;
      font-family: Poppins;
      font-weight: 500;
      line-height: 1.5;
    }
    .home-navlink1 {
      font-size: 12px;
      padding-top: var(--dl-space-space-halfunit);
      padding-bottom: var(--dl-space-space-halfunit);
    }
    .home-text15 {
      font-size: 14px;
    }
    .home-text16 {
      font-size: 14px;
    }
    .home-text17 {
      font-size: 12px;
      text-align: center;
      margin-bottom: 0px;
    }
    .home-blog {
      margin-top: var(--dl-space-space-unit);
      padding-top: var(--dl-space-space-twounits);
      padding-left: var(--dl-space-space-unit);
      margin-bottom: 0px;
      padding-right: var(--dl-space-space-unit);
      padding-bottom: var(--dl-space-space-twounits);
    }
    .home-blog1 {
      margin-top: 0px;
      padding-top: var(--dl-space-space-unit);
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
      padding-bottom: var(--dl-space-space-unit);
    }
    .home-container25 {
      margin-bottom: 0px;
    }
    .home-text32 {
      font-size: 14px;
      margin-top: var(--dl-space-space-unit);
     
    }
    .home-blog2 {
      margin-top: 0px;
      padding-top: var(--dl-space-space-twounits);
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
      padding-bottom: var(--dl-space-space-twounits);
    }
    .home-container33 {
      margin-bottom: 0px;
    }
    .home-blog3 {
      margin-top: 0px;
      padding-top: var(--dl-space-space-oneandhalfunits);
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
  
    }
    .home-container43 {
      margin-top: 0px;
    }
    .home-button8 {
      font-size: 12px;
      margin-top: 25px;
    }
  /*scroll-card*/
  ::-webkit-scrollbar {
    height: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #000000;
    border-radius: 25px;
  }
  ::-webkit-scrollbar-thumb {
    background: linear-gradient(50deg, #1F958D, #58B56B);
    border-radius: 25px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background:linear-gradient(50deg, #1F958D, #58B56B);
  }
  .container {
    display: flex;
    gap: 12px;
    width: 100%;
    background: linear-gradient(50deg, #58B56B, #1F958D);
    padding: 30px;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    scroll-padding: 30px;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
  }
  
  .container .card {
    display: flex;
    flex: 0 0 100%;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 12px;
    background: #fff;
    scroll-snap-align: start;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
  }
  .card .image {
    height: 150px;
    width: 150px;
    padding: 4px;
    background: #ffffff;
    border-radius: 50%;
  }
  .image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
    border: 5px solid #fff;
  }
  .card h2 {
    margin-top: 25px;
    margin-bottom: 10px;
    color: #333;
    font-size: 14px;
    font-weight: 900;
    color:#1F958D;
    background: #ffffff;
    text-align: center;
  }
  .card p {
    margin-top: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    text-align: center;
  }
  ::-webkit-scrollbar{
    width: 20px;
  }
  ::-webkit-scrollbar-track{
    background:#ffffff;
  }
  ::-webkit-scrollbar-thumb{
    background: linear-gradient(50deg, #1F958D, #58B56B);
    border-radius: 20px;
  }
  
  .container1 {
    display: flex;
    gap: 12px;
    width: 100%;
    background: #fff;
    padding: 30px;
    /*scroll-snap-type: x mandatory;*/
    overflow-x: scroll;
    scroll-padding: 30px;
   
  }
  .container1 .card1 {
    display: flex;
    flex: 0 0 100%;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
    padding-top: 30px;
    border-radius: 12px;
    background: #fff;
    scroll-snap-align: start;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
    border: 1px solid #1F958D;
  }
  .card1 .image1 {
    height: 175px;
    width: 175px;
    padding: 4px;
    background: #ffffff;
    border-radius: 50%;
  }
  .image1 img {
    height: 100%;
    width:100%;
    object-fit: cover;
    border-radius: 10px;
    border: 5px solid #fff;
    
  }
  .card1 h2 {
    margin-top: 25px;
    margin-bottom: 10px;
    color: #333;
    font-size: 14px;
    font-weight: 900;
    color:#1F958D;
    background: #ffffff;
  
  }
  .card1 p {
    margin-top: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    text-align: center;
  }
  .new a {
    padding: 10px 20px;
    border: solid 1px #1F958D;
    border-radius: 30px;
    cursor: pointer;
    font-size: 10px;
    margin-top: 20px;
    
  }
  .new a:hover {
    background-color: #58B56B;
    color: #fff;
  }
  
  }
  
  
  /*scroll-card*/
  ::-webkit-scrollbar {
    height: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #000000;
  }
  ::-webkit-scrollbar-thumb {
    background: linear-gradient(50deg, #1F958D, #58B56B);
    border-radius: 25px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background:linear-gradient(50deg, #1F958D, #58B56B);
  }
  /* Index.html css */
  html {
      line-height: 1.15;
  }
  
  body {
      margin: 0;
  }
  
  * {
      box-sizing: border-box;
      border-width: 0;
      border-style: solid;
  }
  
  /* p, li, ul, pre, div, h1, h2, h3, h4, h5, h6, figure, blockquote,
      figcaption {
      margin: 0;
      padding: 0;
  } */
  
  button {
      background-color: transparent;
  }
  
  button, input, optgroup, select, textarea {
      font-family: inherit;
      font-size: 100%;
      line-height: 1.15;
      margin: 0;
  }
  
  button, select {
      text-transform: none;
  }
  
  button, [type="button"], [type="reset"], [type="submit"] {
      -webkit-appearance: button;
  }
  
  button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner,
      [type="submit"]::-moz-focus-inner {
      border-style: none;
      padding: 0;
  }
  
  button:-moz-focus, [type="button"]:-moz-focus, [type="reset"]:-moz-focus,
      [type="submit"]:-moz-focus {
      outline: 1px dotted ButtonText;
  }
  
  a {
      color: inherit;
      text-decoration: inherit;
  }
  
  input {
      padding: 2px 4px;
  }
  
  img {
      display: block;
  }
  
  html {
      scroll-behavior: smooth
  }
  html {
      font-family: Inter;
      font-size: 16px;
  }
  
  body {
      font-weight: 400;
      font-style: normal;
      text-decoration: none;
      text-transform: none;
      letter-spacing: normal;
      line-height: 1.15;
      color: var(- -dl-color-gray-black);
      background-color: var(- -dl-color-gray-white);
  }
  [data-thq="thq-dropdown"]:hover>[data-thq="thq-dropdown-list"] {
      display: flex;
  }
  
  [data-thq="thq-dropdown"]:hover>div [data-thq="thq-dropdown-arrow"] {
      transform: rotate(90deg);
  }
  @media ( max-width : 3000px) {
      /* Button used to open the contact form - fixed at the bottom of the page */
      .open-button {
          border: none;
          cursor: pointer;
          position: fixed;
          bottom: 2%;
          right: 5%;
          z-index: 2;
      }
  
      /* The popup form - hidden by default */
      .form-popup {
          display: none;
          position: fixed;
          bottom: 10px;
          right: 15px;
          z-index: 9;
      }
  
      /* Add styles to the form container */
      .form-container {
          max-width: 300px;
          padding: 10px;
          background: linear-gradient(#1F958D, #58B56B);
          border-radius: 10px 10px 0 0;
          border: none;
          margin-bottom: 70px;
      }
  
      /* Full-width input fields */
      .form-container input[type=text], .form-container input[type=phone] {
          width: 100%;
          padding: 15px;
          margin: 5px 0 5px 0;
          border: none;
          background: #f1f1f1;
      }
  
      /* When the inputs get focus, do something */
      .form-container input[type=text]:focus, .form-container input[type=phone]:focus
          {
          background-color: #ddd;
          outline: none;
      }
  
      /* Set a style for the submit/login button */
      .form-container .btn {
          background-color: #ffffff;
          color: rgb(0, 0, 0);
          padding: 16px 20px;
          border: none;
          cursor: pointer;
          margin-bottom: 10px;
          opacity: 1;
          width: 100%;
      }
      .form-container .btn:hover {
          color: #ffffff;
          background-color: #000000;
      }
      /* Add a red background color to the cancel button */
      .form-container .cancel {
          background-color: red;
          border-radius: 50px;
          color: #ffffff;
          width: 50%;
          transform: translate(50%);
      }
      .form-container .cancel:hover {
          color: #ffffff;
          background-color: #b41010;
      }
      button img {
          width: 40px;
          left: 80%;
      }
  }
  
  @media ( max-width : 500px) {
      /* Button used to open the contact form - fixed at the bottom of the page */
      .open-button {
          border: none;
          cursor: pointer;
          position: fixed;
          bottom: 2%;
          right: 15%;
          z-index: 2;
      }
  
      /* The popup form - hidden by default */
      .form-popup {
          display: none;
          position: fixed;
          bottom: 10px;
          right: 15px;
          z-index: 9;
      }
  
      /* Add styles to the form container */
      .form-container {
          max-width: 300px;
          padding: 10px;
          background: linear-gradient(#1F958D, #58B56B);
          border-radius: 10px 10px 0 0;
          border: none;
          margin-bottom: 50px;
          align-content: center;
      }
  
      /* Full-width input fields */
      .form-container input[type=text], .form-container input[type=phone] {
          width: 100%;
          padding: 15px;
          margin: 5px 0 5px 0;
          border: none;
          background: #f1f1f1;
          margin-bottom: 20px;
      }
  
      /* When the inputs get focus, do something */
      .form-container input[type=text]:focus, .form-container input[type=phone]:focus
          {
          background-color: #ddd;
          outline: none;
      }
  
      /* Set a style for the submit/login button */
      .form-container .btn {
          background-color: #ffffff;
          color: rgb(0, 0, 0);
          padding: 10px 15px;
          border: none;
          cursor: pointer;
          margin-bottom: 10px;
          opacity: 1;
          width: 100%;
          font-size: 10px;
      }
      .form-container .btn:hover {
          color: #ffffff;
          background-color: #000000;
      }
      /* Add a red background color to the cancel button */
      .form-container .cancel {
          background-color: red;
          border-radius: 50px;
          color: #ffffff;
          width: 50%;
          transform: translate(50%);
          font-size: 10px;
      }
      .form-container .cancel:hover {
          color: #ffffff;
          background-color: #b41010;
      }
      button img {
          width: 40px;
          left: 80%;
      }
  }
  @media ( max-width : 3000px) {
      #myBtn {
          display: none;
          position: fixed;
          bottom: 20px;
          right: 30px;
          z-index: 99;
          font-size: 18px;
          border: none;
          outline: none;
          background: linear-gradient(50deg, #1F958D, #58B56B);
          color: white;
          cursor: pointer;
          padding: 10px;
          border-radius: 4px;
          font-weight: 700;
      }
  }
  
  @media ( max-width : 479px) {
      #myBtn {
          display: none;
          position: fixed;
          bottom: 20px;
          right: 10px;
          z-index: 99;
          font-size: 18px;
          border: none;
          outline: none;
          background: linear-gradient(50deg, #1F958D, #58B56B);
          color: white;
          cursor: pointer;
          padding: 5px;
          border-radius: 4px;
          font-weight: 700;
      }
  }
  @media ( max-width :3000px) {
      .whatsapp img {
          position: fixed;
          width: 50px;
          top: 92%;
          left: 20px;
          z-index: 2;
          cursor: pointer;
      }
      .whatsapp img:hover {
          transform: scale(1.2);
          fill: #1F958D;
      }
  }
  
  @media ( max-width :479px) {
      .whatsapp img {
          position: fixed;
          width: 30px;
          top: 93%;
          left: 20px;
          z-index: 2;
          cursor: pointer;
      }
      .whatsappimg:hover {
          transform: scale(1.3);
          fill: #1F958D;
      }
  }
  body {
      background: rgb(255, 255, 255);
  }
  
  .brand-carousel {
      background: rgb(255, 255, 255);
  }
  
  .owl-dots {
      text-align: center;
      margin-top: 4%;
  }
  
  .owl-dot {
      display: inline-block;
      height: 15px !important;
      width: 15px !important;
      background-color: #878787 !important;
      opacity: 0.8;
      border-radius: 50%;
      margin: 0 5px;
  }
  
  .owl-dot.active {
      background-color: #000 !important;
  }
  
  img {
      vertical-align: middle;
  }
  
  /* Position the image container (needed to position the left and right arrows) */
  .container {
      position: relative;
  }
  
  /* Hide the images by default */
  .mySlides {
      display: none;
  }
  
  /* Add a pointer when hovering over the thumbnail images */
  .cursor {
      cursor: pointer;
  }
  
  /* Next & previous buttons */
  .prev, .next {
      cursor: pointer;
      position: absolute;
      top: 40%;
      width: auto;
      padding: 16px;
      margin-top: -50px;
      color: white;
      font-weight: bold;
      font-size: 20px;
      border-radius: 0 3px 3px 0;
      user-select: none;
      -webkit-user-select: none;
  }
  
  /* Position the "next button" to the right */
  .next {
      right: 0;
      border-radius: 3px 0 0 3px;
  }
  
  /* On hover, add a black background color with a little bit see-through */
  .prev:hover, .next:hover {
      background: linear-gradient(50deg, #1F958D, #58B56B);
  }
  
  /* Number text (1/3 etc) */
  .numbertext {
      color: #f2f2f2;
      font-size: 12px;
      padding: 8px 12px;
      position: absolute;
      
  }
  
  /* Container for image text */
  .caption-container {
      text-align: center;
      background: linear-gradient(90deg, rgb(88, 181, 107) 1.00%,
          rgb(31, 149, 141) 100.00%);
      padding: 20px 16px;
      color: white;
  }
  
  @media ( max-width : 479px) {
      #caption {
          font-size: 12px;
      }
      .caption-container {
          padding: 10px 10px;
      }
  }
  
  .row:after {
      content: "";
      display: table;
      clear: both;
  }
  
  /* Six columns side by side */
  .column {
      float: left;
      width: 16.66%;
  }
  
  /* Add a transparency effect for thumnbail images */
  .demo {
      opacity: 0.6;
  }
  
  .active, .demo:hover {
      opacity: 1;
  }
  
  body {
      background: rgb(255, 255, 255);
  }
  
  .brand-carousel {
      background: rgb(255, 255, 255);
  }
  
  .owl-dots {
      text-align: center;
      margin-top: 4%;
  }
  
  .owl-dot {
      display: inline-block;
      height: 15px !important;
      width: 15px !important;
      background-color: #878787 !important;
      opacity: 0.8;
      border-radius: 50%;
      margin: 0 5px;
  }
  
  .owl-dot.active {
      background-color: #000 !important;
  }
  
  img {
      vertical-align: middle;
  }
  
  /* Position the image container (needed to position the left and right arrows) */
  .container {
      position: relative;
  }
  
  /* Hide the images by default */
  .mySlides {
      display: none;
  }
  
  /* Add a pointer when hovering over the thumbnail images */
  .cursor {
      cursor: pointer;
  }
  
  /* Next & previous buttons */
  .prev, .next {
      cursor: pointer;
      position: absolute;
      top: 40%;
      width: auto;
      padding: 16px;
      margin-top: -50px;
      color: white;
      font-weight: bold;
      font-size: 20px;
      border-radius: 0 3px 3px 0;
      user-select: none;
      -webkit-user-select: none;
  }
  
  /* Position the "next button" to the right */
  .next {
      right: 0;
      border-radius: 3px 0 0 3px;
  }
  
  /* On hover, add a black background color with a little bit see-through */
  .prev:hover, .next:hover {
      background: linear-gradient(50deg, #1F958D, #58B56B);
  }
  
  /* Number text (1/3 etc) */
  .numbertext {
      color: #f2f2f2;
      font-size: 12px;
      padding: 8px 12px;
      position: absolute;
      
  }
  
  /* Container for image text */
  .caption-container {
      text-align: center;
      background: linear-gradient(90deg, rgb(88, 181, 107) 1.00%,
          rgb(31, 149, 141) 100.00%);
      padding: 20px 16px;
      color: white;
  }
  
  @media ( max-width : 479px) {
      #caption {
          font-size: 12px;
      }
      .caption-container {
          padding: 10px 10px;
      }
  }
  
  .row:after {
      content: "";
      display: table;
      clear: both;
  }
  
  /* Six columns side by side */
  .column {
      float: left;
      width: 16.66%;
  }
  
  /* Add a transparency effect for thumnbail images */
  .demo {
      opacity: 0.6;
  }
  
  .active, .demo:hover {
      opacity: 1;
  }
  body {
      background: rgb(255, 255, 255);
  }
  
  .brand-carousel {
      background: rgb(255, 255, 255);
  }
  
  .owl-dots {
      text-align: center;
      margin-top: 4%;
  }
  
  .owl-dot {
      display: inline-block;
      height: 15px !important;
      width: 15px !important;
      background-color: #878787 !important;
      opacity: 0.8;
      border-radius: 50%;
      margin: 0 5px;
  }
  
  .owl-dot.active {
      background-color: #000 !important;
  }
  
  img {
      vertical-align: middle;
  }
  
  /* Position the image container (needed to position the left and right arrows) */
  .container {
      position: relative;
  }
  
  /* Hide the images by default */
  .mySlides {
      display: none;
  }
  
  /* Add a pointer when hovering over the thumbnail images */
  .cursor {
      cursor: pointer;
  }
  
  /* Next & previous buttons */
  .prev, .next {
      cursor: pointer;
      position: absolute;
      top: 40%;
      width: auto;
      padding: 16px;
      margin-top: -50px;
      color: white;
      font-weight: bold;
      font-size: 20px;
      border-radius: 0 3px 3px 0;
      user-select: none;
      -webkit-user-select: none;
  }
  
  /* Position the "next button" to the right */
  .next {
      right: 0;
      border-radius: 3px 0 0 3px;
  }
  
  /* On hover, add a black background color with a little bit see-through */
  .prev:hover, .next:hover {
      background: linear-gradient(50deg, #1F958D, #58B56B);
  }
  
  /* Number text (1/3 etc) */
  .numbertext {
      color: #f2f2f2;
      font-size: 12px;
      padding: 8px 12px;
      position: absolute;
      
  }
  
  /* Container for image text */
  .caption-container {
      text-align: center;
      background: linear-gradient(90deg, rgb(88, 181, 107) 1.00%,
          rgb(31, 149, 141) 100.00%);
      padding: 20px 16px;
      color: white;
  }
  
  @media ( max-width : 479px) {
      #caption {
          font-size: 12px;
      }
      .caption-container {
          padding: 10px 10px;
      }
  }
  
  .row:after {
      content: "";
      display: table;
      clear: both;
  }
  
  /* Six columns side by side */
  .column {
      float: left;
      width: 16.66%;
  }
  
  /* Add a transparency effect for thumnbail images */
  .demo {
      opacity: 0.6;
  }
  
  .active, .demo:hover {
      opacity: 1;
  }
  /* Styles for screens larger than or equal to 768px */
  @media (min-width: 768px) {
    .home-blog {
      display: flex;
      flex-wrap: wrap;
    }
  
    .home-container14 {
      width: 95.33%;
      padding: 10px;
    }
  
    .home-blog-post-card {
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 10px;
    }
  
    .home-image01 {
      width: 100%;
      height: auto;
    }
  
    .home-text18 {
      font-weight: bold;
      padding-top: 10px;
    }
  
    .home-text19 {
      color: #1e1c1c;
    }
  }
  
  /* Styles for screens smaller than 768px */
  @media (max-width: 767px) {
    .home-container12 {
      display: flex;
      flex-wrap: wrap;
    }
  
    .card {
      /* Add styles for cards in the smaller layout */
    }
  
    /* Add additional styles for other elements in the smaller layout */
  }
  
  
  
  
  
  
  .home-blog-post-card10 {
    flex-basis: 23%; /* 4 cards per row (100% / 4) */
    box-sizing: border-box;
    margin-bottom: 21px;
  }
  
  .clearfix::after {
    content: "";
    display: table;
    clear: both;
  }
  .home-blog-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Creates four columns with equal width */
    grid-gap: 35px; /* Adjust the gap between the cards as needed */
  }
  
  
  
`;