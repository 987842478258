import React,{useState,useEffect} from 'react'
import './style.css';
import { AboutContainer } from './AboutStyles';
function About() {
 
  return (
    <AboutContainer>
    <div>
  {/* <Header /> */}
	<head>

<title>About-us - Best and quality medical disposable
	manufacturer in UAE</title>
<meta name="description"
	content="We are the quality manufacturer of medical disposables in UAE for the past 7 years.  We provide Respiratory, PPE and nursing consumables, Surgical Kits, etc." />
<meta property="og:title"
	content="About-us - Best and quality medical disposable manufacturer in UAE " />
<meta property="og:description"
	content="We are the quality manufacturer of medical disposables in UAE for the past 7 years.  We provide Respiratory, PPE and nursing consumables, Surgical Kits, etc." />
<meta name="viewport" content="width=device-width, initial-scale=1.0" />
<meta charset="utf-8" />
<meta property="twitter:card" content="summary_large_image" />


<link rel="shortcut icon" href=
	'./playground_assets/32x32.png'
      type="icon/png" sizes="32x32" />
<link rel="stylesheet"
	href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap"
	data-tag="font" />
<link rel="stylesheet"
	href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap"
	data-tag="font" />
<link rel="stylesheet"
	href="https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap"
	data-tag="font" />
<link rel="stylesheet"
	href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&amp;display=swap"
	data-tag="font" />

	</head>
    <body>
   
		<div>
			<div class="about-us-container">
				<div class="about-us-container01">
					<div class="about-us-container02">
						<div class="about-us-container03">
							<h1 class="about-us-text">WHO WE ARE</h1>
							<span class="about-us-text01"> <span> Safecare
									Medical Industries was conceived and established in the year of
									2016 by Bin Ali group that plans to give a different dimension
									to the medical industries all across the Middle East. </span> <br />
								<br /> <span> Headquartered in Abu Dhabi UAE, SafeCare
									is a Domestic Profit Corporation providing quality Medical
									plastic consumable products across all GCC countries. With its
									registered subsidiary factories and subsidiary offices across
									the UK, USA, Germany, and China, SafeCare thrives on producing
									and providing quality medical products across the globe. </span> <br />
								<br /> <span> Safe care Medical Industries has built a
									worldwide reputation for providing high quality plastic
									consumables over the years. With a production area of 6000
									square meters, we manufacture all of our products in a
									controlled environment called Clean Room of class 10000(IS0 7)
									and 100000(ISO 8). Our manufacturing includes a wide variety of
									processes including Injection moulding, Blow Moulding,
									Thermoforming etc. and all those that are required for a world
									class medical manufacturing facility. </span> <br />
							</span>
						</div>
						<div class="about-us-container04">
							<img alt="group-92-1500w" src=
								'./playground_assets/group-92-1500w.png'
                class="about-us-image" />
						</div>
					</div>
          <div class="about-us-container05">
            <div class="about-us-container06">
              <img
                alt="group-92-1500w"
                 src='./playground_assets/group-92-1500w.png'
                class="about-us-image1"
              />
            </div>
            <div class="about-us-container07">
              <h1 class="about-us-text10">WHO WE ARE</h1>
              <span class="about-us-text11">
                <span>
                  Safecare Medical Industries was conceived and established in
                  the year of 2016 by Bin Ali group that plans to give a
                  different dimension to the medical industries all across the
                  Middle East.
                </span>
                <br />
                <br />
                <span>
                  Headquartered in Abu Dhabi UAE, SafeCare is a Domestic Profit
                  Corporation providing quality Medical plastic consumable
                  products across all GCC countries. With its registered
                  subsidiary factories and subsidiary offices across the UK,
                  USA, Germany, and China, SafeCare thrives on producing and
                  providing quality medical products across the globe.
                </span>
                <br />
                <br />
                <span>
                  Safe care Medical Industries has built a worldwide reputation
                  for providing high quality plastic consumables over the years.
                  With a production area of 6000 square meters, we manufacture
                  all of our products in a controlled environment called Clean
                  Room of class 10000(IS0 7) and 100000(ISO 8). Our
                  manufacturing includes a wide variety of processes including
                  Injection moulding, Blow Moulding, Thermoforming etc. and all
                  those that are required for a world class medical
                  manufacturing facility.
                </span>
                <br />
              </span>
            </div>
          </div>
          <div class="about-us-container08">
            <div class="about-us-container09">
              <h1 class="about-us-text20">Our Mission</h1>
              <span class="about-us-text21">
                Our mission is to transform our company into an admired,
                customer-friendly, and innovative medical solutions provider.
              </span>
            </div>
            <div class="about-us-container10">
              <h1 class="about-us-text22">Our Vision</h1>
              <span class="about-us-text23">
                <span>
                  Safecare Medical Industries exists to improve the quality of
                  human life by providing affordable, reliable, and innovative
                  products.
                </span>
                <br />
              </span>
            </div>
          </div>
          <div class="about-us-container11">
            <div class="about-us-container12">
              <h1 class="about-us-text26">Our Key Strengths</h1>
              <span class="about-us-text27">
                Who are in extremely love with eco friendly system.
              </span>
            </div>
          </div>
          <div class="about-us-container13">
            <div class="about-us-container14">
              <img
                alt="group-94-1500w"
                src='./playground_assets/group-94-1500w.png'
                class="about-us-image2"
              />
            </div>
            <div class="about-us-container15">
              <h1 class="about-us-text28">CLEAN ROOMS</h1>
              <span class="about-us-text29">
                <span class="about-us-text30">CLASSIFIED ISO 7, ISO 8</span>
                <br />
                <br />
                <span class="about-us-text33">
                  Spread over a total area of 1500 sq meters, Safecare
                  manufacturing takes places in the ISO 7 ISO 8 classified clean
                  room environement that has a low level of pollutants such as
                  dust. airbone microbes. aersol porticos and chemical vapours
                </span>
                <br />
              </span>
            </div>
          </div>
          <div class="about-us-container16">
            <div class="about-us-container17">
              <h1 class="about-us-text35">STORAGE SYSTEM</h1>
              <span class="about-us-text36">
                <span class="about-us-text37">HIGH TECH AUTOMATED&nbsp;</span>
                <br class="about-us-text38" />
                <br />
                <span>
                  Safecare holds the unique accomplishment of having the world's
                  finest automated storage system spread over a vast area of
                  30000 sq ft. The system ensures 100 percent efficiency in
                  storage without involving any manual interventions
                </span>
                <br />
              </span>
            </div>
            <div class="about-us-container18">
              <img
                alt="group-95-1500w"
                 src='./playground_assets/group-95-1500w.png'
                class="about-us-image3"
              />
            </div>
          </div>
          <div class="about-us-container19">
            <div class="about-us-container20">
              <img
                alt="group-95-1500w"
                src='./playground_assets/group-95-1500w.png'
                class="about-us-image4"
              />
            </div>
            <div class="about-us-container21">
              <h1 class="about-us-text42">WHO WE ARE</h1>
              <span class="about-us-text43">
                <span class="about-us-text44">HIGH TECH AUTOMATED</span>
                <br class="about-us-text45" />
                <br class="about-us-text46" />
                <span class="about-us-text47">
                  Safecare holds the unique accomplishment of having the world's
                  finest automated storage system spread over a vast area of
                  30000 sq ft. The system ensures 100 percent efficiency in
                  storage without involving any manual interventions
                </span>
                <br class="about-us-text48" />
              </span>
            </div>
          </div>
          <div class="about-us-container22">
            <div class="about-us-container23">
              <img
                alt="group_96-1500w"
                 src='./playground_assets/group_96-1500w.png'
                class="about-us-image5"
              />
            </div>
            <div class="about-us-container24">
              <h1 class="about-us-text49">PHARMA GRADE</h1>
              <span class="about-us-text50">
                <span class="about-us-text51">MADE WITH SUPREME</span>
                <br class="about-us-text52" />
                <br />
                <span class="about-us-text54">
                  Our high quality consumables are meant to be used in hospitals
                  and laboratories. We here at Safecare uses the supreme pharma
                  grade raw materials which are 95% resistant to all the
                  chemicals and acids
                </span>
                <br />
              </span>
            </div>
          </div>


				</div>
			</div>
		</div>
    <script
      data-section-id="header"
      src="https://unpkg.com/@teleporthq/teleport-custom-scripts"
    ></script>
    <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
	</body>
  {/* <Footer /> */}
    </div>
    </AboutContainer>
  )
}

export default About
