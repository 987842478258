import styled from 'styled-components';
export const GalleryContainer = styled.div`
*{
  padding: 0;
  margin: 0;
}
html{
  height: 100vh;
}
.total-col{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
body{
  background:linear-gradient(to left,#1F958D, #58B56B, #3AACB1);
  font-family: poppins;
  width: 100%;
  
}


.container{
  width: 100%;
  // margin: 50px auto;
 
  
}
.container h1{
  color: #000;
  font-size: 44px;
  text-align: center;
  padding-bottom: 30px;
  color: #fff;
}
.row{

  display: block;
  align-items: center;
  flex-wrap: wrap;

}
.col1{
 
  
  width: 100%;

}
.col2{
  
  
  width: 100%;

}

.feature-img{
  width: 50%;
  margin: auto;
  position: relative;
  border-radius: 10px ;
  overflow: hidden;
}
.small-img-row{
  display: flex;
  background: #fff;
  margin: 20px 20px;
  align-items: center;
  border-radius: 6px;
  overflow: hidden;
  width: 95%;
}
.small-img{
  position: relative;
}
.small-img img{
  width: 150px;

}
.small-img-row p{
  margin-left: 20px;
  color: #707070;
  line-height: 22px;
  font-size: 15px;
}
.play-btn{
  width: 60px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  cursor: pointer;
}
.small-img .play-btn{
  width: 35px;

}
.video-player{
  width: 80%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  display: none;
  position: fixed;

}
video:focus{
  outline: none;
}
.close1-btn{
  position: absolute;
  top: 10px;
  right: 10PX;
  width: 30px;
  cursor: pointer;
}

@media(max-width: 1400px) {
.container h1{
  font-size: 30px;
}
.small-img-row p{
  font-size: 15px;
}
}
@media(max-width: 1200px){
  .small-img-row {
      display: flex;
      background: #fff;
      margin: 10px 30px;
      align-items: center;
      border-radius: 6px;
      overflow: hidden;
      width: 90%;
  }
  .container h1{
      font-size: 25px;
  }
  .small-img img{
      width: 120px;
  
  }
  .feature-img{
      width: 70%;
  }
}
@media(max-width: 991px){
  .small-img img{
      width: 100px;
  
  }
  .container h1{
      font-size: 20px;
      padding-bottom: 20px;
  }
  .small-img-row p{
      font-size: 12px;
  }
  .play-btn{
      width: 40px;}

  .feature-img{
          width: 80%;
      }
}
@media(max-width: 767px){
  .row{

      display: block;
      align-items: center;
      flex-wrap: wrap;
  
  }
  .small-img-row{
      display: flex;
      background: #fff;
      margin: 10px auto;
      align-items: center;
      border-radius: 6px;
      overflow: hidden;
      width: 95%;
  }
  .container h1{
      font-size: 20px;
      padding-bottom: 10px;
  }

  .small-img-row p{
      margin-left: 10px;
  }
  
.container{
  width: 100%;
  margin: 20px auto;
  height: 100%;
  
  }


  .total-col{
      display: block;
      padding: 0 10px 0 5px;
      
  }

  .play-btn{
      width: 30px;
  }
  .feature-img{
          width: 95%;
      }

}
@media(max-width: 479px){

  .small-img-row{
      display: flex;
      background: #fff;
      margin: 10px 5px;
      align-items: center;
      border-radius: 6px;
      overflow: hidden;
      width: 95%;
  }
  .play-btn{
      width: 40px;}
  

  .small-img-row p{
      margin-left: 10px;
  }

  .feature-img{
      width: 95%;
  }
}



/*gallery css*/
.gallery {
  margin: 10px 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.gallery img {
  width: 230px;
  padding: 5px;
  filter:grayscale(100%) ;
  transition: 1s;
  
}
.gallery img:hover {
  filter: grayscale(0);
  cursor: pointer;
  transform: scale(1.05);

}
@media(max-width: 900px){
  .gallery img {
  width: 280px;
  padding: 5px;
  }
}
@media(max-width: 679px){
  .gallery img {
      width: 320px;
  padding: 5px;
  }
}
@media(max-width: 320px){
  .gallery img {
      width: 340px;
  padding: 5px;
  }
}
`;