import React, { useEffect, useState } from 'react';
import '../pages/style.css';
// import './header.css';
import { HeaderContainer } from './HeaderStyle';
import Wishlist from '../pages/wishlist';

export const fetchWishlist = async (sessionID, backendUrl, setCount, setWishlist,setMedicineList) => {
  const data = {
    sessionID: sessionID,
  };

  try {
    const response = await fetch(`${backendUrl}/getwishlist`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch wishlist count');
    }

    const responseData = await response.json();
    const wishlistCount = responseData.wishlistCount || 0; // Update the key to 'wishlistCount'
    setCount(wishlistCount);
    console.log("Updated count:", wishlistCount);
    const wishlist = responseData.wishlist;
    const medicinelist = responseData.medicinelist;
    setWishlist(wishlist);
    setMedicineList(medicinelist);
    
    
    return wishlist;
  } catch (error) {
    console.error(error);
    setCount(0);
    return []; // Return an empty array in case of an error
  }
};



function Header({ sessionID, backendUrl ,categoryList }) {
  const [count, setCount] = useState(0);
  const [wishlist, setWishlist] = useState([]);
  const [medicinelist, setMedicineList] = useState([]);


  const badgeStyle = {
    display: 'inline-block',
    padding: '7px 9px',
    borderRadius: '50%',
    backgroundColor: '#f8ff0b',
    color: '#fff',
    fontSize: '14px',
  };

  useEffect(() => {
    fetchWishlist(sessionID, backendUrl, setCount, setWishlist,setMedicineList);
  }, [sessionID, backendUrl, setWishlist,setMedicineList]);


  const handleClick = () => {
    // Handle click event
  };

const buttonStyle = {
  width: '140px',
  alignItems: 'center',
};

const linkStyle = {
  height: '40px',
  lineHeight: '40px',
  display: 'flex',
  color: '#1F958D',
  fontSize: '1.2em',
  textDecoration: 'none',
  paddingLeft: '35px',
};

const containerStyle = {
  paddingLeft: '15px',
  paddingRight: '15px',
  paddingTop: '5px',
  paddingBottom: '5px',
  position: 'relative',
  display: 'flex',
  borderRadius: '5px',
  alignItems: 'center',
  backgroundColor: '#ffffff',
  justifyContent: 'space-between',
  border: '#1F958D 2px solid',
  cursor: 'pointer',
};

const countStyle = {
  width: '30px',
  height: '30px',
  borderRadius: '50px',
  backgroundColor: '#ADFF2F',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  color: '#000000',
};

const imageStyle = {
  width: '30px',
  position: 'absolute',
  stroke: '#fff',
  fill: '#fff',
};

const hoverStyle = {
  backgroundColor: '#1f958d5d',
};
const handleDivClick = () => {
  window.location.href = '/wishlist'; 
};


  return (
   <HeaderContainer>
    <div>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css" integrity="sha512-iecdLmaskl7CVkqkXNQ/ZH/XLlvWZOJyj7Yy7tcenmpD1ypASozpmT/E0iPtmFIB46ZmdtAc9eNBvH0H/ZpiBw==" crossorigin="anonymous" referrerpolicy="no-referrer" />
      <div className="header-container">
      <div className="header-container01">
          <header data-role="Header" className="header-header">
          <div className="header-container02">
                <svg viewBox="0 0 1024 1024" className="header-icon">
                  <path
                    d="M282 460q96 186 282 282l94-94q20-20 44-10 72 24 152 24 18 0 30 12t12 30v150q0 18-12 30t-30 12q-300 0-513-213t-213-513q0-18 12-30t30-12h150q18 0 30 12t12 30q0 80 24 152 8 26-10 44z"></path>
                </svg>
                <a href="tel:+971-554 334 560" className="header-link">
                  <h1 className="header-text">+971-554 334 560</h1>
                </a>
              </div>
              <div className="header-container03">
                <svg viewBox="0 0 1024 1024" className="header-icon02">
                  <path
                    d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
                </svg>
                <a href="mailto:info@safecareind.com?subject="
                  className="header-link1">
                  <h1 className="header-text01">info@safecareind.com</h1>
                </a>
              </div>
              <div className="header-container04">
              <div className="header-container05">
                <img
                  alt="facebook"
                 src='./playground_assets/fb-200h.png'
                 className="header-image"
                />
                <img
                  alt="instagram"
                   src='./playground_assets/insta-200h.png'
                   className="header-image01"
                />
                <img
                  alt="twitter"
                 
                  className="header-image02" src='./playground_assets/twi-200h.png'
                />
                <a
                  href="https://www.youtube.com/@safecaremedicalindustries7705"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="header-link2"
                >
                  <img
                    alt="yahoo"
                    src='./playground_assets/yt-200h.png'
                    className="header-image03"
                  />
                </a>
              </div>
            </div>
            <div className="header-container06">
              <img
                alt="iso"
                  src='./playground_assets/iso-1500w.png'
                  className="header-image04"
              />
              <img
                alt="ce"
               src='./playground_assets/ce-1500w.png'
               className="header-image05"
              />
              <img
                alt="gmp"
                src='./playground_assets/gmp-1500w.png'
                className="header-image06"
              />
            </div>
            {/* <div className="btn1" style={buttonStyle}>
                <a href="#" style={linkStyle}>
                  Wishlist
                </a>
                <span style={countStyle}>1</span>
                <img src="./playground_assets/icon.svg" className="image" alt="wishlist" style={imageStyle} />
            </div> */}
            {/* <div className="header-container07"  data-sessionID={sessionID} style={{
                display: 'flex',
                flexDirection: 'row'
              }} onClick={handleDivClick} >
              <div>
              <img
                alt="wishlist"
                src="./playground_assets/icon.svg"
                className="header-image07"
              />
              <h1 className="header-text02">Wishlist</h1>
              </div>
              <div className="wishlist-count" data-wishlistcount={count}>
                {count > 0 && (
                  <span style={badgeStyle}>{count}</span>
                )}
              </div>
             
            </div> */}
            <div class="header-container07"  data-sessionID={sessionID}  onClick={handleDivClick}>
              <div class="button1" a href="#">
                <div>
                    <i class="fa fa-shopping-cart" aria-hidden="true"></i>
               </div>
               <div>
                    <p>Wishlist</p>
               </div>
                <div className="wishlist-count" data-wishlistcount={count}>
                {count > 0 && (
                  <span >{count}</span>
                )}
                </div>
              
            </div> 
          </div>
            </header>
            <header data-role="Header" className="header-header1">
            <nav>
            <div className="wrapper">
            <div className="logo"><a href="/"><img  alt='industry' src='./playground_assets/safecare industry-1500w.png'/></a></div>
            <div className="vertical-bar"></div>
                <input type="radio" name="slider" id="menu-btn"/>
                <input type="radio" name="slider" id="close-btn"/>
                <ul className="nav-links">
                <label htmlFor="close-btn" className="btn close-btn"><i className="fas fa-times"></i></label>
                  <li><a href="/" >HOME</a></li>
                  <li><a href="/about">ABOUT US</a></li>
                  <li>
                  <a  href="/product" className="desktop-item">PRODUCTS</a>
                    <input type="checkbox" id="showMega"/>
                    <label htmlFor="showMega" className="mobile-item">PRODUCTS</label>
                    <div className="mega-box">
                    <div className="content">
                   
  <div className="row" style={{padding:'10px'}}>
    <ul className="mega-links" style={{ whiteSpace: 'nowrap' }}>
      {/* <li style={{ display: 'inline', margin: '0 5px' }}>
        <a href={`/product`} style={{ fontSize: '13px', color: 'yellow' }}>All Products</a>
      </li> */}
      {categoryList.slice(0, 4).map((category) => (
        <li key={category._id} style={{ display: 'inline', margin: '0 5px' }}>
          <a href={`/product?categoryid=${category._id}`} style={{ fontSize: '13px' }}>
            {category.catename}
          </a>
        </li>
      ))}
    </ul>
  </div>

  {categoryList.slice(4).reduce((rows, category, index) => {
    if (index % 4 === 0) {
      rows.push([]);
    }
    rows[Math.floor(index / 4)].push(category);
    return rows;
  }, []).map((row, rowIndex) => (
    <div className="row" key={rowIndex} style={{padding:'10px'}}>
      <ul className="mega-links" style={{ whiteSpace: 'nowrap' }}>
        {row.map((category) => (
          <li key={category._id} style={{ display: 'inline', margin: '0 5px' }}>
            <a href={`/product?categoryid=${category._id}`} style={{ fontSize: '13px' }}>
              {category.catename}
            </a>
          </li>
        ))}
      </ul>
    </div>
  ))}


                      </div>
                    </div>
                  </li>
                  <li><a href="/gallery">GALLERY</a></li>
                  <li><a href="/careers">CAREERS</a></li>
                  <li><a href="/contact">CONTACT US</a></li>
                  <li>
                 <button> <a  href="/" className="desktop-item" style={{color:'white'}}>OUR BRANDS</a></button>
                    <input type="checkbox" id="showMegas"/>
                    <label htmlFor="showMegas" className="mobile-item" style={{color:'white'}}>OUR BRANDS</label>
                    <div className="mega-box">
                    <div className="content">
                    <div className="row">
                          <ul className="mega-links">
                            <li style={{fontSize:'13px',padding: '10px 20px'}}><a href="https://bensano.com/">Bensan</a></li>
                            <li style={{fontSize:'13px' ,padding: '10px 20px'}}><a href="https://denfiz.com/">Denfiz</a></li>
                            <li style={{fontSize:'13px' ,padding: '10px 20px'}}><a href="#">Sentinel</a></li>
                            <li style={{fontSize:'13px' ,padding: '10px 20px'}}><a href="#">Culture Media</a></li>
                          </ul>
                        </div>
                      
                      </div>
                    </div>
                  </li>      
                 
                  
                  </ul>














                  {/* <button onClick={handleClick}>OUR BRANDS</button> */}
                  {/* <li><a href="/contact">OUR BRANDS</a></li> */}
                <label htmlFor="menu-btn" className="btn menu-btn" style={{color: 'black'}} ><i class="fa-regular fa-bars" style={{fontFamily:'FontAwesome'}}></i></label>
                
              </div>
              </nav>
              </header>
            </div>
      </div>
      <script
      data-section-id="header"
      src="https://unpkg.com/@teleporthq/teleport-custom-scripts"
    ></script>
    <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>

    </div>
    </HeaderContainer>
  )
}

export default Header
