import React, { useState } from 'react';
import { WishlistContainer } from './WishlistStyle';
import Swal from "sweetalert2";

function Emailformat({backendUrl}) {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    const  emailAddresses = email.split(' ');
  const uniqueEmailAddresses = [...new Set(emailAddresses)];
  for (let i = 0; i < uniqueEmailAddresses.length; i++) {
    const emailAddress = uniqueEmailAddresses[i].trim();

    if (!emailAddress) {
      errors.email = 'Email is required';
      isValid = false;
      break;
    } else if (!/\S+@\S+\.\S+/.test(emailAddress)) {
      errors.email = ' email invalid';
      isValid = false;
      break;
    }
  }

    if (!message) {
      errors.message = 'Message is required';
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSubmit =  async  (e) => {
    e.preventDefault();

    if (validateForm()) {
        try {
            const response = await fetch(`${backendUrl}/postemail`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ email, message }), // Adjust the body payload based on your server-side requirements
            });
      
            if (response.ok) {
              // Handle successful form submission
            
              showAlert('Success!', 'Email sent successfully', 'success');
           
              setEmail('');
              setMessage('');
              setFormErrors({});
            } else {
              // Handle server-side error
              
              showAlert('error!', 'Error Sending Email', 'error');
            }
          } catch (error) {
            showAlert('error!', 'Error Sending Email', 'error');
          }

      // Reset the form inputs
      setEmail('');
      setMessage('');
      setFormErrors({});
    }
  };
  const showAlert = (title, text, icon) => {
    Swal.fire({
      title: title,
      text: text,
      icon: icon,
      confirmButtonText: 'OK',
    });
  };

  return (
    <div>
      <WishlistContainer>
        <head>
          <meta charset="UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <title>Email Testing</title>
          <link
            rel="stylesheet"
            href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css"
          />
          <script src="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/js/all.min.js"></script>
        </head>
        <body>
          <section class="bg-light my-12">
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="card shadow-0 border">
                    <div className="card-body">
                      <div className="form-body">
                        <div className="row">
                          <div className="col-lg-6">
                            {/* Use "col-lg-6" to make the form occupy half the width */}
                            <div className="form-holder">
                              <div className="form-content">
                                <div className="form-items">
                                  <h4>Email Testing</h4>
                                  <form className="was-validated" onSubmit={handleSubmit}>
                                    <div className="row">
                                      <div className="col-md-12">
                                      <input
                                            className={`form-control ${
                                                formErrors.email ? 'is-invalid' : ''
                                            }`}
                                            style={{
                                                marginBottom: '1rem',
                                                border: '1px solid black',
                                                width: '100%',
                                            }}
                                            type="text"
                                            name="email"
                                            id="email"
                                            placeholder="E-mail Addresses (separated by spaces)"
                                            value={email}
                                            required
                                            onChange={(e) => setEmail(e.target.value)}
                                            />
                                        {formErrors.email && (
                                          <div className="invalid-feedback">
                                            {formErrors.email}
                                          </div>
                                        )}
                                      </div>
                                      <div className="col-md-12">
                                        <textarea
                                          className={`form-control ${
                                            formErrors.message ? 'is-invalid' : ''
                                          }`}
                                          style={{
                                            marginBottom: '1rem',
                                            border: '1px solid black',
                                            width: '100%',
                                            height: '300px',
                                          }}
                                          id="message"
                                          name="message"
                                          rows="4"
                                          placeholder="Your content"
                                          value={message}
                                          required
                                          onChange={(e) => setMessage(e.target.value)}
                                        ></textarea>
                                        {formErrors.message && (
                                          <div className="invalid-feedback">
                                            {formErrors.message}
                                          </div>
                                        )}
                                      </div>
                                      <div className="col-md-12">
                                        <button
                                          id="submit"
                                          type="submit"
                                          className="btn btn-success"
                                        >
                                          Send Email
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </body>
      </WishlistContainer>
    </div>
  );
}

export default Emailformat;
