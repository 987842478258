import styled from 'styled-components';
export const HeaderContainer = styled.div`
.header-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  
  
  
  
  .header-container01 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: center;
    background-image: linear-gradient(90deg, rgb(88, 181, 107) 1.00%,rgb(31, 149, 141) 100.00%);
    
  }
  .header-header {
    width: 100%;
    height: 120px;
    display: flex;
    max-width: 1600px;
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    padding-left: 0px;
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: center;
  }
  .header-container02 {
    flex: 0 0 auto;
    width: 20%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header-icon {
    fill: #ffff;
    width: 40px;
    height: 40px;
  }
  .header-link {
    display: contents;
  }
  .header-text {
    color: rgb(255, 255, 255);
    font-size: 1.2rem;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 500;
    text-decoration: none;
  }
  .header-container03 {
    flex: 0 0 auto;
    width: 20%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header-icon02 {
    fill: #fff;
    width: 40px;
    height: 40px;
  }
  .header-link1 {
    display: contents;
  }
  .header-text01 {
    color: #f9f9f9;
    font-size: 1.2rem;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 500;
    margin-left: var(--dl-space-space-unit);
    text-decoration: none;
  }
  .header-container04 {
    flex: 0 0 auto;
    width: 20%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header-container05 {
    display: flex;
    position: relative;
    margin-top: 0px;
    align-items: center;
    justify-content: center;
  }
  .header-image {
    width: 30px;
    cursor: pointer;
    object-fit: cover;
    transition: 0.3s;
    margin-right: var(--dl-space-space-halfunit);
  }
  .header-image:hover {
    transform: scale(1.2);
  }
  .header-image01 {
    width: 30px;
    cursor: pointer;
    object-fit: cover;
    transition: 0.3s;
    margin-right: var(--dl-space-space-halfunit);
  }
  .header-image01:hover {
    transform: scale(1.2);
  }
  .header-image02 {
    width: 30px;
    cursor: pointer;
    object-fit: cover;
    transition: 0.3s;
    margin-right: var(--dl-space-space-halfunit);
  }
  .header-image02:hover {
    transform: scale(1.2);
  }
  .header-link2 {
    display: contents;
  }
  .header-image03 {
    width: 30px;
    cursor: pointer;
    object-fit: cover;
    transition: 0.3s;
    text-decoration: none;
  }
  .header-image03:hover {
    transform: scale(1.2);
  }
  .header-container06 {
    flex: 0 0 auto;
    width: 20%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header-image04 {
    width: 60px;
    object-fit: cover;
  }
  .header-image05 {
    width: 60px;
    object-fit: cover;
  }
  .header-image06 {
    width: 60px;
    object-fit: cover;
  }
  // .header-container07 {
  //   flex: 0 0 auto;
  //   width: 10%;
  //   height: auto;
  //   display: flex;
  //   padding: 5px 25px ;
  //   transition: 0.3s;
  //   align-items: center;
  //   border-color: #ffffff;
  //   border-width: 1px;
  //   border-radius: 20px;
  //   flex-direction: column;
  //   justify-content: center;
  //   cursor: pointer;
  // }
  // .header-container07:hover {
  //   background-color: #000000;
  // }
  .header-image07 {
    width: 25px;
    object-fit: cover;
  }
  .header-text02 {
    color: #ffffff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  .header-header1 {
    width: 100%;
    height: 100px;
    display: flex;
    max-width: 1600px;
    box-shadow: 5px 5px 15px 0px #363636;
    align-items: center;
    
   
    border-radius: 10px;
    
    
    justify-content: space-between;
    background-color: var(--dl-color-gray-white);
    border-bottom-left-radius: 10;
    border-bottom-right-radius: 10;
  }
  .header-navlink {
    display: contents;
  }
  .header-image08 {
    width: var(--dl-size-size-xlarge);
    height: auto;
    object-fit: cover;
    text-decoration: none;
  }
  .header-container08 {
    flex: 0 0 auto;
    width: 0.5px;
    height: 80px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-color: var(--dl-color-gray-500);
  }
  .header-nav {
    display: flex;
  }
  .header-nav1 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
  .header-container09 {
    display: flex;
    position: relative;
    margin-top: 0px;
    align-items: center;
    padding-top: 0px;
    flex-direction: row;
    justify-content: center;
  }
  .header-navlink1 {
    font-size: 22px;
    font-style: normal;
    transition: 0.3s;
    font-family: Montserrat;
    font-weight: 700;
    text-decoration: none;
  }
  .header-navlink1:hover {
    color: var(--dl-color-success-300);
  }
  .header-navlink1:active {
    color: var(--dl-color-success-300);
  }
  .header-navlink2 {
    font-size: 22px;
    font-style: normal;
    transition: 0.3s;
    font-family: Montserrat;
    font-weight: 700;
    margin-left: var(--dl-space-space-fourunits);
    text-decoration: none;
  }
  .header-navlink2:hover {
    color: var(--dl-color-success-300);
  }
  .header-navlink2:active {
    color: var(--dl-color-success-300);
  }
  .header-thq-dropdown {
    cursor: pointer;
    display: inline-block;
    position: relative;
    margin-left: var(--dl-space-space-twounits);
    border-radius: var(--dl-radius-radius-radius2);
  }
  .header-dropdown-toggle {
    fill: #595959;
    color: #000000;
    width: 100%;
    display: inline-flex;
    align-items: center;
    padding-top: 0px;
    padding-left: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-radius2);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 0px;
  }
  .header-navlink3 {
    color: #020202;
    font-size: 22px;
    font-style: normal;
    text-align: center;
    transition: 0.3s;
    font-family: Montserrat;
    font-weight: 700;
    margin-right: 4px;
    vertical-align: middle;
    text-decoration: none;
  }
  .header-navlink3:hover {
    color: var(--dl-color-success-300);
  }
  .header-navlink3:active {
    color: var(--dl-color-success-300);
  }
  .header-dropdown-arrow {
    transition: 0.3s;
  }
  .header-icon04 {
    fill: var(--dl-color-gray-black);
    width: 18px;
    height: 18px;
    margin-top: auto;
    margin-bottom: auto;
  }
  .header-container10 {
    flex: 0 0 auto;
    width: 80%;
    height: 100%;
    display: none;
    box-shadow: 5px 5px 10px 0px #d4d4d4;
    margin-top: 0px;
    align-items: flex-start;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    border-radius: var(--dl-radius-radius-radius4);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    background-image: linear-gradient(90deg, rgb(31, 149, 141) 0.00%,rgb(88, 181, 107) 100.00%);
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }
  .header-ul {
    list-style-position: inside;
  }
  .header-li {
    color: #000000;
    font-family: Montserrat;
    line-height: 2;
  }
  .header-text03 {
    color: #ffffff;
    margin-top: var(--dl-space-space-unit);
    font-family: Montserrat;
  }
  .header-separator {
    width: 50%;
    height: 1px;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
    background-color: #ffffff;
  }
  .header-text04 {
    color: #ffffff;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .header-li01 {
    color: #000000;
    font-family: Montserrat;
    line-height: 2;
  }
  .header-text05 {
    color: #ffffff;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .header-li02 {
    color: #000000;
    font-family: Montserrat;
    line-height: 2;
  }
  .header-text06 {
    color: #ffffff;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .header-li03 {
    color: #000000;
    font-family: Montserrat;
    line-height: 2;
  }
  .header-text07 {
    color: #ffffff;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .header-ul1 {
    width: 100%;
    height: auto;
    margin-top: var(--dl-space-space-fourunits);
  }
  .header-text08 {
    color: #ffffff;
    font-size: 20px;
    font-style: normal;
    margin-top: var(--dl-space-space-unit);
    font-family: Montserrat;
    font-weight: 600;
    line-height: 2;
  }
  .header-text09 {
    color: #ffffff;
    font-size: 20px;
    font-style: normal;
    margin-top: var(--dl-space-space-unit);
    font-family: Montserrat;
    font-weight: 600;
    line-height: 2;
  }
  .header-text10 {
    color: #ffffff;
    font-size: 20px;
    font-style: normal;
    margin-top: var(--dl-space-space-unit);
    font-family: Montserrat;
    font-weight: 600;
    line-height: 2;
  }
  .header-text11 {
    color: #ffffff;
    font-size: 20px;
    font-style: normal;
    margin-top: var(--dl-space-space-unit);
    font-weight: 600;
  }
  .header-ul2 {
    margin-top: 0px;
  }
  .header-text12 {
    color: #ffffff;
    margin-top: var(--dl-space-space-unit);
    font-family: Montserrat;
  }
  .header-separator1 {
    width: 50%;
    height: 1px;
    margin-top: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
    background-color: #ffffff;
  }
  .header-text13 {
    color: #ffffff;
    font-size: 20px;
    font-style: normal;
    margin-top: var(--dl-space-space-unit);
    font-family: Montserrat;
    font-weight: 600;
    line-height: 2;
  }
  .header-text14 {
    color: #ffffff;
    font-size: 20px;
    font-style: normal;
    margin-top: var(--dl-space-space-unit);
    font-family: Montserrat;
    font-weight: 600;
    line-height: 2;
  }
  .header-text15 {
    color: #ffffff;
    font-size: 20px;
    font-style: normal;
    margin-top: var(--dl-space-space-unit);
    font-family: Montserrat;
    font-weight: 600;
    line-height: 2;
  }
  .header-text16 {
    color: #ffffff;
    font-size: 20px;
    font-style: normal;
    margin-top: var(--dl-space-space-unit);
    font-family: Montserrat;
    font-weight: 600;
    line-height: 2;
  }
  .header-navlink4 {
    font-size: 22px;
    font-style: normal;
    transition: 0.3s;
    font-family: Montserrat;
    font-weight: 700;
    margin-left: var(--dl-space-space-unit);
    text-decoration: none;
  }
  .header-navlink4:hover {
    color: var(--dl-color-success-300);
  }
  .header-navlink4:active {
    color: var(--dl-color-success-300);
  }
  .header-navlink5 {
    font-size: 22px;
    font-style: normal;
    transition: 0.3s;
    font-family: Montserrat;
    font-weight: 700;
    margin-left: var(--dl-space-space-fourunits);
    text-decoration: none;
  }
  .header-navlink5:hover {
    color: var(--dl-color-success-300);
  }
  .header-navlink5:active {
    color: var(--dl-color-success-300);
  }
  .header-navlink6 {
    font-size: 22px;
    font-style: normal;
    transition: 0.3s;
    font-family: Montserrat;
    font-weight: 700;
    margin-left: var(--dl-space-space-fourunits);
    text-decoration: none;
  }
  .header-navlink6:hover {
    color: var(--dl-color-success-300);
  }
  .header-navlink6:active {
    color: var(--dl-color-success-300);
  }
  .header-icon-group {
    align-self: center;
  }
  .header-navlink7 {
    color: var(--dl-color-gray-white);
    cursor: pointer;
    font-size: 22px;
    font-style: normal;
    text-align: center;
    transition: 0.3s;
    font-family: Montserrat;
    font-weight: 700;
    padding-top: 38px;
    border-width: 0px;
    padding-left: 35px;
    border-radius: 10PX;
    padding-right: 35px;
    padding-bottom: 38px;
    text-decoration: none;
    background-image: linear-gradient(90deg, rgb(31, 149, 141) 0%, rgb(88, 181, 107) 99%);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .header-navlink7:hover {
    background-image: linear-gradient(90deg, rgb(0, 0, 0) 0.00%,rgb(0, 0, 0) 100.00%),linear-gradient(90deg, rgb(31, 149, 141) 0%, rgb(88, 181, 107) 99%);
  }
  .header-burger-menu {
    display: none;
  }
  .header-icon06 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    display: none;
  }
  .header-mobile-menu {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    display: none;
    padding: 32px;
    z-index: 100;
    position: absolute;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
  }
  .header-nav2 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .header-container11 {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-threeunits);
    justify-content: space-between;
  }
  .header-image09 {
    height: 2rem;
  }
  .header-close-mobile-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header-icon08 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .header-nav3 {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .header-text19 {
    margin-left: var(--dl-space-space-twounits);
  }
  .header-text20 {
    margin-left: var(--dl-space-space-twounits);
  }
  .header-text21 {
    margin-left: var(--dl-space-space-twounits);
  }
  .header-text22 {
    margin-left: var(--dl-space-space-twounits);
  }
  .header-text23 {
    margin-left: var(--dl-space-space-twounits);
  }
  .header-button {
    color: var(--dl-color-gray-white);
    font-size: 22px;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 700;
    padding-top: var(--dl-space-space-unit);
    border-width: 0px;
    padding-left: var(--dl-space-space-twounits);
    border-radius: 10PX;
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-image: linear-gradient(90deg, rgb(31, 149, 141) 0%, rgb(88, 181, 107) 99%);
  }
  @media(max-width: 1600px) {
    .header-header {
      height: 120px;
      max-width: 1400px;
      justify-content: space-between;
    }
    .header-container02 {
      width: 20%;
      height: auto;
    }
    .header-container03 {
      width: 30%;
      height: auto;
    }
    .header-container04 {
      width: 20%;
      height: auto;
    }
    .header-container05 {
      margin-top: 0px;
      flex-direction: row;
    }
    .header-container06 {
      width: 20%;
      height: auto;
    }
    // .header-container07 {
    //   width: 10%;
    //   height: auto;
    // }
    .header-header1 {
      width: 95%;
      max-width: 1300px;
      padding-right: 0px;
    }
    .header-nav {
      height: 100%;
    }
    .header-nav1 {
      height: 100%;
    }
    .header-container09 {
      height: auto;
    }
    .header-navlink1 {
      font-size: 16px;
      margin-left: 0px;
    }
    .header-navlink2 {
      font-size: 16px;
      margin-left: var(--dl-space-space-threeunits);
    }
    .header-thq-dropdown {
      margin-left: var(--dl-space-space-threeunits);
    }
    .header-dropdown-toggle {
      padding-left: 0px;
      padding-right: 0px;
    }
    .header-navlink3 {
      color: #000000;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
    }
    .header-text03 {
      font-style: normal;
      font-weight: 600;
    }
    .header-text12 {
      font-style: normal;
      font-weight: 600;
    }
    .header-navlink4 {
      font-size: 16px;
      margin-left: var(--dl-space-space-twounits);
    }
    .header-navlink5 {
      font-size: 16px;
      margin-left: var(--dl-space-space-threeunits);
    }
    .header-navlink6 {
      font-size: 16px;
      margin-left: var(--dl-space-space-threeunits);
    }
    .header-navlink7 {
      color: var(--dl-color-gray-white);
      cursor: pointer;
      display: flex;
      font-size: 16px;
      font-style: normal;
      text-align: center;
      align-items: center;
      font-family: Montserrat;
      font-weight: 700;
      padding-top: 42px;
      border-radius: 0px;
      flex-direction: row;
      padding-bottom: 42px;
      justify-content: center;
      background-image: linear-gradient(90deg, rgb(31, 149, 141) 0.00%,rgb(88, 181, 107) 99.00%);
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    .header-button {
      color: var(--dl-color-gray-white);
      font-size: 20px;
      font-style: normal;
      font-family: Montserrat;
      font-weight: 700;
      border-radius: 10PX;
      background-image: linear-gradient(90deg, rgb(31, 149, 141) 0%, rgb(88, 181, 107) 99%);
    }
  
    }
  
  @media(max-width: 1200px) {
  
    .header-header {
      max-width: 1000px;
      justify-content: space-between;
    }
    .header-container02 {
      width: 20%;
      height: auto;
      justify-content: center;
    }
    .header-icon {
      width: 20px;
      height: 20px;
    }
    .header-text {
      color: rgb(255, 255, 255);
      font-size: 16px;
      font-family: Montserrat;
    }
    .header-container03 {
      width: 20%;
      height: auto;
      margin-left: var(--dl-space-space-twounits);
      justify-content: center;
    }
    .header-icon02 {
      width: 20px;
      height: 20px;
    }
    .header-text01 {
      font-size: 1rem;
      text-align: left;
      margin-left: var(--dl-space-space-halfunit);
    }
    .header-container04 {
      width: 20%;
      height: auto;
      margin-left: 0px;
      justify-content: center;
    }
    .header-container06 {
      width: 20%;
      height: auto;
      margin-left: 10px;
     
    }
    // .header-container07 {
    //   width: 15%;
    //   height: auto;
    //   padding: 10px 20px;
    //   justify-content: flex-start;
    //   flex-direction: row;
    // }
    .header-text01 {
      font-size: 16px;
    }
    .header-header1 {
      width: 100%;
      height: 80px;
      max-width: 1000px;
      padding-top: var(--dl-space-space-unit);
      padding-left: var(--dl-space-space-unit);
      padding-right: 0px;
      padding-bottom: var(--dl-space-space-unit);
      justify-content: space-between;
    }
    .header-image08 {
      width: var(--dl-size-size-large);
    }
    .header-container08 {
      height: 60px;
    }
    .header-nav {
      align-items: center;
      justify-content: center;
    }
    .header-navlink1 {
      font-size: 16px;
      font-style: normal;
      font-family: Montserrat;
      font-weight: 700;
      margin-left: var(--dl-space-space-oneandhalfunits);
    }
    .header-navlink2 {
      font-size: 16px;
      font-style: normal;
      font-family: Montserrat;
      font-weight: 700;
      margin-left: var(--dl-space-space-oneandhalfunits);
    }
    .header-thq-dropdown {
      margin-left: var(--dl-space-space-oneandhalfunits);
    }
    .header-navlink3 {
      color: #000000;
      font-size: 16px;
      font-style: inherit;
      font-family: Montserrat;
      font-weight: 700;
    }
    .header-container10 {
      height: 100%;
      padding: var(--dl-space-space-threeunits);
      align-self: center;
      margin-left: 0px;
      flex-direction: row;
    }
    .header-ul {
      width: 33.3%;
      margin-top: var(--dl-space-space-unit);
      padding-left: 0px;
    }
    .header-li {
      font-family: Montserrat;
      margin-bottom: var(--dl-space-space-unit);
    }
    .header-text03 {
      font-size: 1.5em;
      margin-top: 0px;
      font-family: Montserrat;
      padding-left: 0rem;
      margin-bottom: var(--dl-space-space-unit);
    }
    .header-separator {
      width: 80%;
      margin-top: var(--dl-space-space-halfunit);
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: var(--dl-space-space-unit);
      background-color: #ffffff;
    }
    .header-text04 {
      font-size: 18px;
      font-style: normal;
      margin-top: 0px;
      font-weight: 600;
    }
    .header-li01 {
      font-family: Montserrat;
      margin-bottom: var(--dl-space-space-unit);
    }
    .header-text05 {
      font-size: 18px;
      font-style: normal;
      margin-top: 0px;
      font-weight: 600;
    }
    .header-li02 {
      font-family: Montserrat;
      margin-bottom: var(--dl-space-space-unit);
    }
    .header-text06 {
      font-size: 18px;
      font-style: normal;
      margin-top: 0px;
      font-weight: 600;
    }
    .header-li03 {
      font-family: Montserrat;
      margin-bottom: var(--dl-space-space-unit);
    }
    .header-text07 {
      font-size: 18px;
      font-style: normal;
      margin-top: 0px;
      font-weight: 600;
    }
    .header-ul1 {
      width: 33.3%;
      margin-top: var(--dl-space-space-threeunits);
      padding-left: 0px;
    }
    .header-li04 {
      font-family: Montserrat;
      margin-bottom: var(--dl-space-space-unit);
    }
    .header-text08 {
      font-size: 18px;
      font-style: normal;
      margin-top: 0px;
      font-weight: 600;
    }
    .header-li05 {
      font-family: Montserrat;
      margin-bottom: var(--dl-space-space-unit);
    }
    .header-text09 {
      font-size: 18px;
      font-style: normal;
      margin-top: 0px;
      font-weight: 600;
    }
    .header-li06 {
      font-family: Montserrat;
      margin-bottom: var(--dl-space-space-unit);
    }
    .header-text10 {
      font-size: 18px;
      font-style: normal;
      margin-top: 0px;
      font-weight: 600;
    }
    .header-li07 {
      font-family: Montserrat;
      margin-bottom: var(--dl-space-space-unit);
    }
    .header-text11 {
      font-size: 18px;
      font-style: normal;
      margin-top: 0px;
      font-weight: 600;
    }
    .header-ul2 {
      width: 33.3%;
      margin-top: var(--dl-space-space-unit);
      padding-left: 0px;
    }
    .header-li08 {
      font-family: Montserrat;
      margin-bottom: var(--dl-space-space-unit);
    }
    .header-text12 {
      font-size: 1.5em;
      margin-top: 0px;
      font-family: Montserrat;
      padding-left: 0rem;
      margin-bottom: var(--dl-space-space-unit);
    }
    .header-separator1 {
      width: 80%;
      margin-top: var(--dl-space-space-twounits);
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: var(--dl-space-space-twounits);
      background-color: #ffffff;
    }
    .header-text13 {
      font-size: 18px;
      font-style: normal;
      margin-top: 0px;
      font-weight: 600;
    }
    .header-li09 {
      font-family: Montserrat;
      margin-bottom: var(--dl-space-space-unit);
    }
    .header-text14 {
      font-size: 18px;
      font-style: normal;
      margin-top: 0px;
      font-weight: 600;
    }
    .header-li10 {
      font-family: Montserrat;
      margin-bottom: var(--dl-space-space-unit);
    }
    .header-text15 {
      font-size: 18px;
      font-style: normal;
      margin-top: 0px;
      font-weight: 600;
    }
    .header-li11 {
      font-family: Montserrat;
      margin-bottom: var(--dl-space-space-unit);
    }
    .header-text16 {
      font-size: 18px;
      font-style: normal;
      margin-top: 0px;
      font-weight: 600;
    }
    .header-navlink4 {
      font-size: 16px;
      font-style: normal;
      font-family: Montserrat;
      font-weight: 700;
      margin-left: var(--dl-space-space-unit);
    }
    .header-navlink5 {
      font-size: 16px;
      font-style: normal;
      font-family: Montserrat;
      font-weight: 700;
      margin-left: var(--dl-space-space-oneandhalfunits);
    }
    .header-navlink6 {
      font-size: 16px;
      font-style: normal;
      font-family: Montserrat;
      font-weight: 700;
      margin-left: var(--dl-space-space-oneandhalfunits);
    }
    .header-icon-group {
      width: auto;
    }
    .header-navlink7 {
      color: var(--dl-color-gray-white);
      cursor: pointer;
      font-size: 14px;
      font-style: normal;
      font-family: Montserrat;
      font-weight: 700;
      padding-top: var(--dl-space-space-twounits);
      padding-left: 6px;
      border-radius: 0px;
      padding-right: 6px;
      padding-bottom: var(--dl-space-space-twounits);
      background-image: linear-gradient(90deg, rgb(31, 149, 141) 0%, rgb(88, 181, 107) 99%);
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    .header-text17 {
      font-size: 14px;
    }
    .header-button {
      color: var(--dl-color-gray-white);
      font-size: 22px;
      font-style: normal;
      font-family: Montserrat;
      font-weight: 700;
      padding-left: var(--dl-space-space-unit);
      border-radius: 10PX;
      padding-right: var(--dl-space-space-unit);
      background-image: linear-gradient(90deg, rgb(31, 149, 141) 0%, rgb(88, 181, 107) 99%);
    }
  }
  @media(max-width: 991px) {
    .header-container01 {
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .header-header {
      height: var(--dl-size-size-small);
      align-self: stretch;
      align-items: center;
      padding-top: var(--dl-space-space-unit);
      padding-right: 0px;
      flex-direction: row;
      padding-bottom: var(--dl-space-space-unit);
      justify-content: center;
    }
    .header-container02 {
      width: 33.3%;
      height: auto;
      align-items: center;
    }
    .header-text {
      font-size: 14px;
    }
    .header-container03 {
      width: 33.3%;
      height: auto;
      margin-top: 0px;
      align-items: center;
      margin-left: 0px;
      margin-bottom: 0px;
      justify-content: center;
    }
    .header-icon02 {
      height: auto;
    }
    .header-text01 {
      font-size: 14px;
    }
    .header-container04 {
      width: 20%;
      height: auto;
      display: none;
      margin-top: 0px;
      margin-left: 0px;
      margin-bottom: 0px;
      justify-content: center;
    }
    .header-container05 {
      width: 100%;
      height: auto;
    }
    .header-image {
      width: 20px;
    }
    .header-image01 {
      width: 20px;
    }
    .header-image02 {
      width: 20px;
    }
    .header-image03 {
      width: 20px;
    }
    .header-container06 {
      width: 20%;
      height: auto;
      display: none;
      margin-left: 0px;
      justify-content: center;
    }
    .header-image04 {
      width: 50PX;
    }
    .header-image05 {
      width: 50PX;
    }
    .header-image06 {
      width: 50PX;
    }
    // .header-container07 {
    //   width: 10%;
    //   height: 100%;
    //   padding:15px 40px;
    //   margin-left: 0px;
    //   justify-content: center;
    //   flex-direction: row;
    // }
    .header-image07 {
      width: 20px;
    }
    .header-text02 {
      font-size: 10px;
    }
    .header-header1 {
      width: 100%;
      height: 80px;
      box-shadow: 5px 5px 10px 0px #d4d4d4;
      padding-top: var(--dl-space-space-unit);
      border-radius: 0px;
      padding-right: var(--dl-space-space-unit);
      padding-bottom: var(--dl-space-space-unit);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .header-image08 {
      width: 150PX;
    }
    .header-container08 {
      display: none;
    }
    .header-nav1 {
      display: none;
    }
    .header-container10 {
      width: 100%;
      flex-direction: column;
    }
    .header-ul {
      width: 100%;
    }
    .header-ul1 {
      width: 100%;
      margin-top: 0px;
    }
    .header-ul2 {
      width: 100%;
    }
    .header-icon-group {
      display: none;
    }
    .header-burger-menu {
      display: flex;
    }
    .header-icon06 {
      width: 45px;
      height: 45px;
      display: flex;
    }
    .header-mobile-menu {
      height: auto;
      z-index: 300;
    }
    .header-image09 {
      width: auto;
      height: 3rem;
    }
    .header-nav3 {
      width: 100%;
      align-self: center;
      align-items: center;
      flex-direction: column;
    }
    .header-text18 {
      font-size: 20px;
      align-self: center;
      font-style: normal;
      margin-top: 0px;
      font-family: Montserrat;
      font-weight: 700;
      margin-left: 0px;
      margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
    .header-text19 {
      font-size: 20px;
      align-self: center;
      font-style: normal;
      margin-top: var(--dl-space-space-oneandhalfunits);
      text-align: left;
      font-family: Montserrat;
      font-weight: 700;
      margin-left: 0px;
      margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
    .header-text20 {
      font-size: 20px;
      align-self: center;
      font-style: normal;
      margin-top: var(--dl-space-space-oneandhalfunits);
      text-align: left;
      font-family: Montserrat;
      font-weight: 700;
      margin-left: 0px;
      margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
    .header-text21 {
      font-size: 20px;
      align-self: center;
      font-style: normal;
      margin-top: var(--dl-space-space-oneandhalfunits);
      text-align: left;
      font-family: Montserrat;
      font-weight: 700;
      margin-left: 0px;
      margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
    .header-text22 {
      font-size: 20px;
      align-self: center;
      font-style: normal;
      margin-top: var(--dl-space-space-oneandhalfunits);
      text-align: left;
      font-family: Montserrat;
      font-weight: 700;
      margin-left: 0px;
      margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
    .header-text23 {
      font-size: 20px;
      align-self: center;
      font-style: normal;
      margin-top: var(--dl-space-space-oneandhalfunits);
      text-align: left;
      font-family: Montserrat;
      font-weight: 700;
      margin-left: 0px;
      margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
    .header-button {
      font-size: 18px;
      align-self: center;
      margin-top: var(--dl-space-space-oneandhalfunits);
    }
  }
  @media(max-width: 767px) {
    .header-header {
      padding-left: var(--dl-space-space-oneunits);
      padding-right: var(--dl-space-space-oneunits);
    }
    .header-container02 {
      width: 33.3%;
      height: auto;
    }
    .header-icon {
      width: 20px;
      height: auto;
    }
    .header-text {
      font-size: 12px;
    }
    .header-container03 {
      width: 33.3%;
      height: auto;
    }
    .header-icon02 {
      width: 20px;
    }
    .header-text01 {
      width: auto;
      font-size: 12px;
    }
    .header-container04 {
      display: none;
      margin-top: 0px;
    }
    .header-container05 {
      width: 100%;
      display: none;
      margin-top: 0px;
      justify-content: center;
    }
    .header-container06 {
      display: none;
    }
    // .header-container07 {
    //   width: 20%;
    //   margin-left: var(--dl-space-space-twounits);
    //   flex-direction: row;
    // }
    .header-text02 {
      margin-left: var(--dl-space-space-halfunit);
    }
    .header-header1 {
      height: 60px;
      padding-top: var(--dl-space-space-unit);
      padding-left: var(--dl-space-space-twounits);
      border-radius: 0px;
      padding-right: var(--dl-space-space-twounits);
      padding-bottom: var(--dl-space-space-unit);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .header-image08 {
      width: var(--dl-size-size-large);
    }
    .header-nav {
      display: none;
    }
    .header-nav1 {
      align-items: flex-start;
      flex-direction: column;
    }
    .header-navlink1 {
      margin-bottom: var(--dl-space-space-unit);
    }
    .header-navlink2 {
      margin-left: 0;
      margin-bottom: var(--dl-space-space-unit);
    }
    .header-container10 {
      width: 100%;
      height: 100%;
    }
    .header-ul {
      width: 100%;
    }
    .header-ul1 {
      width: 100%;
    }
    .header-ul2 {
      width: 100%;
    }
    .header-text12 {
      margin-top: var(--dl-space-space-oneandhalfunits);
      margin-bottom: 0px;
    }
    .header-separator1 {
      margin-top: var(--dl-space-space-oneandhalfunits);
    }
    .header-navlink4 {
      margin-left: 0;
      margin-bottom: var(--dl-space-space-unit);
    }
    .header-navlink5 {
      margin-left: 0;
      margin-bottom: var(--dl-space-space-unit);
    }
    .header-navlink6 {
      margin-left: 0;
      margin-bottom: var(--dl-space-space-unit);
    }
    .header-burger-menu {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .header-icon06 {
      width: 32px;
      height: 32px;
    }
    .header-mobile-menu {
      height: auto;
      display: none;
      z-index: 300;
    }
    .header-image09 {
      height: 2.5rem;
    }
    .header-nav3 {
      width: 100%;
      align-self: center;
      align-items: flex-start;
      flex-direction: column;
    }
    .header-text18 {
      font-size: 18px;
      align-self: center;
      margin-top: 0px;
      margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
    .header-text19 {
      font-size: 18px;
      align-self: center;
      margin-top: 20px;
      margin-left: 0;
      margin-bottom: 20px;
    }
    .header-text20 {
      font-size: 18px;
      align-self: center;
      margin-top: 20px;
      margin-left: 0;
      margin-bottom: 20px;
    }
    .header-text21 {
      font-size: 18px;
      align-self: center;
      margin-top: 20px;
      margin-left: 0;
      margin-bottom: 20px;
    }
    .header-text22 {
      font-size: 18px;
      align-self: center;
      margin-top: 20px;
      margin-left: 0;
      margin-bottom: 20px;
    }
    .header-text23 {
      font-size: 18px;
      align-self: center;
      margin-top: 20px;
      margin-left: 0;
      margin-bottom: 20px;
    }
    .header-button {
      align-self: center;
    }
  }
  @media(max-width: 479px) {
    .header-header {
      justify-content: space-between;
      flex-direction:row;
      padding: 0px 10px 0px 10px;
    }
    .header-container02 {
      width: 33.3%;
      margin-bottom: 5px;
    }
    .header-icon {
      width: 15px;
      height: 15px;
    }
    .header-text {
      font-size: 8px;
    }
    .header-container03 {
      width: 33.3%;
      margin-top: 0px;
      margin-left: 0px;
      margin-bottom: 5px;
    }
    .header-icon02 {
      width: 15px;
      height: 15px;
    }
    .header-text01 {
      width: auto;
      font-size: 8px;
      text-align: center;
      margin-left: 0px;
    }
    .header-container04 {
      width: 100%;
      margin-top: var(--dl-space-space-halfunit);
      align-items: center;
      margin-bottom: var(--dl-space-space-halfunit);
    }
    .header-container05 {
      align-self: center;
      margin-top: 0px;
      align-items: center;
    }
    .header-container06 {
      width: 100%;
      margin-top: 5px;
    }
    // .header-container07 {
    //   width: 20%;
    //   height: auto;
    //   padding: 2px 5px;
    //   margin-left:0;
      
     
    //   flex-direction: row;
    //   border-radius: 5px;
    // }
    .header-text02 {
     
      font-size: 8px;
      margin-left: 5px;
    }
    .headrer-image07{
      width: 5px;
     
  
  
  
    }
    .header-header1 {
      height: 70px;
      padding: 0px 10px 0px 10px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .header-image08 {
      width: var(--dl-size-size-medium);
    }
    .header-container10 {
      width: 100%;
    }
    .header-icon-group {
      display: none;
    }
    .header-icon06 {
      width: 32px;
      height: 32px;
    }
    .header-mobile-menu {
      height: auto;
      display: none;
      padding: 16px;
      z-index: 300;
    }
    .header-image09 {
      height: 2rem;
    }
    .header-nav3 {
      width: 100%;
      align-self: center;
    }
    .header-text18 {
      font-size: 16px;
      align-self: center;
      margin-bottom: var(--dl-space-space-halfunit);
    }
    .header-text19 {
      font-size: 16px;
      align-self: center;
      margin-top: var(--dl-space-space-halfunit);
      margin-bottom: var(--dl-space-space-halfunit);
    }
    .header-text20 {
      font-size: 16px;
      align-self: center;
      margin-top: var(--dl-space-space-halfunit);
      margin-bottom: var(--dl-space-space-halfunit);
    }
    .header-text21 {
      font-size: 16px;
      align-self: center;
      margin-top: var(--dl-space-space-halfunit);
      margin-bottom: var(--dl-space-space-halfunit);
    }
    .header-text22 {
      font-size: 16px;
      align-self: center;
      margin-top: var(--dl-space-space-halfunit);
      margin-bottom: var(--dl-space-space-halfunit);
    }
    .header-text23 {
      font-size: 16px;
      align-self: center;
      margin-top: var(--dl-space-space-halfunit);
      margin-bottom: var(--dl-space-space-halfunit);
    }
    .header-button {
      align-self: center;
      margin-top: var(--dl-space-space-halfunit);
    }
  }
  
  [data-thq="thq-dropdown"]:hover > [data-thq="thq-dropdown-list"] {
    display: flex;
  }

  [data-thq="thq-dropdown"]:hover > div [data-thq="thq-dropdown-arrow"] {
    transform: rotate(90deg);
  }
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: montserrat;
  }
  
  
  
  
  
  
  nav{
    
  
    z-index: 99;
    width: 100%;
    
    
  }
  
  nav .wrapper{
    position: relative;
    width: 100%;
    padding-left: 30px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
  
   
  
   
  }
  .wrapper .logo img{
    
  height: 50px;
  width: auto;
  
  
  }
  
  .vertical-bar{ 
    height: 100%;
    border-right: 1px solid black;
  }
  .wrapper .nav-links{
    display: inline-flex;
    align-items: center;
  }
  .nav-links li{
    list-style: none;
  }
  .nav-links li a{
    color: #000000;
    text-decoration: none;
    font-size: 16px;
    font-weight: 700;
    padding: 40px 15px;
    transition: all 0.3s ease;
  }
  .nav-links li a.active {
    color: #000000;
  }
  
  
  
  
  
  .nav-links li a:hover{
    background: #000000;
    color: #fff;
  }
  .nav-links .mobile-item{
    display: none;
  }
  .nav-links .drop-menu{
    position: absolute;
    background: #000000;
    width: 180px;
    line-height: 45px;
    top: 85px;
    opacity: 0;
    visibility: hidden;
    box-shadow: 0 6px 10px rgba(0,0,0,0.20);
  }
  .nav-links li:hover .drop-menu,
  .nav-links li:hover .mega-box{
    transition: all 0.3s ease;
    top: 70px;
    opacity: 1;
    visibility: visible;
  }
  .drop-menu li a{
    width: 100%;
    display: block;
    padding: 0 0 0 15px;
    font-weight: 400;
    border-radius: 0px;
  }
  .mega-box{
    position: absolute;
    /* left: 35%; */
    /* width: 100%; */
    padding: 30px 0px;
    top: 85px;
    opacity: 0;
    visibility: hidden;
  }
  .mega-boxnew{
    position: absolute;
    left: 35%;
    width: 100%;
    padding: 30px 0px;
    top: 85px;
    opacity: 0;
    visibility: hidden;
  }
  .mega-box .content{
    background: linear-gradient(90deg, rgb(88, 181, 107) 1.00%,rgb(31, 149, 141) 100.00%);
    padding: 25px 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    box-shadow: 0 6px 10px rgba(0,0,0,0.15);
    border-radius: 0px 0px 10px 10px;
  }
  .mega-boxnew .content{
    background: linear-gradient(90deg, rgb(88, 181, 107) 1.00%,rgb(31, 149, 141) 100.00%);
    padding: 25px 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    box-shadow: 0 6px 10px rgba(0,0,0,0.15);
    border-radius: 0px 0px 10px 10px;
  }
  .mega-box .content .row{
    line-height: 25px;
  }
  .mega-boxnew .content .row{
    width: calc(25% - 30px);
    line-height: 19px;
  }
  
  
  .content .row header{
    color: #f2f2f2;
    font-size: 20px;
    font-weight: 500;
  }
  .content .row .mega-links{
    margin-left: -19px;
    border-left: 1px solid rgba(255,255,255,0.09);
  }
  .content .row .mega-link{
    margin-left: -19px;
    border-left: 1px solid rgba(255,255,255,0.09);
  }
  .row .mega-links li{
    padding: 0 20px;
  }
  .row .mega-link li{
    padding: 0 20px;
  }
  .row .mega-links li a{
    padding: 0px;
    padding: 0 10px;
    color: #ffffff;
    font-size: 17px;
    display: block;
  }
  .row .mega-link li a{
    padding: 0px;
    padding: 0 20px;
    color: #ffffff;
    font-size: 17px;
    display: block;
  }
 
  .row .mega-links li a:hover{
    color: #f2f2f2;
  }
  .row .mega-link li a:hover{
    color: #f2f2f2;
  }
  .wrapper .btn{
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    display: none;
  }
  .wrapper .btn.close-btn{
    position: absolute;
    right: 30px;
    top: 10px;
  }
  
  @media screen and (max-width: 1200px) {
    .wrapper .logo img{
      width: 140px;
      height: auto;
    }
    .vertical-bar{ 
      height: 80%;
      border-right: 1px solid black;
      display: none;
    }
  
    .wrapper .btn{
      display: block;
    }
    nav .wrapper {
      
      width: 90%;
      box-shadow: none;
    }
    .wrapper .nav-links{
      position: fixed;
      height: 100vh;
      width: 100%;
      max-width: 1000px;
      top: 0;
      left: -100%;
      background: linear-gradient(90deg, rgb(88, 181, 107) 1.00%,rgb(31, 149, 141) 100.00%);
      display: block;
      padding: 50px 10px;
      line-height: 50px;
      overflow-y: auto;
      box-shadow: 0px 15px 15px rgba(0,0,0,0.18);
      transition: all 0.3s ease;
    }
    /* custom scroll bar */
    ::-webkit-scrollbar {
      width: 10px;
    }
    ::-webkit-scrollbar-track {
      background: #ffffff;
    }
    ::-webkit-scrollbar-thumb {
      background: #3A3B3C;
    }
    #menu-btn:checked ~ .nav-links{
      left: 0%;
    }
    #menu-btn:checked ~ .btn.menu-btn{
      display: none;
    }
    #close-btn:checked ~ .btn.menu-btn{
      display: block;
      
    }
    .nav-links li{
      margin: 15px 10px;
    }
    .nav-links li a{
      padding: 0 20px;
      display: block;
      font-size: 18px;
      color: #fff;
    }
    .nav-links .drop-menu{
      position: static;
      opacity: 1;
      top: 65px;
      visibility: visible;
      padding-left: 20px;
      width: 100%;
      max-height: 0px;
      overflow: hidden;
      box-shadow: none;
      transition: all 0.3s ease;
    }
    #showDrop:checked ~ .drop-menu,
    #showMega:checked ~ .mega-box{
      max-height: 100%;
    }
    #showMegas:checked ~ .mega-box{
      max-height: 100%;
    }
    
    .nav-links .desktop-item{
      display: none;
    }
    .nav-links .mobile-item{
      display: block;
      color: #f2f2f2;
      font-size: 18px;
      font-weight: 700;
      padding-left: 20px;
      cursor: pointer;
      border-radius: 5px;
      transition: all 0.3s ease;
    }
    .nav-links .mobile-item:hover{
      background: #000000;
    }
    .drop-menu li{
      margin: 0;
    }
    .drop-menu li a{
      border-radius: 5px;
      font-size: 18px;
    }
    .mega-box{
      position: static;
      top: 65px;
      opacity: 1;
      visibility: visible;
      padding: 0 20px;
      max-height: 0px;
      overflow: hidden;
      transition: all 0.3s ease;
    }
    .mega-boxnew{
      position: static;
      top: 65px;
      opacity: 1;
      visibility: visible;
      padding: 0 20px;
      max-height: 0px;
      overflow: hidden;
      transition: all 0.3s ease;
    }
    .mega-box .content{
      box-shadow: none;
      flex-direction: column;
      padding: 20px 20px 0 20px;
    }
    .mega-boxnew .content{
      box-shadow: none;
      flex-direction: column;
      padding: 20px 20px 0 20px;
    }
    .mega-box .content .row{
      width: 100%;
      margin-bottom: 15px;
      border-top: 1px solid rgba(255,255,255,0.08);
    }
     .mega-boxnew .content .row{
      width: 100%;
      margin-bottom: 15px;
      border-top: 1px solid rgba(255,255,255,0.08);
    }
    .mega-box .content .row:nth-child(1),
    .mega-box .content .row:nth-child(2){
      border-top: 0px;
    }
    .mega-boxnew .content .row:nth-child(1),
    .mega-boxnew .content .row:nth-child(2){
      border-top: 0px;
    }
    .content .row .mega-links{
      border-left: 0px;
      padding-left: 15px;
    }
    .content .row .mega-link{
      border-left: 0px;
      padding-left: 15px;
    }
    .row .mega-links li{
      margin: 0;
    }
    .row .mega-link li{
      margin: 0;
    }
    .content .row header{
      font-size: 19px;
    }
  
    button {
      display: none;
      padding: 20px 35px;
     border: none;
     border-radius: 10px;
     
     }
     button:hover {
       background: linear-gradient(90deg, rgb(88, 181, 107) 1.00%,rgb(31, 149, 141) 100.00%);;
       color: #fff;
       cursor: pointer;
     }
  }
  nav input{
    display: none;
  }
  button {
  padding: 40px 35px;
  border: none;
  border-radius: 0px 10px 10px  0px;
  background: linear-gradient(90deg, rgb(88, 181, 107) 1.00%,rgb(31, 149, 141) 100.00%);;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  }
  button:hover {
    background: #000000;
    color: #fff;
    cursor: pointer;
    
  }
  @media screen and (max-width: 479px) {
  
  .wrapper .logo img{
    width:100px ;
  }
  nav .wrapper {
    padding-left: 0;
  }
  
  
  }
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');


  .button1{
    position: relative;
    display: flex;
    width: 100%;
    background-color:yellow;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    justify-content: space-between;
    font-size: 16px;
    font-family: montserrat;
    align-items: center;
    box-shadow: 3px 3px 7px;
  }
  p{
    font-size: 16px;
    font-weight: 700;
  }
  
  .fa-shopping-cart{
    font-size:25px;
    color: black;
  }
  
  span{
    background-color: red;
    padding: 10px;
    border-radius: 50%;
    color: white;
    font-size: 12px;
    font-weight: 700;
    position: absolute;
    top:-10px ;
    height: 36px;
    width: 36px;
    line-height: 17px;
    text-align: center;
    display: flex;
    
    
    
  
  }
  @media(max-width:991px){
  
     .button1        {
    position: relative;
    display: flex;
    width: 100%;
    background-color:yellow;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    justify-content: space-between;
    font-size: 16px;
    font-family: montserrat;
    align-items: center;
    box-shadow: 3px 3px 7px;
  }
    
    
  }
  @media(max-width:767px){
  
  
  .button1{
    position: relative;
    display: flex;
    width: 70px;
    background-color:yellow;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    justify-content: space-between;
    font-size: 16px;
    font-family: montserrat;
    align-items: center;
    box-shadow: 3px 3px 7px;
  }
  p{
    font-size: 8px;
    font-weight: 700;
  }
  
  .fa-shopping-cart{
    font-size:15px;
    color: black;
  }
  
  span{
    background-color: red;
    padding: 5px;
    border-radius: 50%;
    color: white;
    font-size: 8px;
    font-weight: 500;
    position: absolute;
    top:0 ;
    height: 10px;
    width: 10px;
    line-height: 10px;
    text-align: center;
    
  
  }
    
  }
  
  @media(max-width:420px){
  
  
  .button1{
    position: relative;
    display: flex;
    width: 70%;
    background-color:yellow;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    justify-content: space-between;
    font-size: 16px;
    font-family: montserrat;
    align-items: center;
    box-shadow: 3px 3px 7px;
  }
  p{
    font-size: 10px;
    font-weight: 700;
    display: none;
  }
  
  .fa-shopping-cart{
    font-size:15px;
    color: black;
  }
  
  span{
    background-color: red;
    padding: 3px;
    border-radius: 50%;
    color: white;
    font-size: 8px;
    font-weight: 500;
    position: absolute;
    top:0 ;
    height: 15px;
    width: 15px;
    line-height: 15px;
    text-align: center;
    
  
  }
    
  }
  
  
  
  /*whishlist css end*/
  
  .header-container {
    width: 100%;
    display: flex;
    // overflow: auto;
    // min-height: 100vh;
    align-items: center;
    flex-direction: column;
  }
  
  
  
  .header-container01 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: var(--dl-space-space-oneunits);
    justify-content: center;
    background-image: linear-gradient(90deg, rgb(88, 181, 107) 1.00%,rgb(31, 149, 141) 100.00%);
  }
  .header-header {
    width: 100%;
    height: 75px;
    display: flex;
    max-width: 1600px;
    align-items: center;
    padding-top: var(--dl-space-space-oneunits);
    padding-left: 0px;
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-oneunits);
    justify-content: space-between;
   
  }
  .header-container02 {
    flex: 0 0 auto;
    width: 20%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header-icon {
    fill: #ffff;
    width: 40px;
    height: 40px;
  }
  .header-link {
    display: contents;
  }
  .header-text {
    color: rgb(255, 255, 255);
    font-size: 1.2rem;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 500;
    text-decoration: none;
  }
  .header-container03 {
    flex: 0 0 auto;
    width: 20%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header-icon02 {
    fill: #fff;
    width: 40px;
    height: 40px;
  }
  .header-link1 {
    display: contents;
  }
  .header-text01 {
    color: #f9f9f9;
    font-size: 1.2rem;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 500;
    margin-left: var(--dl-space-space-unit);
    text-decoration: none;
  }
  .header-container04 {
    flex: 0 0 auto;
    width: 20%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header-container05 {
    display: flex;
    position: relative;
    margin-top: 0px;
    align-items: center;
    justify-content: center;
  }
  .header-image {
    width: 30px;
    cursor: pointer;
    object-fit: cover;
    transition: 0.3s;
    margin-right: var(--dl-space-space-halfunit);
  }
  .header-image:hover {
    transform: scale(1.2);
  }
  .header-image01 {
    width: 30px;
    cursor: pointer;
    object-fit: cover;
    transition: 0.3s;
    margin-right: var(--dl-space-space-halfunit);
  }
  .header-image01:hover {
    transform: scale(1.2);
  }
  .header-image02 {
    width: 30px;
    cursor: pointer;
    object-fit: cover;
    transition: 0.3s;
    margin-right: var(--dl-space-space-halfunit);
  }
  .header-image02:hover {
    transform: scale(1.2);
  }
  .header-link2 {
    display: contents;
  }
  .header-image03 {
    width: 30px;
    cursor: pointer;
    object-fit: cover;
    transition: 0.3s;
    text-decoration: none;
  }
  .header-image03:hover {
    transform: scale(1.2);
  }
  .header-container06 {
    flex: 0 0 auto;
    width: 20%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header-image04 {
    width: 60px;
    object-fit: cover;
  }
  .header-image05 {
    width: 60px;
    object-fit: cover;
  }
  .header-image06 {
    width: 60px;
    object-fit: cover;
  }
  .header-container07 {
    flex: 0 0 auto;
    width: 10%;
    height: auto;
    display: flex;
    
    transition: 0.3s;
    align-items: center;
   
   
    
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
  }
  .header-container07:hover {
    background-color: #000000;
  }
  .header-image07 {
    width: 25px;
    object-fit: cover;
  }
  .header-text02 {
    color: #ffffff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  
  
    
  
  @media(max-width: 1200px) {
  
    .button1{
      position: relative;
      display: flex;
      width: 120px;
      background-color:yellow;
      padding: 5px 10px;
      border-radius: 5px;
      cursor: pointer;
      justify-content: space-between;
      font-size: 16px;
      font-family: montserrat;
      align-items: center;
      box-shadow: 3px 3px 7px;
    }
    p{
      font-size: 12px;
      font-weight: 700;
    }
    
    .fa-shopping-cart{
      font-size:15px;
      color: black;
    }
    
    span{
      background-color: red;
      padding: 5px;
      border-radius: 50%;
      color: white;
      font-size: 8px;
      font-weight: 500;
      position: absolute;
      top:0 ;
      height: 25px;
      width: 25px;
      line-height: 15px;
      text-align: center;
      
    
    }
  
    .header-header {
      max-width: 1000px;
      justify-content: space-between;
    }
    .header-container02 {
      width: 20%;
      height: auto;
      justify-content: center;
    }
    .header-icon {
      width: 20px;
      height: 20px;
    }
    .header-text {
      color: rgb(255, 255, 255);
      font-size: 14px;
      font-family: Montserrat;
    }
    .header-container03 {
      width: 20%;
      height: auto;
      margin-left: var(--dl-space-space-twounits);
      justify-content: center;
    }
    .header-icon02 {
      width: 20px;
      height: 20px;
    }
    .header-text01 {
      font-size: 1rem;
      text-align: left;
      margin-left: var(--dl-space-space-halfunit);
    }
    .header-container04 {
      width: 20%;
      height: auto;
      margin-left: 0px;
      justify-content: center;
    }
    .header-container06 {
      width: 20%;
      height: auto;
      margin-left: 10px;
     
    }
  
    .header-text01 {
      font-size: 14px;
    }
   
  }
  @media(max-width: 991px) {
  
  
    
    .header-container01 {
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .header-header {
      height: var(--dl-size-size-small);
      align-items: center;
      padding-top: var(--dl-space-space-unit);
      padding-right: 40px;
      flex-direction: row;
      padding-bottom: var(--dl-space-space-unit);
      justify-content: space-between;
    }
    .header-container02 {
      width: 33.3%;
      height: auto;
      align-items: center;
    }
    .header-text {
      font-size: 14px;
    }
    .header-container03 {
      width: 33.3%;
      height: auto;
      margin-top: 0px;
      align-items: center;
      margin-left: 0px;
      margin-bottom: 0px;
      justify-content: center;
    }
    .header-icon02 {
      height: auto;
    }
    .header-text01 {
      font-size: 14px;
    }
    .header-container04 {
      width: 20%;
      height: auto;
      display: none;
      margin-top: 0px;
      margin-left: 0px;
      margin-bottom: 0px;
      justify-content: center;
    }
    .header-container05 {
      width: 100%;
      height: auto;
    }
    .header-image {
      width: 20px;
    }
    .header-image01 {
      width: 20px;
    }
    .header-image02 {
      width: 20px;
    }
    .header-image03 {
      width: 20px;
    }
    .header-container06 {
      width: 20%;
      height: auto;
      display: none;
      margin-left: 0px;
      justify-content: center;
    }
    .header-image04 {
      width: 50PX;
    }
    .header-image05 {
      width: 50PX;
    }
    .header-image06 {
      width: 50PX;
    }
    .header-container07 {
      width: 10%;
      height: 100%;
      
      margin-left: 0px;
      justify-content: center;
      flex-direction: row;
    }
    .header-image07 {
      width: 20px;
    }
    .header-text02 {
      font-size: 10px;
    }
   
  }
  @media(max-width: 767px) {
    .header-header {
      
     padding-right: 25px;
    }
    .header-container02 {
      width: 33.3%;
      height: auto;
    }
    .header-icon {
      width: 20px;
      height: auto;
    }
    .header-text {
      font-size: 12px;
    }
    .header-container03 {
      width: 33.3%;
      height: auto;
    }
    .header-icon02 {
      width: 20px;
    }
    .header-text01 {
      width: auto;
      font-size: 12px;
    }
    .header-container04 {
      display: none;
      margin-top: 0px;
    }
    .header-container05 {
      width: 100%;
      display: none;
      margin-top: 0px;
      justify-content: center;
    }
    .header-container06 {
      display: none;
    }
    .header-container07 {
      width: 20%;
      margin-left: var(--dl-space-space-tunits);
      flex-direction: row;
    }
    .header-text02 {
      margin-left: var(--dl-space-space-halfunit);
    }
  }
  @media(max-width: 479px) {
  
    .header-header {
      justify-content: space-between;
      height: 65px;
      padding-right: 15px; 
    }
    .header-container02 {
      width: 40%;
      display: block;
      text-align: center;
    }
    .header-icon {
      width: 15px;
      height: 15px;
    }
    .header-text {
      font-size: 10px;
    }
    .header-container03 {
      width: 40%;
      margin-top: 0px;
     
      display: block;
      text-align: center;
     
    }
    .header-icon02 {
      width: 15px;
      height: 15px;
    }
    .header-text01 {
      width: auto;
      font-size: 10px;
      text-align: center;
      
    }
    .header-container04 {
      width: 100%;
      margin-top: var(--dl-space-space-halfunit);
      align-items: center;
      margin-bottom: var(--dl-space-space-halfunit);
    }
    .header-container05 {
      align-self: center;
      margin-top: 0px;
      align-items: center;
    }
    .header-container06 {
      width: 100%;
      margin-top: 5px;
    }
    .header-container07 {
      width: 15%;
      height: auto;
      padding: 2px 5px;
      margin-right:10px;
      
     
      flex-direction: row;
      border-radius: 5px;
    }
    .header-text02 {
     
      font-size: 8px;
      margin-left: 5px;
    }
    .headrer-image07{
      width: 5px;
  
    }
  }
  

`;