import React, { useState, useEffect,Redirect  } from 'react';
import { BrowserRouter as Router, Route, Routes, useParams,useLocation  } from 'react-router-dom';
import './App.css';
import Header from './Components/layout/Header';
import Careers from './Components/pages/careers';
import Footer from './Components/layout/Footer';
import Gallery from './Components/pages/gallary.jsx';
import About from './Components/pages/About';
import Index from './Components/pages/index';
import Contact from './Components/pages/contact';
import CareerDetails from './Components/pages/careerdetails';
import axios from 'axios';
import Product from './Components/pages/product';
import Productdetails from './Components/pages/productdetails';
import { v4 as uuidv4 } from 'uuid';
import Wishlist from './Components/pages/wishlist';
import Nopage from './Components/pages/Nopage';
import ViewWishlist from './Components/pages/viewWishlist';
import Emailformat from './Components/pages/emailformat';

function ErrorBoundary({ children }) {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const errorHandler = () => {
      setHasError(true);
    };

    window.addEventListener('error', errorHandler);

    return () => {
      window.removeEventListener('error', errorHandler);
    };
  }, []);

  if (hasError) {
    return <Nopage />;
  }

  return children;
}
function App() {
  const isLocalhost = window.location.hostname === 'localhost';

let backendUrl = isLocalhost
  ? 'http://localhost:3003/Industry'
  : 'https://safecareind.com/Industry';

// if (!isLocalhost) {
//   backendUrl = 'http://192.168.16.156:3000/Industry';
// }
const u = ""; 
const [sessionID, setSessionID] = useState('');
const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    const getSessionID = () => {
      const storedSessionID = localStorage.getItem('sessionID');
      if (storedSessionID) {
        setSessionID(storedSessionID);
        
      } else {
        const newSessionID = uuidv4();
        localStorage.setItem('sessionID', newSessionID);
        setSessionID(newSessionID);
       
      }
    };

    getSessionID();
  }, []);
  const wishlistHandler = (id) => {
    console.log(id)

  };
  const [count, setCount] = useState(0);

  useEffect(() => {
    // Function to fetch the category list from the backend
    const fetchCategoryList = async () => {
      try {
        const response = await fetch(`${backendUrl}/getCategory`, {
         
        });

        if (response.ok) {
          const data = await response.json();
          setCategoryList(data.categories);
        } else {
          console.error('Failed to fetch category list');
        }
      } catch (error) {
        console.error('Error fetching category list:', error);
      }
    };

    fetchCategoryList();
  }, [backendUrl, sessionID]);

  return (
    <Router>
      <div>
      <Header
        sessionID={sessionID}
        backendUrl={backendUrl}
        count={count}
        setCount={setCount} 
        categoryList={categoryList}
      />
       <ErrorBoundary>
        <Routes>
       
          <Route path="/" element={<Index backendUrl={backendUrl} /> } />
          <Route path="/checkemail" element={<Emailformat backendUrl={backendUrl} />} />
          <Route path="about" element={<About />} />
      
          <Route
            path="wishlist/*"
            element={<Wishlist backendUrl={backendUrl}  sessionID={sessionID}/>}
          />
          <Route
            path="careers/*"
            element={<Careers backendUrl={backendUrl} />}
          />
           <Route
            path="product/*"
            element={<Product backendUrl={backendUrl} />}
          />
          <Route path="gallery" element={<Gallery />} />
          <Route
            path="contact/*"
            element={<Contact backendUrl={backendUrl} />}
          />
          <Route
            path="career/:id"
            element={<CareerDetailsPage backendUrl={backendUrl} />}
          />
           <Route
            path="product/:id"
            element={<ProductdetailsPage backendUrl={backendUrl} sessionID={sessionID} />}
          />
          <Route path="/viewlist" element={<WishlistData backendUrl={backendUrl} />} />
          
           <Route path="*" element={<Nopage />} />
           
           
        </Routes>
        </ErrorBoundary>
        <Footer />
      </div>
    </Router>
  );
}




function CareerDetailsPage({ backendUrl }) {
  const { id } = useParams();
  const [careerDetails, setCareerDetails] = useState(null);

  useEffect(() => {
    const fetchCareerDetails = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/getCareerAginstId/${id}`
        );
        const { careers, actcareers, categories } = response.data;
        setCareerDetails({ careers, actcareers, categories });
      } catch (error) {
        console.error(error);
        // Handle error case
      }
    };

    fetchCareerDetails();
  }, [backendUrl, id]);
  return <CareerDetails careerDetails={careerDetails} backendUrl={backendUrl} />;
}
function ProductdetailsPage({ backendUrl,sessionID }) {
  console.log(sessionID)
  const { id } = useParams();
  const [productDetails, setProductDetails] = useState(null);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/getProductdetailsById/${id}`
        );
        const { productFeatures, productReference, categories, product,productPicturess,productColor } = response.data;
        setProductDetails({ productFeatures, productReference, categories, product,productPicturess,productColor });
      } catch (error) {
        console.error(error);
        // Handle error case
      }
    };

    fetchProductDetails();
  }, [backendUrl, id]);
  return <Productdetails productDetails={productDetails} backendUrl={backendUrl} sessionID ={sessionID} />;
}
function WishlistData({ backendUrl }) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const ref = params.get('ref');
  const id = params.get('u');
 
  const [customer, setCustomer] = useState(null);
  const [customerDetails, setcustomerDetails] = useState(null);
  useEffect(() => {
    const fetchWishlistDetails = async () => {
      try {

        const response = await axios.get(`${backendUrl}/getWishlistDetails/${id}`);
        const { custlist,custdetails } = response.data;
        setCustomer({ custlist });
        setcustomerDetails({ custdetails });
      } catch (error) {
        console.error(error);
      }
    };

    fetchWishlistDetails();
  }, [backendUrl, id]);

  return <ViewWishlist customerDetails={customerDetails} customer={customer} />;
}

export default App;

