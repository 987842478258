import React from 'react'
import '../pages/style.css';
import './footer.css';
function Footer() {
  return (
    <div>
  <body>
  <div class="footer-container">
        <footer class="footer-footer">
          <div class="footer-container01">
            <div class="footer-container02">
              <a href="/" class="footer-navlink">
                <img
                  alt="logo"
                  src="./playground_assets/safecare-med-white-logo-200h.png"
                  class="footer-image"
                />
              </a>
            </div>
          </div>
          <div class="footer-links-container">
            <div class="footer-container03">
              <div class="footer-container04">
                <span class="footer-text">Main Menu</span>
                <a href="/" class="footer-navlink1">Home</a>
                <a href="gallery" class="footer-navlink2">Gallery</a>
                <a href="product" class="footer-navlink3">Products</a>
              </div>
              <div class="footer-container05">
                <span class="footer-text01">Company</span>
                <a href="about" class="footer-navlink4">About</a>
                <a href="careers" class="footer-navlink5">Careers</a>
                <a href="contact" class="footer-navlink6">Contact</a>
              
              </div>
            </div>
            <div class="footer-container06">
              <div class="footer-container07">
                <span class="footer-text02">Support</span>
                <span class="footer-text03">Privacy Policy</span>
                <span class="footer-text04">Legal</span>
                <span class="footer-text05">Help center</span>
              </div>
              <div class="footer-container08">
                <span class="footer-text06">Resources</span>
                <a href="" class="footer-navlink7">SDS</a>
                <a href="" class="footer-navlink8">TDS</a>
                <span class="footer-text07">FAQ</span>
              </div>
            </div>
            <div class="footer-container09">
              <div class="footer-container10">
                <div class="footer-container11">
                  <div class="footer-container12">
                    <span class="footer-text08">Contact Us</span>
                    <div class="footer-container13">
                      <svg viewBox="0 0 1024 1024" class="footer-icon">
                        <path
                          d="M512 490q44 0 75-31t31-75-31-75-75-31-75 31-31 75 31 75 75 31zM512 86q124 0 211 87t87 211q0 62-31 142t-75 150-87 131-73 97l-32 34q-12-14-32-37t-72-92-91-134-71-147-32-144q0-124 87-211t211-87z"
                        ></path>
                      </svg>
                      <span class="footer-text09">
                        <span>Safecare Medical Industries</span>
                        <br />
                        <span>KHI-8-18 - Abu Dhabi</span>
                        <br />
                        <span>United Arab Emirates</span>
                      </span>
                    </div>
                    <div class="footer-container14">
                      <svg viewBox="0 0 1024 1024" class="footer-icon2">
                        <path
                          d="M282 460q96 186 282 282l94-94q20-20 44-10 72 24 152 24 18 0 30 12t12 30v150q0 18-12 30t-30 12q-300 0-513-213t-213-513q0-18 12-30t30-12h150q18 0 30 12t12 30q0 80 24 152 8 26-10 44z"
                        ></path>
                      </svg>
                      <a href="tel:+971-554 334 560" class="footer-link">
                        +971-02-5067333
                      </a>
                    </div>
                    <div class="footer-container15">
                      <svg viewBox="0 0 1024 1024" class="footer-icon4">
                        <path
                          d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"
                        ></path>
                      </svg>
                      <a
                        href="mailto:info@safecareind.com?subject="
                        class="footer-link1"
                      >
                        info@safecareind.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="footer-container16">
            <div class="footer-container17">
              <img
                alt="image"
                src="./playground_assets/fb-200h.png"
                class="footer-image1"
              />
              <img
                alt="image"
                src="./playground_assets/insta-200h.png"
                class="footer-image2"
              />
              <img
                alt="image"
                src="./playground_assets/twi-200h.png"
                class="footer-image3"
              />
              <a
                href="https://www.youtube.com/@safecaremedicalindustries7705"
                target="_blank"
                rel="noreferrer noopener"
                class="footer-link2"
              >
                <img
                  alt="image"
                  src="./playground_assets/yt-200h.png"
                  class="footer-image4"
                />
              </a>
            </div>
          </div>
        </footer>
        <div class="footer-container18">
          <h1 class="footer-text15">
            Copyright ©2023 Safecareind | All rights reserved
          </h1>
        </div>
      </div>

  </body>
    </div>
   
  )
}

export default Footer
