import React, { useState } from 'react';
import Swal from 'sweetalert2';
import './style.css';
import './careerpage.css';
import './POPUP.css';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import axios from 'axios';

function Careerdetails({ careerDetails,backendUrl }) {
  const [popupOpen, setPopupOpen] = useState(false);
  const [copyLinkValue, setCopyLinkValue] = useState('');
  const [productViewVisible, setProductViewVisible] = useState(false);
  const [jobTitle, setJobTitle] = useState('');


 if (!careerDetails) {
    return null; // Or you can render an error message or loading indicator
  }
 const careers=careerDetails.careers;
 const actcareers=careerDetails.actcareers;
 const categories=careerDetails.categories;
  // const { careers, actcareers, categories } = careerDetails.data;
  function openPopup() {
    setPopupOpen(true);
  }

  function closePopup() {
    setPopupOpen(false);
  }
  function copy() {
    const inputField = document.querySelector('.copy-link-input');
    inputField.select();
    document.execCommand('copy');
    inputField.value = 'Copied!';
  
    setTimeout(() => {
      inputField.value = ''; // Reset the input field value after a delay
    }, 2000);
  }
  

  const handleApplyButtonClick = () => {
    const rowId = document.getElementById('apply-button').getAttribute('row_id');
    setJobTitle(rowId);
    setProductViewVisible(true);
  };
  

  function handleCopyLink(inputValue) {
    navigator.clipboard.writeText(inputValue);
    setCopyLinkValue('Copied!');
    setTimeout(() => setCopyLinkValue(inputValue), 2000);
  }
  const shareOnSocialMedia = (iconId, dynamicContent) => {
    const handleSocialMediaShare = () => {
      const url = window.location.href; // get the current URL
      const message = `${dynamicContent} ${url}`; // combine the URL and dynamic content

      if (iconId === 'whatsapp-icon') {
        const encodedMessage = encodeURIComponent(message);
        window.open(`https://wa.me/?text=${encodedMessage}`, '_blank');
      } else if (iconId === 'facebook-icon') {
        const encodedUrl = encodeURIComponent(url);
        const encodedMessage = encodeURIComponent(message);
        window.open(`https://www.facebook.com/sharer.php?u=${encodedUrl}&quote=${encodedMessage}`, '_blank');
      } else if (iconId === 'instagram-icon') {
        const encodedMessage = encodeURIComponent(message);
        window.open(`https://www.instagram.com/?url=${encodedMessage}`, '_blank');
      } else if (iconId === 'twitter-icon') {
        const encodedMessage = encodeURIComponent(message);
        window.open(`https://twitter.com/share?url=${encodedMessage}`, '_blank');
      } else if (iconId === 'linkedin-icon') {
        const encodedTitle = encodeURIComponent(dynamicContent);
        const encodedUrl = encodeURIComponent(url);
        const encodedMessage = encodeURIComponent(message);
        window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=${encodedTitle}&summary=${encodedMessage}`, '_blank');
      }
    };
    return handleSocialMediaShare;
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    if (
      document.getElementById('first_name').value === "" ||
      document.getElementById('last_name').value === "" ||
      document.getElementById('email').value === "" ||
      document.getElementById('phone').value === ""
    ) {
      showAlert('Warning!', 'Please Enter Basic Details', 'warning');
    
    } else if (!validateEmail(document.getElementById('email').value)) {
      showAlert('Warning!', 'Please enter a valid email address', 'warning');
      return false;
    } else if (document.getElementById('phone').value.length < 8) {
      showAlert('Warning!', 'Phone No must be at least 8 characters long', 'warning');
      return false;
    } else {
      const data = {
        lastname: document.getElementById('last_name').value,
        firstname: document.getElementById('first_name').value,
        email: document.getElementById('email').value,
        phone: document.getElementById('phone').value,
        jobtitle: document.getElementById('job_title').value,
        description: document.getElementById('description').value,
        enquirytype: document.getElementById('enquirytype').value
      };
      fetch(`${backendUrl}/saveEnquiry`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => response.json())
        .then(data => {
          showAlert('Success!', data.message, 'success');
          ClearForm();
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return emailRegex.test(email);
  };

  const ClearForm = () => {
    document.getElementById("enquiryForm").reset();
  };
  const showAlert = (title, text, icon) => {
    Swal.fire({
      title: title,
      text: text,
      icon: icon,
      confirmButtonText: 'OK',
    });
  };
  return (
    <div>
       {/* <Header/> */}
      <head>
      <title>{careers.jobTitle}</title>
      <script src="https://code.jquery.com/jquery-3.6.0.min.js"></script>
      <link
   rel="stylesheet"
   href="https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/css/intlTelInput.css"
 />
 <script src="https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/intlTelInput.min.js"></script>
 <meta
        name="description"
        content="We are the quality manufacturer of medical disposables in UAE for the past 7 years.  We provide Respiratory, PPE and nursing consumables, Surgical Kits, etc."
        />
     <meta
        property="og:title"
        content="careerpage - Best and quality medical disposable manufacturer in UAE "
        />
     <meta
        property="og:description"
        content="We are the quality manufacturer of medical disposables in UAE for the past 7 years.  We provide Respiratory, PPE and nursing consumables, Surgical Kits, etc."
        />
     <meta name="viewport" content="width=device-width, initial-scale=1.0" />
     <meta charset="utf-8" />
     <meta property="twitter:card" content="summary_large_image" />
     <link
        rel="shortcut icon"
        href="playground_assets/32x32.png"
        type="icon/png"
        sizes="32x32"
        />
     <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap"
        data-tag="font"
        />
     <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap"
        data-tag="font"
        />
     <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap"
        data-tag="font"
        />
     <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&amp;display=swap"
        data-tag="font"
        />
             <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@5.15.4/css/all.min.css"/>
             <script
      data-section-id="header"
      src="https://unpkg.com/@teleporthq/teleport-custom-scripts"
    ></script>
      </head>
      <body>
      <div>
      <div class="careerpage-container">
      <div class="careerpage-container01">
      <div class="careerpage-container02">
     <div className="careerpage-container03">
          <h1 className="careerpage-text">{careers.jobTitle}</h1>
        </div> 
          <div className="careerpage-container04">
            <h1 className="careerpage-text01">Job description</h1>
          </div>
          <div className="careerpage-container05">
            <ul className="careerpage-ul list">
              {careers.description && careers.description.split(';').map((point, index) => (
                <li key={index} className="careerpage-li list-item">
                  <span className="careerpage-text02">
                    {point.trim()}
                  </span>
                </li>
              ))}
            </ul>
          </div> 


          <div class="careerpage-container06">
           <h1 class="careerpage-text08">Qualifications</h1>
           </div>      
          <div className="careerpage-container05">
            <ul className="careerpage-ul list">
              {careers.qualification.split(';').map((point, index) => (
                <li key={index} className="careerpage-li list-item">
                  <span className="careerpage-text02">
                    {point.trim()}
                  </span>
                </li>
              ))}
            </ul>
          </div>
            <div class="careerpage-container07">
            <button
                      className="careerpage-button button"
                      id="apply-button"
                      row_id={careers.jobTitle}
                      onClick={handleApplyButtonClick}
                    > Apply Now</button>
            <div class="careerpage-container08">
                <h1 class="careerpage-text13" onClick={openPopup}>Share</h1>
                <svg viewBox="0 0 1024 1024" class="careerpage-icon">
                  <path
                    d="M1024 365.714c0 9.714-4 18.857-10.857 25.714l-292.571 292.571c-6.857 6.857-16 10.857-25.714 10.857-20 0-36.571-16.571-36.571-36.571v-146.286h-128c-246.286 0-408 47.429-408 320 0 23.429 1.143 46.857 2.857 70.286 0.571 9.143 2.857 19.429 2.857 28.571 0 10.857-6.857 20-18.286 20-8 0-12-4-16-9.714-8.571-12-14.857-30.286-21.143-43.429-32.571-73.143-72.571-177.714-72.571-257.714 0-64 6.286-129.714 30.286-190.286 79.429-197.143 312.571-230.286 500-230.286h128v-146.286c0-20 16.571-36.571 36.571-36.571 9.714 0 18.857 4 25.714 10.857l292.571 292.571c6.857 6.857 10.857 16 10.857 25.714z"
                  ></path>
                </svg>
              </div>
              <div class="popup" id="popup">
                      <h2>Share on Social Media!</h2>
                     <div>
                        <a href="https://www.facebook.com/" target="_blank">
                          <img src="./playground_assets/square-facebook.svg" alt="" onClick={() => shareOnSocialMedia('facebook-icon', careers.jobTitle)} />
                        </a>
                        <a href="https://www.instagram.com/" target="_blank">
                          <img src="./playground_assets/square-instagram.svg" alt="" onClick={() => shareOnSocialMedia('instagram-icon', careers.jobTitle)} />
                        </a>
                        <a href="https://www.twitter.com/" target="_blank">
                          <img src="./playground_assets/square-twitter.svg" alt="" onClick={() => shareOnSocialMedia('twitter-icon', careers.jobTitle)} />
                        </a>
                        <a href="https://www.linkedin.com/" target="_blank">
                          <img src="./playground_assets/linkedin.svg" alt="" onClick={() => shareOnSocialMedia('linkedin-icon', careers.jobTitle)} />
                        </a>
                        <a id="whatsapp-icon" href="https://wa.me/?text=" target="_blank">
                          <img src="./playground_assets/whatsapp-square.svg" alt="" onClick={() => shareOnSocialMedia('whatsapp-icon', careers.jobTitle)} />
                        </a>
                        <div className="copy-link">
                            <input type="text" className="copy-link-input" value="" readOnly />
                            <span className="copy-link-button" onClick={copy}>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style={{ width: '50%' }}>
                                <path d="M320 448v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V120c0-13.255 10.745-24 24-24h72v296c0 30.879 25.121 56 56 56h168zm0-344V0H152c-13.255 0-24 10.745-24 24v368c0 13.255 10.745 24 24 24h272c13.255 0 24-10.745 24-24V128H344c-13.2 0-24-10.8-24-24zm120.971-31.029L375.029 7.029A24 24 0 0 0 358.059 0H352v96h96v-6.059a24 24 0 0 0-7.029-16.97z" />
                              </svg>
                            </span>
                          </div>
                      </div> 
                <button type="button" onclick="closePopup()">Ok</button>
              </div>

              </div>


        </div>
        <div className="careerpage-container09">
      <h1 className="careerpage-text14">Other Jobs</h1>
      <div className="careerpage-container10" id="jobTitlesContainer">
        {actcareers.map((actcareer, index) => (
          <div className="careerpage-container11" key={index}>
            <h1 className="careerpage-text15 list">
              <a href={`/career/${actcareer._id}`}>{actcareer.jobTitle}</a>
            </h1>
            <a href={`/career/${actcareer._id}`}></a>
            <svg viewBox="0 0 1024 1024" className="careerpage-icon02" href={`/career/${actcareer._id}`} >
              <path d="M366 708l196-196-196-196 60-60 256 256-256 256z" href={`/career/${actcareer._id}`}></path>
            </svg>
          </div>
        ))}
      </div>
    </div>

        </div>
        
        </div>
        </div>
      
      {productViewVisible && (
        <div id="product_view">
          <div className="career-submission-form-container">
            <form className="career-submission-form-form" id="enquiryForm" onSubmit={handleSubmit}>
              <div className="career-submission-form-container1">
                <div className="career-submission-form-container2">
                  <div className="career-submission-form-container3">
                    <input
                      type="text"
                      placeholder="First Name"
                      id="first_name"
                      name="firstname"
                      className="career-submission-form-textinput input"
                    />
                    <input
                      type="hidden"
                      name="enquirytype"
                      id="enquirytype"
                      value="1"
                    />
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email Address"
                      autoComplete="on"
                      className="career-submission-form-textinput1 input"
                    />
                  </div>
                  <div className="career-submission-form-container4">
                    <div className="career-submission-form-container5">
                      <input
                        type="text"
                        id="last_name"
                        name="lastname"
                        placeholder="Last Name"
                        className="career-submission-form-textinput2 input"
                      />
                      <input
                        type="tel"
                        placeholder="Phone"
                        id="phone"
                        name="phone"
                        className="career-submission-form-textinput3 input"
                      />
                    </div>
                  </div>
                </div>
                <div className="career-submission-form-container6">
                <select
                    required
                    className="career-submission-form-select"
                    id="job_title"
                    value={jobTitle}
                    onChange={(e) => setJobTitle(e.target.value)}
                  >
                    {jobTitle && (
                      <option value={jobTitle}>{jobTitle}</option>
                    )}
                  </select>
                  <textarea
                    placeholder="Enter Message"
                    className="career-submission-form-textarea textarea"
                    id="description"
                    name="description"
                  ></textarea>
                  <button
                    className="career-submission-form-button button"
                    id="submit-product_view"
                  >
                    SUBMIT
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      </body>
      {/* <Footer/> */}
    </div>
  )
}

export default Careerdetails
