import React, { useState,useEffect } from 'react';
import Swal from 'sweetalert2';
import './style.css';
import './contact.css';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import axios from 'axios';
function Contact({backendUrl}) {
  
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    jobtitle: '',
    description: '',
    enquirytype: '2',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (
      formData.firstname === '' ||
      formData.email === '' ||
      formData.description === '' ||
      formData.phone === ''
    ) {
      showAlert('Warning!', 'Please Enter Basic Details', 'warning');
    } else if (!validateEmail(formData.email)) {
      showAlert('Warning!', 'Please enter a valid email address', 'warning');
    } else if (formData.phone.length < 8) {
      showAlert('Warning!', 'Phone No must be at least 8 characters long', 'warning');
    } else {
      const data = {
        lastname: formData.lastname,
        firstname: formData.firstname,
        email: formData.email,
        phone: formData.phone,
        jobtitle: formData.jobtitle,
        description: formData.description,
        type: formData.enquirytype,
      };

      axios
        .post(`${backendUrl}/saveEnquiry`,data)
        .then((response) => {
          showAlert('Success!', response.data.message, 'success');
          clearForm();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const showAlert = (title, text, icon) => {
    Swal.fire({
      title: title,
      text: text,
      icon: icon,
      confirmButtonText: 'OK',
    });
  };

  const clearForm = () => {
    setFormData({
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      jobtitle: '',
      description: '',
      enquirytype: '2',
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return emailRegex.test(email);
  };
  return (
    <div>
      {/* <Header /> */}
      <script src="https://code.jquery.com/jquery-3.6.0.min.js"></script>
      <head>
    <title>
      Contact-us - Best and quality medical disposable manufacturer in UAE
    </title>
    <meta
      name="description"
      content="We are the quality manufacturer of medical disposables in UAE for the past 7 years.  We provide Respiratory, PPE and nursing consumables, Surgical Kits, etc."
    />
    <meta
      property="og:title"
      content="Contact-us - Best and quality medical disposable manufacturer in UAE "
    />
    <meta
      property="og:description"
      content="We are the quality manufacturer of medical disposables in UAE for the past 7 years.  We provide Respiratory, PPE and nursing consumables, Surgical Kits, etc."
    />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta charset="utf-8" />
    <meta property="twitter:card" content="summary_large_image" />
    <link
      rel="shortcut icon"
      src='./playground_assets/32x32.png'
      type="icon/png"
      sizes="32x32"
    />
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap"
      data-tag="font"
    />
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap"
      data-tag="font"
    />
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap"
      data-tag="font"
    />
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&amp;display=swap"
      data-tag="font"
    />
     <script
        type="text/javascript"
        src="https://unpkg.com/dangerous-html@0.1.11/dist/default/lib.umd.js"
      ></script>
    </head>
    <body>
   
    <div>
      <script
        type="text/javascript"
        src="https://unpkg.com/dangerous-html@0.1.11/dist/default/lib.umd.js"
      ></script>
  

      <div class="contact-us-container">
        <div class="contact-us-container01">
          <div class="contact-us-container02">
            <h1 class="contact-us-text">Contact Us</h1>
          </div>
        </div>
        <div class="contact-us-container03">
          <div class="contact-us-container04">
            <div class="contact-us-container05">
              <div class="contact-us-container06">
                <div class="contact-us-container07">
                  <svg viewBox="0 0 1024 1024" class="contact-us-icon">
                    <path
                      d="M512 490q44 0 75-31t31-75-31-75-75-31-75 31-31 75 31 75 75 31zM512 86q124 0 211 87t87 211q0 62-31 142t-75 150-87 131-73 97l-32 34q-12-14-32-37t-72-92-91-134-71-147-32-144q0-124 87-211t211-87z"
                    ></path>
                  </svg>
                  <span class="contact-us-text1">
                    <span>Safecare Medical Industries</span>
                    <br />
                    <span class="contact-us-text4">
                      KHI-8-18 - Abu Dhabi -&nbsp;
                    </span>
                    <span class="contact-us-text5">United Arab Emirates</span>
                  </span>
                </div>
                <div class="contact-us-container08">
                  <svg viewBox="0 0 1024 1024" class="contact-us-icon2">
                    <path
                      d="M282 460q96 186 282 282l94-94q20-20 44-10 72 24 152 24 18 0 30 12t12 30v150q0 18-12 30t-30 12q-300 0-513-213t-213-513q0-18 12-30t30-12h150q18 0 30 12t12 30q0 80 24 152 8 26-10 44z"
                    ></path>
                  </svg>
                  <a href="tel:+971-554 334 560" class="contact-us-link">
                    +971554334560
                  </a>
                </div>
                <div class="contact-us-container09">
                  <svg viewBox="0 0 1024 1024" class="contact-us-icon4">
                    <path
                      d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"
                    ></path>
                  </svg>
                  <a
                    href="mailto:info@safecareind.com?subject="
                    class="contact-us-link1"
                  >
                    info@safecareind.com
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="contact-us-container10">
            <div class="contact-us-container11">
              <h1 class="contact-us-text6">Send us your query anytime!</h1>
            </div>
            <div class="contact-us-container12">
              <form class="contact-us-form" id="ContactForm" onSubmit={handleSubmit}>
                <div class="contact-us-container13">
                  <div class="contact-us-container14">
                    <div class="contact-us-container15">
                      <input
                        type="text"
                    
                        placeholder="First Name" id="firstname" name="firstname" value={formData.firstname} onChange={handleInputChange}
                        class="contact-us-textinput input"
                      />
                      <input type="hidden" id="lastname" name="lastname"  value={formData.lastname} onChange={handleInputChange} />
                      <input type="hidden" id="enquirytype" name="enquirytype" value="2"/>
                      <input
                        type="text"
                       
                        placeholder="Subject"
                        autocomplete="on" name="jobtitle" id="jobtitle" value={formData.jobtitle} onChange={handleInputChange}
                        class="contact-us-textinput1 input"
                      />
                     
                    </div>
                    
                    <div class="contact-us-container16">
                      <div class="contact-us-container17">
                        <input
                          type="email"
                         
                          placeholder="Enter email" id="email" name="email"  value={formData.email} onChange={handleInputChange}
                          class="contact-us-textinput2 input"
                        />
                        <input
                          type="tel"
                          
                          placeholder="Phone" id="phone" name="phone"  value={formData.phone} onChange={handleInputChange}
                          class="contact-us-textinput3 input"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="contact-us-container18">
                    <textarea
                      placeholder="Enter Message"
                      class="contact-us-textarea textarea" name="description" id="description" value={formData.description} onChange={handleInputChange}
                    ></textarea>
                    <button class="contact-us-button button" id="submit-contact-us">
                      SEND MESSAGE
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="contact-us-container19">
          <div class="contact-us-div">
            <dangerous-html
              html="<div style='text-align:center;'>
    <iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20206.3390221396!2d54.70463058534053!3d24.728052106162842!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5effd1737a9a77%3A0x4bdf4e512ac74a2a!2sSafe%20Care%20Medical%20Industry!5e0!3m2!1sen!2sae!4v1670939984800!5m2!1sen!2sae' width='100%' height='300' style='border:0;' allowfullscreen='' loading='lazy' referrerpolicy='no-referrer-when-downgrade'></iframe>
</div>"
            ></dangerous-html>
          </div>
        </div>
      </div>
    </div>
  
    <script
      data-section-id="header"
      src="https://unpkg.com/@teleporthq/teleport-custom-scripts"
    ></script>
    <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
  </body>
    {/* <Footer /> */}
    </div>
  )
}

export default Contact
