import styled from 'styled-components';
export const ProductContainer = styled.div`
.products-container {
    width: 100%;
    display: flex;
    overflow: auto;
    align-items: center;
    flex-direction: column;
  }
  .products-container01 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    position: sticky;
    flex-wrap: wrap;
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 10px;
    justify-content: center;
  }
  .products-container02 {
    flex: 0 0 auto;
    width: auto;
    cursor: pointer;
    height: auto;
    display: flex;
    transition: 0.3s;
    align-items: flex-start;
    margin-left: var(--dl-space-space-unit);
    padding-top: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-primary-100);
    border-width: 1px;
    margin-right: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    border-radius: 50px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .products-container02:hover {
    background-image: linear-gradient(90deg, rgb(88, 181, 107) 0.00%,rgb(31, 149, 141) 100.00%);
  }
  .products-container02:active {
    background-color: #D9D9D9;
  }
  .products-text {
    transition: 0.3s;
  }
  .products-text:hover {
    color: #ffffff;
  }
  .products-container03 {
    flex: 0 0 auto;
    width: auto;
    cursor: pointer;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-left: var(--dl-space-space-unit);
    padding-top: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-primary-100);
    border-width: 1px;
    margin-right: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    border-radius: 50px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .products-container03:hover {
    background-image: linear-gradient(90deg, rgb(88, 181, 107) 0.00%,rgb(31, 149, 141) 100.00%);
  }
  .products-text01 {
    transition: 0.3s;
  }
  .products-text01:hover {
    color: #ffffff;
  }
  .products-container04 {
    flex: 0 0 auto;
    width: auto;
    cursor: pointer;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-left: var(--dl-space-space-unit);
    padding-top: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-primary-100);
    border-width: 1px;
    margin-right: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    border-radius: 50px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .products-container04:hover {
    background-image: linear-gradient(90deg, rgb(88, 181, 107) 0.00%,rgb(31, 149, 141) 100.00%);
  }
  .products-text02 {
    transition: 0.3s;
  }
  .products-text02:hover {
    color: #ffffff;
  }
  .products-container05 {
    flex: 0 0 auto;
    width: auto;
    cursor: pointer;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-left: var(--dl-space-space-unit);
    padding-top: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-primary-100);
    border-width: 1px;
    margin-right: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    border-radius: 50px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .products-container05:hover {
    background-image: linear-gradient(90deg, rgb(88, 181, 107) 0.00%,rgb(31, 149, 141) 100.00%);
  }
  .products-text03 {
    transition: 0.3s;
  }
  .products-text03:hover {
    color: #ffffff;
  }
  .products-container06 {
    flex: 0 0 auto;
    width: auto;
    cursor: pointer;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-left: var(--dl-space-space-unit);
    padding-top: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-primary-100);
    border-width: 1px;
    margin-right: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    border-radius: 50px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .products-container06:hover {
    background-image: linear-gradient(90deg, rgb(88, 181, 107) 0.00%,rgb(31, 149, 141) 100.00%);
  }
  .products-text04 {
    transition: 0.3s;
  }
  .products-text04:hover {
    color: #ffffff;
  }
  .products-container07 {
    flex: 0 0 auto;
    width: auto;
    cursor: pointer;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-left: var(--dl-space-space-unit);
    padding-top: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-primary-100);
    border-width: 1px;
    margin-right: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    border-radius: 50px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .products-container07:hover {
    background-image: linear-gradient(90deg, rgb(88, 181, 107) 0.00%,rgb(31, 149, 141) 100.00%);
  }
  .products-text05 {
    transition: 0.3s;
  }
  .products-text05:hover {
    color: #ffffff;
  }
  .products-container08 {
    flex: 0 0 auto;
    width: auto;
    cursor: pointer;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-left: var(--dl-space-space-unit);
    padding-top: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-primary-100);
    border-width: 1px;
    margin-right: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    border-radius: 50px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .products-container08:hover {
    background-image: linear-gradient(90deg, rgb(88, 181, 107) 0.00%,rgb(31, 149, 141) 100.00%);
  }
  .products-text06 {
    transition: 0.3s;
  }
  .products-text06:hover {
    color: #ffffff;
  }
  .products-container09 {
    flex: 0 0 auto;
    width: auto;
    cursor: pointer;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-left: var(--dl-space-space-unit);
    padding-top: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-primary-100);
    border-width: 1px;
    margin-right: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    border-radius: 50px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .products-container09:hover {
    background-image: linear-gradient(90deg, rgb(88, 181, 107) 0.00%,rgb(31, 149, 141) 100.00%);
  }
  .products-text07 {
    transition: 0.3s;
  }
  .products-text07:hover {
    color: #ffffff;
  }
  .products-container10 {
    flex: 0 0 auto;
    width: auto;
    cursor: pointer;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-left: var(--dl-space-space-unit);
    padding-top: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-primary-100);
    border-width: 1px;
    margin-right: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    border-radius: 50px;
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .products-container10:hover {
    background-image: linear-gradient(90deg, rgb(88, 181, 107) 0.00%,rgb(31, 149, 141) 100.00%);
  }
  .products-text08 {
    transition: 0.3s;
  }
  .products-text08:hover {
    color: #ffffff;
  }
  .products-container11 {
    flex: 0 0 auto;
    width: auto;
    cursor: pointer;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-left: var(--dl-space-space-unit);
    padding-top: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-primary-100);
    border-width: 1px;
    margin-right: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    border-radius: 50px;
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .products-container11:hover {
    background-image: linear-gradient(90deg, rgb(88, 181, 107) 0.00%,rgb(31, 149, 141) 100.00%);
  }
  .products-text09 {
    transition: 0.3s;
  }
  .products-text09:hover {
    color: #ffffff;
  }
  .products-container12 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .products-text10 {
    font-size: 28px;
  }
  .products-container13 {
    flex: 0 0 auto;
    width: 30%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    border-radius: 50px;
    justify-content: center;
    background-image: linear-gradient(90deg, rgb(88, 181, 107) 0.00%,rgb(31, 149, 141) 100.00%);
  }
  .products-text11 {
    color: var(--dl-color-gray-white);
    font-size: 25px;
  }
  .products-container14 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding-top: 0;
    align-items: flex-start;
    justify-content: center;
  }
  .products-testimonial {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .products-container15 {
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-threeunits);
    max-width: 1600px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .products-container16 {
    width: 100%;
    display: grid;
    grid-gap: var(--dl-space-space-twounits);
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .products-testimonial-card {
    display: flex;
    padding: var(--dl-space-space-twounits);
    max-width: var(--dl-size-size-maxwidth);
    background: #fff;
    box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid var(--dl-color-primary-100);
  }
  .products-testimonial1 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
  }
  .products-image {
    max-width: 100%;
    max-height: 100%; /* adjust to your desired height */
    padding: var(--dl-space-space-oneandhalfunits);
    object-fit: cover;
  }
  
  .products-text12 {
    font-size: 20px;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  /* .products-text13 {
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-halfunit);
  } */
  .products-navlink {
    cursor: pointer;
    align-self: center;
    margin-top: var(--dl-space-space-oneandhalfunits);
    transition: 0.3s;
   
    border-color: var(--dl-color-primary-100);
    padding-left: var(--dl-space-space-twounits);
    border-radius: 50px;
    padding-right: var(--dl-space-space-twounits);
   
    text-decoration: none;
  }
  .products-navlink:hover {
    color: var(--dl-color-gray-white);
    background-color: var(--dl-color-primary-100);
  }
  .products-testimonial-card1 {
    display: flex;
    padding: var(--dl-space-space-twounits);
    max-width: var(--dl-size-size-maxwidth);
    background: #fff;
    box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid var(--dl-color-primary-100);
  }
  .products-testimonial2 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
  }
  .products-image1 {
    width: auto;
    padding: var(--dl-space-space-oneandhalfunits);
    object-fit: cover;
  }
  .products-text14 {
    font-size: 20px;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .products-text15 {
    font-size: 28px;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .products-navlink1 {
    cursor: pointer;
    align-self: center;
    margin-top: var(--dl-space-space-twounits);
    transition: 0.3s;
    
    border-color: var(--dl-color-primary-100);
    padding-left: var(--dl-space-space-twounits);
    border-radius: 50px;
    padding-right: var(--dl-space-space-twounits);
    
    text-decoration: none;
  }
  .products-navlink1:hover {
    color: var(--dl-color-gray-white);
    background-color: var(--dl-color-primary-100);
  }
  .products-testimonial-card2 {
    display: flex;
    padding: var(--dl-space-space-twounits);
    max-width: var(--dl-size-size-maxwidth);
    background: #fff;
    box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid var(--dl-color-primary-100);
  }
  .products-testimonial3 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
  }
  .products-image2 {
    width: auto;
    padding: var(--dl-space-space-oneandhalfunits);
    object-fit: cover;
  }
  .products-text16 {
    font-size: 20px;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .products-text17 {
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .products-navlink2 {
    cursor: pointer;
    align-self: center;
    margin-top: var(--dl-space-space-twounits);
    transition: 0.3s;
    
    border-color: var(--dl-color-primary-100);
    padding-left: var(--dl-space-space-twounits);
    border-radius: 50px;
    padding-right: var(--dl-space-space-twounits);
    
    text-decoration: none;
  }
  .products-navlink2:hover {
    color: var(--dl-color-gray-white);
    background-color: var(--dl-color-primary-100);
  }
  .products-testimonial-card3 {
    display: flex;
    padding: var(--dl-space-space-twounits);
    max-width: var(--dl-size-size-maxwidth);
    background: #fff;
    box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid var(--dl-color-primary-100);
  }
  .products-testimonial4 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
  }
  .products-image3 {
    width: auto;
    padding: var(--dl-space-space-oneandhalfunits);
    object-fit: cover;
  }
  .products-text18 {
    font-size: 20px;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .products-text19 {
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .products-navlink3 {
    cursor: pointer;
    align-self: center;
    margin-top: var(--dl-space-space-twounits);
    transition: 0.3s;
    
    border-color: var(--dl-color-primary-100);
    padding-left: var(--dl-space-space-twounits);
    border-radius: 50px;
    padding-right: var(--dl-space-space-twounits);
    
    text-decoration: none;
  }
  .products-navlink3:hover {
    color: var(--dl-color-gray-white);
    background-color: var(--dl-color-primary-100);
  }
  @media(max-width: 1600px) {
    .products-container01 {
      justify-content: center;
    }
    .products-container09 {
      margin-bottom: 0px;
    }
    .products-container12 {
      height: auto;
      align-items: center;
      justify-content: center;
    }
    .products-text10 {
      font-size: 15px;
    }
    .products-text11 {
      font-size: 22px;
    }
    .products-text_11 {
      font-size: 13px;
    }
    .products-container14 {
      height: auto;
      padding: 0px;
    }
    .products-container15 {
      padding-top: var(--dl-space-space-threeunits);
    }
    .products-testimonial-card {
      align-items: flex-start;
    }
    .products-testimonial1 {
      align-self: center;
      align-items: center;
    }
    .products-image {
      width: auto;
      padding: var(--dl-space-space-oneandhalfunits);
    }
    .products-text12 {
      font-size: 18px;
      padding-top: var(--dl-space-space-halfunit);
      padding-left: var(--dl-space-space-oneandhalfunits);
      padding-right: var(--dl-space-space-oneandhalfunits);
      padding-bottom: var(--dl-space-space-halfunit);
    }
    .products-text13 {
      font-size: 18px;
      padding-top: 0px;
      padding-left: mixed;
      padding-right: mixed;
      padding-bottom: 0px;
      text-align: center;
    }
    .products-testimonial-card1 {
      align-items: flex-start;
    }
    .products-testimonial2 {
      align-self: center;
      align-items: flex-start;
    }
    .products-image1 {
      width: auto;
      padding: var(--dl-space-space-oneandhalfunits);
    }
    .products-text14 {
      font-size: 18px;
      padding-top: var(--dl-space-space-halfunit);
      padding-left: var(--dl-space-space-oneandhalfunits);
      padding-right: var(--dl-space-space-oneandhalfunits);
      padding-bottom: var(--dl-space-space-halfunit);
    }
    .products-text15 {
      font-size: 24px;
      padding-left: mixed;
      padding-right: mixed;
      padding-bottom: 0px;
    }
    .products-testimonial-card2 {
      align-items: flex-start;
    }
    .products-testimonial3 {
      align-self: center;
      align-items: flex-start;
    }
    .products-image2 {
      width: auto;
      padding: var(--dl-space-space-oneandhalfunits);
    }
    .products-text16 {
      font-size: 18px;
      padding-top: var(--dl-space-space-halfunit);
      padding-left: var(--dl-space-space-oneandhalfunits);
      padding-right: var(--dl-space-space-oneandhalfunits);
      padding-bottom: var(--dl-space-space-halfunit);
    }
    .products-text17 {
      font-size: 24px;
      padding-top: 0px;
      padding-left: mixed;
      padding-right: mixed;
      padding-bottom: 0px;
    }
    .products-testimonial-card3 {
      align-items: center;
    }
    .products-testimonial4 {
      align-self: center;
      align-items: flex-start;
    }
    .products-image3 {
      width: auto;
      padding: var(--dl-space-space-oneandhalfunits);
      align-self: center;
    }
    .products-text18 {
      padding: var(--dl-space-space-oneandhalfunits);
      font-size: 18px;
      padding-top: var(--dl-space-space-halfunit);
      padding-left: var(--dl-space-space-oneandhalfunits);
      padding-right: var(--dl-space-space-oneandhalfunits);
      padding-bottom: var(--dl-space-space-halfunit);
    }
    .products-text19 {
      font-size: 24px;
      padding-top: 0px;
      padding-left: mixed;
      padding-right: mixed;
      padding-bottom: 0px;
    }
  }
  @media(max-width: 1200px) {
    .products-container01 {
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
    }
    .products-container02 {
      margin-left: var(--dl-space-space-unit);
      margin-bottom: var(--dl-space-space-halfunit);
    }
    .products-text {
      font-size: 14px;
    }
    .products-container03 {
      margin-bottom: var(--dl-space-space-halfunit);
    }
    .products-text01 {
      font-size: 14px;
    }
    .products-container04 {
      margin-bottom: var(--dl-space-space-halfunit);
    }
    .products-text02 {
      font-size: 14px;
    }
    .products-container05 {
      margin-bottom: var(--dl-space-space-halfunit);
    }
    .products-text03 {
      font-size: 14px;
    }
    .products-container06 {
      margin-bottom: var(--dl-space-space-halfunit);
    }
    .products-text04 {
      font-size: 14px;
    }
    .products-container07 {
      margin-bottom: var(--dl-space-space-halfunit);
    }
    .products-text05 {
      font-size: 14px;
    }
    .products-container08 {
      margin-bottom: var(--dl-space-space-halfunit);
    }
    .products-text06 {
      font-size: 14px;
    }
    .products-container09 {
      margin-bottom: var(--dl-space-space-halfunit);
    }
    .products-text07 {
      font-size: 14px;
    }
    .products-container10 {
      margin-bottom: var(--dl-space-space-halfunit);
    }
    .products-text08 {
      font-size: 14px;
    }
    .products-container11 {
      margin-bottom: var(--dl-space-space-halfunit);
    }
    .products-text09 {
      font-size: 14px;
    }
    .products-text10 {
      font-size: 15px;
    }
    .products-text11 {
      font-size: 18px;
    }
    .products-container16 {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .products-text14 {
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .products-text16 {
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .products-text18 {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
  @media(max-width: 991px) {
    .products-container02 {
      margin-left: var(--dl-space-space-halfunit);
      margin-right: var(--dl-space-space-halfunit);
    }
    .products-text {
      font-size: 12px;
    }
    .products-container03 {
      margin-left: var(--dl-space-space-halfunit);
      margin-right: var(--dl-space-space-halfunit);
    }
    .products-text01 {
      font-size: 12px;
    }
    .products-container04 {
      margin-left: var(--dl-space-space-halfunit);
      margin-right: var(--dl-space-space-halfunit);
    }
    .products-text02 {
      font-size: 12px;
    }
    .products-container05 {
      margin-left: var(--dl-space-space-halfunit);
      margin-right: var(--dl-space-space-halfunit);
    }
    .products-text03 {
      font-size: 12px;
    }
    .products-container06 {
      margin-left: var(--dl-space-space-halfunit);
      margin-right: var(--dl-space-space-halfunit);
    }
    .products-text04 {
      font-size: 12px;
    }
    .products-container07 {
      margin-left: var(--dl-space-space-halfunit);
      margin-right: var(--dl-space-space-halfunit);
    }
    .products-text05 {
      font-size: 12px;
    }
    .products-container08 {
      margin-left: var(--dl-space-space-halfunit);
      margin-right: var(--dl-space-space-halfunit);
    }
    .products-text06 {
      font-size: 12px;
    }
    .products-container09 {
      margin-left: var(--dl-space-space-halfunit);
      margin-right: var(--dl-space-space-halfunit);
    }
    .products-text07 {
      font-size: 12px;
    }
    .products-container10 {
      margin-left: var(--dl-space-space-halfunit);
      margin-right: var(--dl-space-space-halfunit);
    }
    .products-text08 {
      font-size: 12px;
    }
    .products-container11 {
      margin-left: var(--dl-space-space-halfunit);
      margin-right: var(--dl-space-space-halfunit);
    }
    .products-text09 {
      font-size: 12px;
    }
    .products-text10 {
      font-size: 18px;
    }
    .products-container13 {
      width: 40%;
    }
    .products-text11 {
      font-size: 16px;
    }
    .products-container14 {
      flex-direction: column;
    }
    .products-container16 {
      grid-template-columns: 1fr 1fr;
    }
  }
  @media(max-width: 767px) {
    .products-container01 {
      padding-top: var(--dl-space-space-unit);
      padding-bottom: var(--dl-space-space-unit);
    }
    .products-container02 {
      margin-left: 4px;
      margin-right: 4px;
    }
    .products-container03 {
      margin-left: 4px;
      margin-right: 4px;
    }
    .products-text01 {
      font-size: 10px;
    }
    .products-container04 {
      margin-left: 4px;
      margin-right: 4px;
    }
    .products-text02 {
      font-size: 10px;
    }
    .products-container05 {
      margin-left: 4px;
      margin-right: 4px;
    }
    .products-text03 {
      font-size: 10px;
    }
    .products-container06 {
      margin-left: 4px;
      margin-right: 4px;
    }
    .products-text04 {
      font-size: 10px;
    }
    .products-container07 {
      margin-left: 4px;
      margin-right: 4px;
    }
    .products-text05 {
      font-size: 10px;
    }
    .products-container08 {
      margin-left: 4px;
      margin-right: 4px;
    }
    .products-text06 {
      font-size: 10px;
    }
    .products-container09 {
      margin-left: 4px;
      margin-right: 4px;
    }
    .products-text07 {
      font-size: 10px;
    }
    .products-container10 {
      margin-left: 4px;
      margin-right: 4px;
    }
    .products-text08 {
      font-size: 10px;
    }
    .products-container11 {
      margin-left: 4px;
      margin-right: 4px;
    }
    .products-text09 {
      font-size: 10px;
    }
    .products-text10 {
      font-size: 16px;
    }
    .products-container13 {
      width: 60%;
    }
    .products-text11 {
      font-size: 14px;
    }
    .products-container15 {
      padding-left: var(--dl-space-space-twounits);
      padding-right: var(--dl-space-space-twounits);
    }
    .products-container16 {
      align-self: center;
      grid-template-columns: 1fr;
    }
    .products-testimonial-card {
      padding-left: var(--dl-space-space-twounits);
      padding-right: var(--dl-space-space-twounits);
    }
    .products-testimonial1 {
      align-self: center;
      align-items: center;
    }
    .products-testimonial-card1 {
      padding-left: var(--dl-space-space-twounits);
      padding-right: var(--dl-space-space-twounits);
    }
    .products-testimonial2 {
      align-self: center;
      align-items: center;
    }
    .products-testimonial-card2 {
      padding-left: var(--dl-space-space-twounits);
      padding-right: var(--dl-space-space-twounits);
    }
    .products-testimonial3 {
      align-self: center;
      align-items: center;
    }
    .products-testimonial-card3 {
      align-items: center;
      padding-left: var(--dl-space-space-twounits);
      padding-right: var(--dl-space-space-twounits);
    }
    .products-testimonial4 {
      align-self: center;
      align-items: center;
    }
  }
  @media(max-width: 479px) {
    .products-container02 {
      border-width: .5px;
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
    }
    .products-text {
      color: var(--dl-color-gray-black);
      font-size: 10px;
    }
    .products-container03 {
      border-width: .5px;
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
    }
    .products-text01 {
      font-size: 10px;
    }
    .products-container04 {
      border-width: .5px;
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
    }
    .products-text02 {
      font-size: 10px;
    }
    .products-container05 {
      border-width: .5px;
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
    }
    .products-text03 {
      font-size: 10px;
    }
    .products-container06 {
      border-width: .5px;
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
    }
    .products-text04 {
      font-size: 10px;
    }
    .products-container07 {
      border-width: .5px;
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
    }
    .products-text05 {
      font-size: 10px;
    }
    .products-container08 {
      border-width: .5px;
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
    }
    .products-text06 {
      font-size: 10px;
    }
    .products-container09 {
      border-width: .5px;
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
    }
    .products-text07 {
      font-size: 10px;
    }
    .products-container10 {
      border-width: .5px;
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
    }
    .products-text08 {
      font-size: 10px;
    }
    .products-container11 {
      border-width: .5px;
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
    }
    .products-text09 {
      font-size: 10px;
    }
    .products-text10 {
      font-size: 14px;
    }
    .products-container13 {
      width: 80%;
      align-items: center;
      justify-content: center;
    }
    .products-text11 {
      font-size: 12px;
    }
    .products-container15 {
      padding-top: var(--dl-space-space-twounits);
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
      padding-bottom: var(--dl-space-space-twounits);
    }
    .products-container16 {
      grid-template-columns: 1fr;
    }
    .products-testimonial-card {
      padding-top: var(--dl-space-space-twounits);
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
      padding-bottom: var(--dl-space-space-twounits);
    }
    .products-testimonial-card1 {
      padding-top: var(--dl-space-space-twounits);
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
      padding-bottom: var(--dl-space-space-twounits);
    }
    .products-testimonial-card2 {
      padding-top: var(--dl-space-space-twounits);
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
      padding-bottom: var(--dl-space-space-twounits);
    }
    .products-testimonial-card3 {
      padding-top: var(--dl-space-space-twounits);
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
      padding-bottom: var(--dl-space-space-twounits);
    }
  }
  /* html {  line-height: 1.15;}body {  margin: 0;}* {  box-sizing: border-box;  border-width: 0;  border-style: solid;}p,li,ul,pre,div,h1,h2,h3,h4,h5,h6,figure,blockquote,figcaption {  margin: 0;  padding: 0;}button {  background-color: transparent;}button,input,optgroup,select,textarea {  font-family: inherit;  font-size: 100%;  line-height: 1.15;  margin: 0;}button,select {  text-transform: none;}button,[type="button"],[type="reset"],[type="submit"] {  -webkit-appearance: button;}button::-moz-focus-inner,[type="button"]::-moz-focus-inner,[type="reset"]::-moz-focus-inner,[type="submit"]::-moz-focus-inner {  border-style: none;  padding: 0;}button:-moz-focus,[type="button"]:-moz-focus,[type="reset"]:-moz-focus,[type="submit"]:-moz-focus {  outline: 1px dotted ButtonText;}a {  color: inherit;  text-decoration: inherit;}input {  padding: 2px 4px;}img {  display: block;}html { scroll-behavior: smooth  }

  html {
    font-family: Inter;
    font-size: 16px;
  }

  body {
    font-weight: 400;
    font-style:normal;
    text-decoration: none;
    text-transform: none;
    letter-spacing: normal;
    line-height: 1.15;
    color: var(--dl-color-gray-black);
    background-color: var(--dl-color-gray-white);

  } */

  [data-thq="thq-dropdown"]:hover > [data-thq="thq-dropdown-list"] {
    display: flex;
  }

  [data-thq="thq-dropdown"]:hover > div [data-thq="thq-dropdown-arrow"] {
    transform: rotate(90deg);
  }
  .no-data {
    background-color: #f7f7f7;
    border-width: 0;
    border-style: solid;
    border-color: #ccc;
    padding: 40px;
    text-align: center;
    font-size: 24px;
    color: #999;
  }
`;