import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { GalleryContainer } from './GalleryStyles';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

function Gallery() {
  useEffect(() => {
    // Any necessary code for useEffect can be added here
  }, []);

  const images = [
    './playground_assets/IMG_9210.jpg',
    './playground_assets/IMG_9225.jpg',
    './playground_assets/IMG_9228.jpg',
    './playground_assets/IMG_9240.jpg',
    './playground_assets/IMG_9210.jpg',
    './playground_assets/IMG_9225.jpg',
    './playground_assets/IMG_9228.jpg',
    './playground_assets/IMG_9240.jpg',
    './playground_assets/IMG_9210.jpg',
    './playground_assets/IMG_9225.jpg',
  ];

  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);

  const openLightbox = (index) => {
    setLightboxIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  const stopVideo = () => {
    const videoPlayer = document.getElementById('videoPlayer');
    videoPlayer.style.display = 'none';
  };

  const playVideo = (file) => {
    const myVideo = document.getElementById('myVideo');
    myVideo.src = file;

    const videoPlayer = document.getElementById('videoPlayer');
    videoPlayer.style.display = 'block';
  };

  return (
    <GalleryContainer>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Gallery</title>
       
      </Helmet>
<body>
      <div className="container">
        <h1  style={{paddingTop:'22px'}}>VIDEO GALLERY</h1>
        <div className="row">
        <div className="col1">
         <div className="feature-img">
           <img src="./playground_assets/IMG_9210.jpg" width="100%" />
           <img
             src="./playground_assets/play.png"
             className="play-btn"
             onClick={() => playVideo('./playground_assets/vid1.mp4')}
            
           />
         </div>
       </div>
       <div className="total-col">
         <div className="col1">
           <div className="small-img-row">
             <div className="small-img">
               <img src="./playground_assets/IMG_9240.JPG" />
               <img
                 src="./playground_assets/play.png"
                 className="play-btn"
                 onClick={() => playVideo('./playground_assets/vid2.mp4')}
               />
             </div>
             <p>Safecare industries Arial View</p>
           </div>
           <div className="col1"></div>
           <div className="small-img-row">
             <div className="small-img">
               <img src="./playground_assets/IMG_9228.JPG" />
               <img
                 src="./playground_assets/play.png"
                 className="play-btn"
                 onClick={() => playVideo('./playground_assets/vid1.mp4')}
               />
             </div>
             <p>Safecare industries Arial View</p>
           </div>
           <div className="col1">
             <div className="small-img-row">
               <div className="small-img">
                 <img src="./playground_assets/IMG_9225.JPG" />
                 <img
                   src="./playground_assets/play.png"
                   className="play-btn"
                   onClick={() => playVideo('./playground_assets/vid2.mp4')}
                 />
               </div>
               <p>Safecare industries Arial View video gallery</p>
             </div>
           </div>
         </div>
         <div className="col2">
           <div className="col1">
             <div className="small-img-row">
               <div className="small-img">
                 <img src="./playground_assets/IMG_9240.JPG" />
                 <img
                   src="./playground_assets/play.png"
                   className="play-btn"
                   onClick={() => playVideo('./playground_assets/vid2.mp4')}
                 />
               </div>
               <p>Safecare industries Arial View</p>
             </div>
             <div className="col1">
               <div className="small-img-row">
                 <div className="small-img">
                   <img src="./playground_assets/IMG_9228.JPG" />
                   <img
                     src="./playground_assets/play.png"
                     className="play-btn"
                     onClick={() => playVideo('./playground_assets/vid1.mp4')}
                   />
                 </div>
                 <p>Safecare industries Arial View</p>
               </div>
               <div className="col1">
                 <div className="small-img-row">
                   <div className="small-img">
                     <img src="./playground_assets/IMG_9225.JPG" />
                     <img
                       src="./playground_assets/play.png"
                       className="play-btn"
                       onClick={() => playVideo('./playground_assets/vid2.mp4')}
                     />
                   </div>
                   <p>Safecare industries Arial View video gallery</p>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>


          </div>

        <h1 style={{ paddingBottom: '0px', paddingTop: '20px' }}>IMAGE GALLERY</h1>
        <div className="gallery">
          {images.map((image, index) => (
            <a
              key={index}
              href={image}
              data-lightbox="mygallery"
              data-title="Safecare industry"
              onClick={(e) => {
                e.preventDefault();
                openLightbox(index);
              }}
            >
              <img src={image} alt={`Image ${index}`} />
            </a>
          ))}
        </div>

        {lightboxOpen && (
          <Lightbox
            mainSrc={images[lightboxIndex]}
            nextSrc={images[(lightboxIndex + 1) % images.length]}
            prevSrc={images[(lightboxIndex + images.length - 1) % images.length]}
            onCloseRequest={closeLightbox}
            onMovePrevRequest={() =>
              setLightboxIndex((lightboxIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() => setLightboxIndex((lightboxIndex + 1) % images.length)}
          />
        )}
      </div>

      <div className="video-player" id="videoPlayer">
        <video width="100%" controls autoPlay id="myVideo">
          <source src="./playground_assets/vid1.mp4" type="video/mp4" />
        </video>
        <img
          src="./playground_assets/close1.png"
          className="close1-btn"
          onClick={stopVideo}
        />
      </div>
      </body>
    </GalleryContainer>
  );
}

export default Gallery;
