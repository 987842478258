import styled from 'styled-components';
export const WishlistContainer = styled.div`
.icon-hover-primary:hover {
  border-color: #1F958D !important;
  background-color: white !important;
}

.icon-hover-primary:hover i {
  color: #58B56B !important;
}
.icon-hover-danger:hover {
  border-color: #dc4c64 !important;
  background-color: white !important;
}

.icon-hover-danger:hover i {
  color: #dc4c64 !important;
}

/*css form*/

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;900&display=swap');

*, body {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  height: 100%;
  // background-color: #152733;
  overflow: hidden;
}


.form-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    // min-height: 100vh;
}

.form-holder .form-content {
  position: relative;
  text-align: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  // padding: 60px;
}

.form-content .form-items {
  border: 3px solid #fff;
  // padding: 40px;
  display: inline-block;
  width: 100%;
  // min-width: 540px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  text-align: left;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.form-content h3 {
  color: #fff;
  text-align: left;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 5px;
}

.form-content h3.form-title {
  margin-bottom: 30px;
}

.form-content p {
  color: #fff;
  text-align: left;
  font-size: 17px;
  font-weight: 300;
  line-height: 20px;
  margin-bottom: 30px;
}


.form-content label, .was-validated .form-check-input:invalid~.form-check-label, .was-validated .form-check-input:valid~.form-check-label{
  color: #fff;
}

.form-content input[type=text], .form-content input[type=password], .form-content input[type=email], .form-content select {
  width: 100%;
  padding: 9px 20px;
  text-align: left;
  border: 0;
  outline: 0;
  border-radius: 6px;
  background-color: #fff;
  font-size: 15px;
  font-weight: 300;
  color: #8D8D8D;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-top: 16px;
}


.btn-primary{
  background-color: #02090f;
  outline: none;
  border: 0px;
   box-shadow: none;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active{
  background-color: #495056;
  outline: none !important;
  border: none !important;
   box-shadow: none;
}

.form-content textarea {
  position: static !important;
  width: 100%;
  padding: 8px 20px;
  border-radius: 6px;
  text-align: left;
  background-color: #fff;
  border: 0;
  font-size: 15px;
  font-weight: 300;
  color: #8D8D8D;
  outline: none;
  resize: none;
  height: 120px;
  -webkit-transition: none;
  transition: none;
  margin-bottom: 14px;
}

.form-content textarea:hover, .form-content textarea:focus {
  border: 0;
  background-color: #ebeff8;
  color: #8D8D8D;
}

.mv-up{
  margin-top: -9px !important;
  margin-bottom: 8px !important;
}

.invalid-feedback{
  color: #ff606e;
}

.valid-feedback{
 color: #2acc80;
}
.card-footer {
border-top: none;
background-color: transparent;
}

.btn {
color: black;

}
.btn-outline-primary {
border: #1F958D 1px solid;
}
.btn-outline-primary:hover{
background-color: #1F958D;
}
.form-button {

}
.btn-success{
color: white;
background-color: #1F958D;
}
.btn-success:hover{
background-color:#3AACB1 ;
border: none;

}
.alert {
padding: 5px;
background-color: #09ff0041;
color: rgb(0, 0, 0);
margin-top: 5px;
font-size: 14px;
}

.closebtn {
margin-left: 15px;
color: rgb(0, 0, 0);
font-weight: bold;
float: right;
font-size: 22px;
line-height: 20px;
cursor: pointer;
transition: 0.3s;
}

.closebtn:hover {
color: black;
}
.card-footer {
  border-top: none;
  background-color: transparent;
}

.btn {
  color: black;
  
}
.btn-outline-primary {
  border: #1F958D 1px solid;
}
.btn-outline-primary:hover{
  background-color: #1F958D;
}
.form-button {
  
}
.btn-success{
  color: white;
  background-color: #1F958D;
}
.btn-success:hover{
  background-color:#3AACB1 ;
  border: none;
  
}
.alert {
padding: 5px;
background-color: #09ff0041;
color: rgb(0, 0, 0);
margin-top: 5px;
font-size: 14px;
}

.closebtn {
margin-left: 15px;
color: rgb(0, 0, 0);
font-weight: bold;
float: right;
font-size: 22px;
line-height: 20px;
cursor: pointer;
transition: 0.3s;
}

.closebtn:hover {
color: black;
}
@keyframes blink-animation {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.blink {
  animation: blink-animation 1s infinite;
  color: red;
}
  `;
  
  