import React, { useState, useEffect } from 'react';
import './style.css';
// import './product.css';
import $ from 'jquery';
import { ProductContainer } from './productStyle';
function Product({ backendUrl }) {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All');

  useEffect(() => {
    fetch(`${backendUrl}/getproductdetails`)
      .then(response => response.json())
      .then(data => {
        setProducts(data.products);
        setCategories(data.categories);
      })
      .catch(error => {
        console.error(error);
      });
  }, [backendUrl]);


  function getProducts(categoryId) {
    console.log("get() called with categoryId:", categoryId);
    var data = { "categoryId": categoryId };
    fetch(`${backendUrl}/getProductAgainstcategoryId`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("get() successful response:", response);
        // Get all the products container divs
        var productsContainers = $('.products-container02');
        
        // Remove the selected class and background color from all divs
        productsContainers.removeClass('selected');
        productsContainers.css('background-color', '');
  
        // Add the selected class and background color to the clicked div container
        var clickedContainer = $(`.products-container02[data-category="${categoryId}"]`);
        clickedContainer.addClass('selected');
        clickedContainer.css('background-color', 'rgb(76, 195, 102)');
  
        // Get the products container div
        var productsContainer = $('.products-container16');
        // Clear the current contents of the div
        productsContainer.empty();
        // Add the new products to the div
        response.prod.forEach(function (product, index) {
          var productCard = '<div class="products-testimonial-card">' +
            '<div class="products-testimonial1">' +
            '<img src="/uploads/' + product.image + '" alt="' + product.productname + '" class="products-image" />' +
            '<h1 class="products-text_11">' + product.categoryId.catename + '</h1>' +
            '<h1 class="products-text13" style="align-items: center;">' + product.productname + '</h1>' +
            '<a href="product/' + product._id + '" class="products-navlink button" rowid="' + product._id + '">More Details</a>' +
            '</div></div>';
          productsContainer.append(productCard);
          document.getElementById("myDiv").style.display = "none";
        });
      })
      .catch((error) => {
        var productsContainer = $('.products-container16');
        productsContainer.empty();
        document.getElementById("myDiv").style.display = "block";
      });
  }
  
  useEffect(() => {
    $(document).ready(function() {
      $('.products-container02').addClass('selected');
      $('.products-container02').css('background-color', 'rgb(76, 195, 102)');
      const productsContainers = document.querySelectorAll('.products-container02, .products-container10');
      productsContainers.forEach(function(container) {
        container.addEventListener('click', function() {
          productsContainers.forEach(function(container) {
            container.classList.remove('selected');
            container.style.backgroundColor = '';
          });
          if (this.getAttribute('rowid') !== 'All') {
            this.classList.add('selected');
            this.style.backgroundColor = '#4CC366';
          }
        });
      });
      var queryString = window.location.search;
      var urlParams = new URLSearchParams(queryString);
      var categoryId = urlParams.get('categoryid');
  
      if (categoryId) {
        getProducts(categoryId);
      }
    });
  }, []);
  
 
  return (
    <ProductContainer>
    <div>
      <head>
      <title>
      Products - Best and quality medical disposable manufacturer in UAE
    </title>
    <meta
      name="description"
      content="We are the quality manufacturer of medical disposables in UAE for the past 7 years.  We provide Respiratory, PPE and nursing consumables, Surgical Kits, etc."
    />
    <meta
      property="og:title"
      content="Products - Best and quality medical disposable manufacturer in UAE "
    />
    <meta
      property="og:description"
      content="We are the quality manufacturer of medical disposables in UAE for the past 7 years.  We provide Respiratory, PPE and nursing consumables, Surgical Kits, etc."
    />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta charset="utf-8" />
    <meta property="twitter:card" content="summary_large_image" />
    <script src="https://code.jquery.com/jquery-3.6.0.min.js"></script>
    

    <link
      rel="shortcut icon"
      href="./playground_assets/32x32.png"
      type="icon/png"
      sizes="32x32"
    />
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap"
      data-tag="font"
    />
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap"
      data-tag="font"
    />
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap"
      data-tag="font"
    />
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&amp;display=swap"
      data-tag="font"
    />

      </head>
      <body>
    
        <div>
          <div className="products-container">
            <div className="products-container01">
              <div className="products-container02" onClick={() => getProducts('All')} data-category="All">
                <span className="products-text">All</span>
              </div>
              {categories.map((category, index) => {
                let categoryNameLength = category.catename.length;
                return (
                  <div className="products-container02" onClick={() => getProducts(category._id)}  data-category={category._id}>
                    {categoryNameLength < 20 && (
                      <span className={`products-text${index + 1} products-text-small`}>{category.catename}</span>
                    )}
                    {categoryNameLength >= 20 && categoryNameLength < 30 && (
                      <span className={`products-text${index + 1} products-text-medium`}>{category.catename}</span>
                    )}
                    {categoryNameLength >= 30 && (
                      <span className={`products-text${index + 1} products-text-large`}>{category.catename}</span>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
  
          <div class="products-container14">
            <div class="products-testimonial">
              <div class="products-container15">
                <div class="products-container16">
                  {products.map((product, index) => (
                    <div class="products-testimonial-card" key={index}>
                      <div class="products-testimonial1">
                        <img
                          src={`/uploads/${product.image}`}
                          alt={product.productname}
                          class="products-image"
                        />
                        <h1 class="products-text_11" style={{ fontSize: '16px', fontWeight: 300,paddingBottom: '10px'}}>{product.categoryId.catename}</h1>
                        <h1 class="products-text13" style={{ alignItems: 'center',fontSize: '20px', fontWeight: 700 }}>
                          {product.productname}
                        </h1>
                        <a href={`product/${product._id}`} class="products-navlink button" rowid={product._id}>
                          More Details
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="no-data" style={{ display: 'none' }} id="myDiv">
          No data available
        </div>
      </body>
    </div>
    </ProductContainer>
  );
  
}

export default Product;
