import React, { useEffect, useState } from 'react';
import './style.css';
import './careers.css';
function chunkArray(array, chunkSize) {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
}
function Careers({ backendUrl }) {
  const [careers, setCareers] = useState([]);

  useEffect(() => {
    fetch(`${backendUrl}/getcareer`)
      .then(response => response.json())
      .then(data => {
        // Handle the response data here
        setCareers(data.careers);
      })
      .catch(error => {
        // Handle any errors that occur during the request
        console.error(error);
      });
  }, []);

  return (
    <div>
      <head>
        <title>Safecare Industry</title>
        <meta
          name="description"
          content="We are the quality manufacturer of medical disposables in UAE for the past 7 years.  We provide Respiratory, PPE and nursing consumables, Surgical Kits, etc."
        />
        <meta
          property="og:title"
          content="Careers - Best and quality medical disposable manufacturer in UAE "
        />
        <meta
          property="og:description"
          content="We are the quality manufacturer of medical disposables in UAE for the past 7 years.  We provide Respiratory, PPE and nursing consumables, Surgical Kits, etc."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta charSet="utf-8" />
        <meta property="twitter:card" content="summary_large_image" />

        <link rel="shortcut icon" href="./playground_assets/32x32.png" type="icon/png" sizes="32x32" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap"
          data-tag="font"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap"
          data-tag="font"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap"
          data-tag="font"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&amp;display=swap"
          data-tag="font"
        />
      </head>
      <body>

        <div>
          <div class="careers-container">
            <div class="careers-container01">
              <div class="careers-container02">
                <h1 class="careers-text">Join our team</h1>
              </div>
              {/* <div> */}
                {/* {careers.length > 0 && (
                  <div> */}
                    {chunkArray(careers, 4).map((chunk, chunkIndex) => (
                      <div class="careers-blog" key={chunkIndex}>
                        {chunk.map((career, index) => (
                          <div
                            class="careers-container15"
                            key={index}
                            
                          >
                            <div class="careers-blog-post-card4">
                              <img
                                src={`/uploads/${career.image}`}
                                alt={career.jobTitle}
                                style={{ width: '100%', height: 'auto' }}
                              />
                              <div class="careers-container16">
                                <span class="careers-text17">{career.jobTitle}</span>
                                <div class="careers-container17">
                                  <a href={`/career/${career._id}`} class="careers-link08 button">
                                    Apply Now
                                  </a>
                                  <a href="#apply" class="careers-link09">
                                    <svg viewBox="0 0 1024 1024" class="careers-icon08">
                                      <path
                                        d="M512 170l342 342-342 342-60-60 238-240h-520v-84h520l-238-240z"
                                      ></path>
                                    </svg>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                  {/* </div>
                )} */}
              {/* </div> */}






            </div>
          </div>
        </div>
      </body>
    </div>
  );
}

export default Careers;
