import styled from 'styled-components';
export const AboutContainer = styled.div`
.about-us-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
  }
  .about-us-container01 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    max-width: 1600px;
    align-items: flex-start;
    flex-direction: column;
  }
  .about-us-container02 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-threeunits);
    max-width: 1600px;
    align-items: center;
  }
  .about-us-container03 {
    flex: 0 0 auto;
    width: 50%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
  .about-us-text {
    font-family: Poppins;
  }
  .about-us-text01 {
    margin-top: var(--dl-space-space-twounits);
    text-align: justify;
    font-family: Poppins;
  }
  .about-us-container04 {
    flex: 0 0 auto;
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .about-us-image {
    width: 80%;
    align-self: center;
    object-fit: cover;
  }
  .about-us-container05 {
    flex: 0 0 auto;
    width: 100%;
    display: none;
    max-width: 1600px;
    align-items: center;
  }
  .about-us-container06 {
    flex: 0 0 auto;
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .about-us-image1 {
    width: 80%;
    align-self: center;
    object-fit: cover;
  }
  .about-us-container07 {
    flex: 0 0 auto;
    width: 50%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
  .about-us-text11 {
    margin-top: var(--dl-space-space-twounits);
    text-align: justify;
  }
  .about-us-container08 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-threeunits);
    max-width: 1600px;
    align-items: flex-start;
  }
  .about-us-container09 {
    flex: 0 0 auto;
    width: 50%;
    display: flex;
    align-items: flex-start;
    padding-right: var(--dl-space-space-threeunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-threeunits);
    justify-content: center;
  }
  .about-us-text20 {
    font-family: Poppins;
  }
  .about-us-text21 {
    margin-top: var(--dl-space-space-twounits);
    text-align: justify;
    font-family: Poppins;
  }
  .about-us-container10 {
    flex: 0 0 auto;
    width: 50%;
    height: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
  .about-us-text22 {
    font-family: Poppins;
  }
  .about-us-text23 {
    margin-top: var(--dl-space-space-twounits);
    text-align: justify;
  }
  .about-us-container11 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    max-width: 1600px;
    align-items: flex-start;
    padding-top: 0px;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: 0px;
    justify-content: center;
  }
  .about-us-container12 {
    flex: 0 0 auto;
    width: 35%;
    display: flex;
    padding: var(--dl-space-space-unit);
    align-items: center;
    border-radius: 10px;
    flex-direction: column;
    justify-content: flex-end;
    background-image: linear-gradient(90deg, rgb(88, 181, 107) 2.00%,rgb(31, 149, 141) 100.00%);
  }
  .about-us-text26 {
    color: var(--dl-color-gray-white);
  }
  .about-us-text27 {
    color: var(--dl-color-gray-white);
    font-size: 18px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 300;
  }
  .about-us-container13 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-threeunits);
    max-width: 1600px;
    align-items: center;
  }
  .about-us-container14 {
    flex: 0 0 auto;
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .about-us-image2 {
    width: 80%;
    align-self: center;
    object-fit: cover;
  }
  .about-us-container15 {
    flex: 0 0 auto;
    width: 50%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
  .about-us-text28 {
    font-family: Poppins;
  }
  .about-us-text29 {
    margin-top: var(--dl-space-space-twounits);
    text-align: justify;
    font-family: Poppins;
  }
  .about-us-container16 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    max-width: 1600px;
    align-items: center;
    padding-top: 0px;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: 0px;
  }
  .about-us-container17 {
    flex: 0 0 auto;
    width: 50%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
  .about-us-text35 {
    font-family: Poppins;
  }
  .about-us-text36 {
    margin-top: var(--dl-space-space-twounits);
    text-align: justify;
    font-family: Poppins;
  }
  .about-us-text37 {
    font-style: normal;
    font-weight: 700;
  }
  .about-us-container18 {
    flex: 0 0 auto;
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .about-us-image3 {
    width: 80%;
    align-self: center;
    object-fit: cover;
  }
  .about-us-container19 {
    flex: 0 0 auto;
    width: 100%;
    display: none;
    max-width: 1600px;
    align-items: center;
  }
  .about-us-container20 {
    flex: 0 0 auto;
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .about-us-image4 {
    width: 80%;
    align-self: center;
    object-fit: cover;
  }
  .about-us-container21 {
    flex: 0 0 auto;
    width: 50%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
  .about-us-text43 {
    margin-top: var(--dl-space-space-twounits);
    text-align: justify;
  }
  .about-us-container22 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-threeunits);
    max-width: 1600px;
    align-items: center;
  }
  .about-us-container23 {
    flex: 0 0 auto;
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .about-us-image5 {
    width: 80%;
    align-self: center;
    object-fit: cover;
  }
  .about-us-container24 {
    flex: 0 0 auto;
    width: 50%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
  .about-us-text49 {
    font-family: Poppins;
  }
  .about-us-text50 {
    margin-top: var(--dl-space-space-twounits);
    text-align: justify;
    font-family: Poppins;
  }
  .about-us-text51 {
    font-style: normal;
    font-weight: 700;
  }
  @media(max-width: 1600px) {
    .about-us-container01 {
      flex-direction: column;
      background-size: cover;
      /* background-image: url("../assets/group_98-1500w.png");  */
      
    }
    .about-us-container02 {
      width: 100%;
      height: auto;
      max-width: 1600px;
      padding-top: var(--dl-space-space-threeunits);
      padding-left: var(--dl-space-space-threeunits);
      padding-right: var(--dl-space-space-threeunits);
      padding-bottom: 0px;
    }
    .about-us-container08 {
      width: 100%;
      height: auto;
      padding: var(--dl-space-space-threeunits);
      max-width: 1600px;
    }
    .about-us-container11 {
      width: 100%;
      height: auto;
      max-width: 1600px;
      padding-top: 0px;
      padding-left: var(--dl-space-space-threeunits);
      padding-right: var(--dl-space-space-threeunits);
      padding-bottom: 0px;
    }
    .about-us-container13 {
      width: 100%;
      height: auto;
      padding: var(--dl-space-space-threeunits);
      max-width: 1600px;
    }
    .about-us-container16 {
      width: 100%;
      height: auto;
      max-width: 1600px;
      padding-top: 0px;
      padding-left: var(--dl-space-space-threeunits);
      padding-right: var(--dl-space-space-threeunits);
      padding-bottom: var(--dl-space-space-threeunits);
    }
    .about-us-text36 {
      font-family: Poppins;
    }
    .about-us-text37 {
      font-style: normal;
      font-weight: 700;
    }
    .about-us-text38 {
      font-style: normal;
      font-weight: 700;
    }
    .about-us-container22 {
      width: 100%;
      height: auto;
      max-width: 1600px;
      padding-top: 0px;
      padding-left: var(--dl-space-space-threeunits);
      padding-right: var(--dl-space-space-threeunits);
      padding-bottom: var(--dl-space-space-threeunits);
    }
  }
  @media(max-width: 1200px) {
    .about-us-container02 {
      width: 100%;
    }
    .about-us-container08 {
      width: 100%;
    }
    .about-us-container11 {
      width: 100%;
      padding-top: 0px;
    }
    .about-us-container13 {
      width: 100%;
    }
    .about-us-container16 {
      width: 100%;
    }
    .about-us-text36 {
      font-family: Poppins;
    }
    .about-us-text37 {
      font-style: normal;
      font-weight: 700;
    }
    .about-us-text38 {
      font-style: normal;
      font-weight: 700;
    }
    .about-us-container22 {
      width: 100%;
    }
  }
  @media(max-width: 991px) {
    .about-us-container02 {
      width: 100%;
      height: auto;
      align-self: center;
      align-items: flex-start;
      flex-direction: row;
      padding-bottom: 0px;
      justify-content: center;
    }
    .about-us-container03 {
      width: 50%;
      height: auto;
      align-items: center;
      flex-direction: column;
    }
    .about-us-text {
      align-self: flex-start;
      padding-top: var(--dl-space-space-halfunit);
      padding-left: var(--dl-space-space-twounits);
    }
    .about-us-text01 {
      font-size: 16px;
      font-style: normal;
      text-align: justify;
      font-family: Poppins;
      font-weight: 300;
      padding-top: var(--dl-space-space-halfunit);
      padding-left: var(--dl-space-space-twounits);
    }
    .about-us-container04 {
      width: 50%;
      height: 100%;
      padding: var(--dl-space-space-unit);
      align-self: center;
      flex-direction: column;
    }
    .about-us-image {
      width: 100%;
      height: 100%;
    }
    .about-us-container05 {
      width: 100%;
      height: auto;
      display: none;
      align-self: center;
      align-items: flex-start;
      flex-direction: row;
      justify-content: center;
    }
    .about-us-container06 {
      width: 50%;
      height: 100%;
      padding: var(--dl-space-space-unit);
      align-self: center;
      flex-direction: column;
    }
    .about-us-image1 {
      width: 100%;
      height: 100%;
    }
    .about-us-container07 {
      width: 50%;
      height: auto;
      align-items: center;
      flex-direction: column;
    }
    .about-us-text10 {
      align-self: flex-start;
      padding-top: var(--dl-space-space-halfunit);
      padding-left: var(--dl-space-space-twounits);
    }
    .about-us-text11 {
      font-size: 18px;
      font-style: normal;
      text-align: justify;
      font-family: Poppins;
      font-weight: 300;
      padding-top: var(--dl-space-space-halfunit);
      padding-left: var(--dl-space-space-twounits);
    }
    .about-us-container08 {
      width: 100%;
      height: auto;
      align-self: center;
      align-items: flex-start;
      flex-direction: row;
      padding-bottom: 0px;
      justify-content: center;
    }
    .about-us-container09 {
      width: 50%;
      height: auto;
      align-items: center;
      flex-direction: column;
    }
    .about-us-text20 {
      align-self: flex-start;
      padding-top: var(--dl-space-space-halfunit);
      padding-left: var(--dl-space-space-twounits);
    }
    .about-us-text21 {
      font-size: 16px;
      font-style: normal;
      text-align: justify;
      font-family: Poppins;
      font-weight: 300;
      padding-top: var(--dl-space-space-halfunit);
      padding-left: var(--dl-space-space-twounits);
    }
    .about-us-container10 {
      width: 50%;
      height: auto;
      align-items: center;
      flex-direction: column;
    }
    .about-us-text22 {
      align-self: flex-start;
      padding-top: var(--dl-space-space-halfunit);
      padding-left: var(--dl-space-space-twounits);
    }
    .about-us-text23 {
      font-size: 16px;
      font-style: normal;
      text-align: justify;
      font-family: Poppins;
      font-weight: 300;
      padding-top: var(--dl-space-space-halfunit);
      padding-left: var(--dl-space-space-twounits);
    }
    .about-us-container11 {
      width: 100%;
      height: auto;
      align-self: center;
      align-items: flex-start;
      flex-direction: row;
      justify-content: center;
    }
    .about-us-container12 {
      width: 50%;
      height: auto;
      align-items: center;
      flex-direction: column;
    }
    .about-us-text26 {
      align-self: flex-start;
      padding-top: var(--dl-space-space-halfunit);
      padding-left: var(--dl-space-space-twounits);
    }
    .about-us-container13 {
      width: 100%;
      height: auto;
      align-self: center;
      align-items: flex-start;
      flex-direction: row;
      justify-content: center;
    }
    .about-us-container14 {
      width: 50%;
      height: 100%;
      padding: var(--dl-space-space-unit);
      align-self: center;
      flex-direction: column;
    }
    .about-us-image2 {
      width: 100%;
      height: 100%;
    }
    .about-us-container15 {
      width: 50%;
      height: auto;
      align-items: center;
      flex-direction: column;
    }
    .about-us-text28 {
      align-self: flex-start;
      padding-top: var(--dl-space-space-halfunit);
      padding-left: var(--dl-space-space-twounits);
    }
    .about-us-text29 {
      font-size: 16px;
      font-style: normal;
      text-align: justify;
      font-family: Poppins;
      font-weight: 300;
      padding-top: var(--dl-space-space-halfunit);
      padding-left: var(--dl-space-space-twounits);
    }
    .about-us-text30 {
      font-style: normal;
      font-weight: 700;
    }
    .about-us-container16 {
      width: 100%;
      height: auto;
      align-self: center;
      align-items: flex-start;
      flex-direction: row;
      padding-bottom: 0px;
      justify-content: center;
    }
    .about-us-container17 {
      width: 50%;
      height: auto;
      align-items: center;
      flex-direction: column;
    }
    .about-us-text35 {
      align-self: flex-start;
      padding-top: var(--dl-space-space-halfunit);
      padding-left: var(--dl-space-space-twounits);
    }
    .about-us-text36 {
      font-size: 16px;
      font-style: normal;
      text-align: justify;
      font-family: Poppins;
      font-weight: 300;
      padding-top: var(--dl-space-space-halfunit);
      padding-left: var(--dl-space-space-twounits);
    }
    .about-us-container18 {
      width: 50%;
      height: 100%;
      padding: var(--dl-space-space-unit);
      align-self: center;
      flex-direction: column;
    }
    .about-us-image3 {
      width: 100%;
      height: 100%;
    }
    .about-us-container19 {
      width: 100%;
      height: auto;
      display: none;
      align-self: center;
      align-items: flex-start;
      flex-direction: row;
      justify-content: center;
    }
    .about-us-container20 {
      width: 50%;
      height: 100%;
      padding: var(--dl-space-space-unit);
      align-self: center;
      flex-direction: column;
    }
    .about-us-image4 {
      width: 100%;
      height: 100%;
    }
    .about-us-container21 {
      width: 50%;
      height: auto;
      align-items: center;
      flex-direction: column;
    }
    .about-us-text42 {
      align-self: flex-start;
      padding-top: var(--dl-space-space-halfunit);
      padding-left: var(--dl-space-space-twounits);
    }
    .about-us-text43 {
      font-size: 18px;
      font-style: normal;
      text-align: justify;
      font-family: Poppins;
      font-weight: 300;
      padding-top: var(--dl-space-space-halfunit);
      padding-left: var(--dl-space-space-twounits);
    }
    .about-us-container22 {
      width: 100%;
      height: auto;
      align-self: center;
      align-items: flex-start;
      flex-direction: row;
      justify-content: center;
    }
    .about-us-container23 {
      width: 50%;
      height: 100%;
      padding: var(--dl-space-space-unit);
      align-self: center;
      flex-direction: column;
    }
    .about-us-image5 {
      width: 100%;
      height: 100%;
    }
    .about-us-container24 {
      width: 50%;
      height: auto;
      align-items: center;
      flex-direction: column;
    }
    .about-us-text49 {
      align-self: flex-start;
      padding-top: var(--dl-space-space-halfunit);
      padding-left: var(--dl-space-space-twounits);
    }
    .about-us-text50 {
      font-size: 16px;
      font-style: normal;
      text-align: justify;
      font-family: Poppins;
      font-weight: 300;
      padding-top: var(--dl-space-space-halfunit);
      padding-left: var(--dl-space-space-twounits);
    }
  }
  @media(max-width: 767px) {
    .about-us-container02 {
      display: none;
      flex-direction: column;
    }
    .about-us-container03 {
      width: 100%;
      align-self: center;
    }
    .about-us-container05 {
      width: 100%;
      display: flex;
      padding: var(--dl-space-space-threeunits);
      flex-direction: column;
    }
    .about-us-container06 {
      width: 100%;
      padding-top: var(--dl-space-space-twounits);
      padding-left: var(--dl-space-space-twounits);
      padding-right: var(--dl-space-space-twounits);
      padding-bottom: 0px;
    }
    .about-us-container07 {
      width: 100%;
      align-self: center;
      padding-top: 0px;
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
    }
    .about-us-text10 {
      align-self: center;
      padding-left: 0px;
    }
    .about-us-text11 {
      font-size: 14px;
      padding-left: 0px;
    }
    .about-us-container08 {
      display: none;
      flex-direction: column;
    }
    .about-us-container09 {
      width: 100%;
      align-self: center;
    }
    .about-us-container10 {
      width: 100%;
      align-self: center;
    }
    .about-us-container11 {
      width: 90%;
      align-items: center;
      padding-left: 0px;
      padding-right: 0px;
      flex-direction: column;
    }
    .about-us-container12 {
      width: 100%;
      align-self: center;
    }
    .about-us-text26 {
      font-size: 25px;
      align-self: center;
      text-align: center;
      padding-left: 0px;
    }
    .about-us-container13 {
      align-self: center;
      padding-top: 0px;
      flex-direction: column;
      padding-bottom: 0px;
    }
    .about-us-container14 {
      width: 100%;
      margin-top: var(--dl-space-space-unit);
    }
    .about-us-container15 {
      width: 100%;
      align-self: center;
      align-items: center;
    }
    .about-us-text28 {
      width: 100%;
      font-size: 25px;
      text-align: center;
      padding-left: 0px;
    }
    .about-us-text29 {
      text-align: center;
      font-family: Poppins;
      padding-left: 0px;
    }
    .about-us-text30 {
      font-style: normal;
      font-weight: 700;
    }
    .about-us-text33 {
      font-size: 14px;
    }
    .about-us-container16 {
      display: none;
      flex-direction: column;
    }
    .about-us-container17 {
      width: 100%;
      align-self: center;
    }
    .about-us-container19 {
      width: 100%;
      display: flex;
      padding: var(--dl-space-space-threeunits);
      flex-direction: column;
    }
    .about-us-container20 {
      width: 100%;
      padding-top: var(--dl-space-space-twounits);
      padding-left: var(--dl-space-space-twounits);
      padding-right: var(--dl-space-space-twounits);
      padding-bottom: 0px;
    }
    .about-us-container21 {
      width: 100%;
      align-self: center;
      align-items: center;
      padding-top: 0px;
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: var(--dl-space-space-twounits);
    }
    .about-us-text42 {
      font-size: 25px;
      align-self: center;
      padding-left: 0px;
    }
    .about-us-text43 {
      font-size: 18px;
      text-align: center;
      padding-left: 0px;
    }
    .about-us-text44 {
      font-style: inherit;
      font-weight: 700;
    }
    .about-us-text45 {
      font-style: normal;
    }
    .about-us-text46 {
      font-style: normal;
    }
    .about-us-text47 {
      font-size: 14px;
      font-style: normal;
    }
    .about-us-text48 {
      font-style: normal;
    }
    .about-us-container22 {
      align-self: center;
      padding-top: 0px;
      flex-direction: column;
      padding-bottom: 0px;
    }
    .about-us-container23 {
      width: 100%;
    }
    .about-us-container24 {
      width: 100%;
      align-self: center;
      align-items: center;
      margin-bottom: var(--dl-space-space-twounits);
    }
    .about-us-text49 {
      width: 100%;
      font-size: 25px;
      text-align: center;
      font-family: Poppins;
      padding-left: 0px;
    }
    .about-us-text50 {
      text-align: center;
      font-family: Poppins;
      padding-left: 0px;
    }
    .about-us-text51 {
      font-style: normal;
      font-weight: 700;
    }
    .about-us-text54 {
      font-size: 14px;
    }
  }
  @media(max-width: 479px) {
    .about-us-container05 {
      padding: var(--dl-space-space-unit);
    }
    .about-us-text10 {
      align-self: center;
      padding-left: 0px;
    }
    .about-us-text11 {
      padding-left: 0px;
    }
    .about-us-text26 {
      font-size: 1.5em;
      font-family: Poppins;
    }
    .about-us-text27 {
      margin-top: var(--dl-space-space-halfunit);
      text-align: center;
    }
    .about-us-container13 {
      padding: var(--dl-space-space-unit);
      align-self: center;
      align-items: center;
    }
    .about-us-container15 {
      align-self: center;
    }
    .about-us-text28 {
      width: 100%;
    }
    .about-us-container19 {
      padding: var(--dl-space-space-unit);
      align-self: center;
    }
    .about-us-container20 {
      padding-top: var(--dl-space-space-halfunit);
    }
    .about-us-container21 {
      padding-bottom: var(--dl-space-space-halfunit);
    }
    .about-us-text42 {
      align-self: center;
      padding-left: 0px;
    }
    .about-us-text43 {
      margin-top: var(--dl-space-space-unit);
      padding-left: 0px;
    }
    .about-us-container22 {
      width: 100%;
      padding: var(--dl-space-space-unit);
      align-self: center;
      align-items: center;
    }
    .about-us-container24 {
      align-self: center;
    }
    .about-us-text49 {
      font-size: 14px;
      font-family: Poppins;
    }
    .about-us-text50 {
      font-family: Poppins;
    }
    .about-us-text51 {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
    }
    .about-us-text52 {
      font-style: normal;
      font-weight: 700;
    }
    .about-us-text54 {
      font-size: 12px;
    }
  }
  
  [data-thq="thq-dropdown"]:hover > [data-thq="thq-dropdown-list"] {
    display: flex;
  }
  
  [data-thq="thq-dropdown"]:hover > div [data-thq="thq-dropdown-arrow"] {
    transform: rotate(90deg);
  }
`;